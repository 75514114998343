import React from 'react';
import { useTranslation } from '../../../../../../../../../../../../components/translation-context/TranslationContext';
import { InfoCardProps } from './props';
import './style.css'

const InfoCard = ({ icon, title, data }: InfoCardProps) => {

    // Attributes
    const { translate } = useTranslation();

    return (
        <div className='info-card-container'>
            {icon}
            <div className='info-card-details-container'>
                <div className='info-card-details-title'>{translate(title)}</div>
                <div className='info-card-details-data'>{data}</div>
            </div>
        </div>
    )
}

export default InfoCard