import React, { useRef, useState } from 'react';
import { EditActionProps } from './props';
import { ReactComponent as EditGoldIcon } from '../../assets/edit-gold.svg';
import { ReactComponent as EditIcon } from '../../assets/edit.svg';
import { ReactComponent as DeleteIcon } from '../../assets/delete.svg';
import EditActionMenu from './menu';
import { EditActionMenuRow } from './menu/props';
import useOnClickOutside from '../../../../../../../../hooks/useOnClickOutside';
import Loading from '../../assets/loading';
import './style.css';
import { useHistory } from 'react-router-dom';
import Toggle from '../../../../../../components/toggle';
import { NotificationStatus } from '../../../../../../../../dtos/go-api';
import {
  useDeleteNotification,
  useUpdateStatusNotificationById,
} from '../../../../../../../../services/go-api/notifications/notifications';
import Service from '../../../../../../../../services/Service';

const EditAction = ({
  notificationId,
  handleDisabled = false,
  notificationStatus,
  refetchNotification,
}: EditActionProps) => {
  // Attributes
  const history = useHistory();
  const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => setIsMenuOpened(false));
  const isLoading = false;
  const { mutateAsync: updateStatusNotification } =
    useUpdateStatusNotificationById();
  const { mutateAsync: deleteNotification } = useDeleteNotification();

  //Functions
  async function handleDeleteNotification() {
    await deleteNotification(
      { notificationId },
      {
        onSuccess: () => {
          if (refetchNotification) refetchNotification();
          Service.success.push('Notification deleted !');
        },
      }
    );
  }

  const rows: EditActionMenuRow[] = [
    {
      icon: EditIcon,
      text: 'Modification',
      onClick: () =>
        history.push(`/dashboard/notifications/${notificationId}/details`),
    },
    {
      icon: DeleteIcon,
      text: 'Delete Notification',
      onClick: () => handleDeleteNotification(),
    },
  ];

  //Functions
  async function handleToggle(check: boolean) {
    await updateStatusNotification(
      {
        notificationId: notificationId,
        data: {
          status: check
            ? NotificationStatus.ENABLED
            : NotificationStatus.DISABLED,
        },
      },
      {
        onSuccess: () => {
          Service.success.push(
            check ? 'Notification enabled !' : 'Notification disabled !'
          );
        },
      }
    );
  }

  // Render
  return (
    <div className='edit-action-container'>
      <div
        className='edit-action-icon-container'
        onClick={() => !isLoading && setIsMenuOpened(true)}
        ref={ref}
      >
        {isLoading ? (
          <Loading width='1rem' height='1rem' stroke='#000000' />
        ) : (
          <EditGoldIcon />
        )}

        {isMenuOpened && <EditActionMenu rows={rows} />}
      </div>
      {handleDisabled && (
        <Toggle
          handleClick={(checked) => handleToggle(checked)}
          defaultValue={
            notificationStatus === NotificationStatus.ENABLED ? true : false
          }
        />
      )}
    </div>
  );
};

export default EditAction;
