import React from "react";
import { useTranslation } from "../translation-context/TranslationContext";
import { InputTextProps } from "./props";
import "./style.css";

const InputText = ({
  onChange,
  value,
  placeholder,
  style,
  size = "medium",
  required,
  type = "text",
  readOnly,
  onKeyDown,
  dataCy,
}: InputTextProps) => {
  // Attributes
  const { translate } = useTranslation();

  return (
    <input
      onKeyDown={onKeyDown}
      readOnly={readOnly}
      required={required}
      className={`input-text-container ${size}`}
      type={type}
      value={value}
      placeholder={translate(placeholder)}
      onChange={onChange}
      style={{ ...style }}
      data-cy={dataCy}
    />
  );
};

export default InputText;
