import React from "react";

const Close = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.52164 0.444892L5.00082 2.96386L2.47999 0.442899C2.34718 0.304578 2.1881 0.194161 2.01207 0.11812C1.83603 0.0420793 1.64659 0.00194478 1.45485 6.89428e-05C1.26311 -0.00180689 1.07292 0.0346138 0.89543 0.107196C0.717944 0.179778 0.556731 0.287061 0.421241 0.422758C0.28575 0.558454 0.178709 0.719833 0.106391 0.897435C0.0340735 1.07504 -0.00206592 1.26529 9.11725e-05 1.45704C0.00224826 1.64879 0.0426584 1.83818 0.118953 2.01411C0.195248 2.19004 0.305893 2.34897 0.444401 2.48159L2.96423 5.00255L0.446394 7.52051C0.183159 7.7925 0.0373457 8.15705 0.0403895 8.53555C0.0434332 8.91406 0.195091 9.27622 0.462665 9.54393C0.73024 9.81165 1.0923 9.9635 1.47079 9.96673C1.84927 9.96995 2.21387 9.82431 2.48597 9.5612L5.00082 7.03824L7.51965 9.55821C7.65256 9.69626 7.81166 9.80643 7.98765 9.88228C8.16363 9.95812 8.35297 9.99812 8.54459 9.99994C8.73622 10.0017 8.92628 9.96534 9.10367 9.89284C9.28106 9.82034 9.44222 9.71321 9.57772 9.5777C9.71323 9.44219 9.82036 9.28102 9.89285 9.10362C9.96535 8.92622 10.0017 8.73615 9.99994 8.54452C9.99812 8.35288 9.95812 8.16354 9.88228 7.98754C9.80644 7.81155 9.69628 7.65244 9.55823 7.51952L7.04338 5.00354L9.56122 2.48557C9.82445 2.21358 9.97027 1.84904 9.96722 1.47053C9.96418 1.09202 9.81252 0.729871 9.54495 0.462152C9.27737 0.194432 8.91531 0.0425903 8.53683 0.0393615C8.15834 0.0361327 7.79375 0.181776 7.52164 0.444892Z"
				fillOpacity="0.3"
			/>
		</svg>
	);
};
export default Close;
