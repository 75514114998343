/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation
} from 'react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  GetV1ApiScreensParams,
  ScreenDTO,
  GetV1ApiScreensIdPreparationsParams
} from '../../../types/dtos/csharp-api'
import { customInstance } from '.././config';

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getV1ApiScreensId = (
    id: string | null,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<void>(
      {url: `/v1/api/screens/${id}`, method: 'get', signal
    },
      options);
    }
  

export const getGetV1ApiScreensIdQueryKey = (id: string | null,) => [`/v1/api/screens/${id}`];

    
export type GetV1ApiScreensIdQueryResult = NonNullable<Awaited<ReturnType<typeof getV1ApiScreensId>>>
export type GetV1ApiScreensIdQueryError = unknown

export const useGetV1ApiScreensId = <TData = Awaited<ReturnType<typeof getV1ApiScreensId>>, TError = unknown>(
 id: string | null, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getV1ApiScreensId>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetV1ApiScreensIdQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getV1ApiScreensId>>> = ({ signal }) => getV1ApiScreensId(id, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getV1ApiScreensId>>, TError, TData>({ queryKey, queryFn, enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export const deleteV1ApiScreensId = (
    id: string | null,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<void>(
      {url: `/v1/api/screens/${id}`, method: 'delete'
    },
      options);
    }
  


    export type DeleteV1ApiScreensIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteV1ApiScreensId>>>
    
    export type DeleteV1ApiScreensIdMutationError = unknown

    export const useDeleteV1ApiScreensId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteV1ApiScreensId>>, TError,{id: string | null}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteV1ApiScreensId>>, {id: string | null}> = (props) => {
          const {id} = props ?? {};

          return  deleteV1ApiScreensId(id,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof deleteV1ApiScreensId>>, TError, {id: string | null}, TContext>(mutationFn, mutationOptions);
    }
    export const getV1ApiScreens = (
    params?: GetV1ApiScreensParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<void>(
      {url: `/v1/api/screens`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetV1ApiScreensQueryKey = (params?: GetV1ApiScreensParams,) => [`/v1/api/screens`, ...(params ? [params]: [])];

    
export type GetV1ApiScreensQueryResult = NonNullable<Awaited<ReturnType<typeof getV1ApiScreens>>>
export type GetV1ApiScreensQueryError = unknown

export const useGetV1ApiScreens = <TData = Awaited<ReturnType<typeof getV1ApiScreens>>, TError = unknown>(
 params?: GetV1ApiScreensParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getV1ApiScreens>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetV1ApiScreensQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getV1ApiScreens>>> = ({ signal }) => getV1ApiScreens(params, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getV1ApiScreens>>, TError, TData>({ queryKey, queryFn, ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export const postV1ApiScreens = (
    screenDTO: ScreenDTO,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<void>(
      {url: `/v1/api/screens`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: screenDTO
    },
      options);
    }
  


    export type PostV1ApiScreensMutationResult = NonNullable<Awaited<ReturnType<typeof postV1ApiScreens>>>
    export type PostV1ApiScreensMutationBody = ScreenDTO
    export type PostV1ApiScreensMutationError = unknown

    export const usePostV1ApiScreens = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postV1ApiScreens>>, TError,{data: ScreenDTO}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postV1ApiScreens>>, {data: ScreenDTO}> = (props) => {
          const {data} = props ?? {};

          return  postV1ApiScreens(data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof postV1ApiScreens>>, TError, {data: ScreenDTO}, TContext>(mutationFn, mutationOptions);
    }
    export const putV1ApiScreens = (
    screenDTO: ScreenDTO,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<void>(
      {url: `/v1/api/screens`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: screenDTO
    },
      options);
    }
  


    export type PutV1ApiScreensMutationResult = NonNullable<Awaited<ReturnType<typeof putV1ApiScreens>>>
    export type PutV1ApiScreensMutationBody = ScreenDTO
    export type PutV1ApiScreensMutationError = unknown

    export const usePutV1ApiScreens = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putV1ApiScreens>>, TError,{data: ScreenDTO}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putV1ApiScreens>>, {data: ScreenDTO}> = (props) => {
          const {data} = props ?? {};

          return  putV1ApiScreens(data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof putV1ApiScreens>>, TError, {data: ScreenDTO}, TContext>(mutationFn, mutationOptions);
    }
    export const getV1ApiScreensIdPreparations = (
    id: string | null,
    params?: GetV1ApiScreensIdPreparationsParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<void>(
      {url: `/v1/api/screens/${id}/preparations`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetV1ApiScreensIdPreparationsQueryKey = (id: string | null,
    params?: GetV1ApiScreensIdPreparationsParams,) => [`/v1/api/screens/${id}/preparations`, ...(params ? [params]: [])];

    
export type GetV1ApiScreensIdPreparationsQueryResult = NonNullable<Awaited<ReturnType<typeof getV1ApiScreensIdPreparations>>>
export type GetV1ApiScreensIdPreparationsQueryError = unknown

export const useGetV1ApiScreensIdPreparations = <TData = Awaited<ReturnType<typeof getV1ApiScreensIdPreparations>>, TError = unknown>(
 id: string | null,
    params?: GetV1ApiScreensIdPreparationsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getV1ApiScreensIdPreparations>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetV1ApiScreensIdPreparationsQueryKey(id,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getV1ApiScreensIdPreparations>>> = ({ signal }) => getV1ApiScreensIdPreparations(id,params, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getV1ApiScreensIdPreparations>>, TError, TData>({ queryKey, queryFn, enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

