import React from 'react';
import { useHistory } from 'react-router-dom';
import AddButton from '../../../../../../components/add-button';
import Header from '../../../../../../components/header';
import CardGroup from '../../../../components/card-group';
import PageContainer from '../../../../components/page-container';
import Search from '../../../../components/search';
import CompanyCard from './components/company-card';
import './style.css';
import MenuAction from '../../../../components/menu-action';
import useSearch from '../../../../../../hooks/use-search';
import BusyIndicator from '../../../../../../components/busy-indicator';
import MenuListContainer from '../../../../components/menu-list-container';
import EmptyStates from '../../../../components/empty-states';
import { useGetAllCompanies } from '../../../../../../services/go-api/company/company';
import { CompanyResDTO } from '../../../../../../dtos/go-api';

const CompanyList = () => {
  // Attributes
  const history = useHistory();
  //   const query = useQuery('companies', refreshCompanies);
  const companiesReq = useGetAllCompanies(
    { size: 1000 },
    { query: { refetchOnWindowFocus: false } }
  );
  const companies = companiesReq.data && companiesReq.data.data;
  const { search, filtered, setSearch } = useSearch<CompanyResDTO>(
    companies ?? [],
    ['name', 'code']
  );

  // Handlers
  function renderCompanies() {
    if (companies) {
      return filtered.map((company, index) => {
        return (
          <CompanyCard
            index={index}
            name={company.name}
            id={company.id}
            key={company.id}
          />
        );
      });
    }

    return 'query.status';
  }

  return (
    <BusyIndicator queries={[companiesReq]}>
      <PageContainer>
        <Header title='Our companies' />
        {companies && companies.length > 0 ? (
          <MenuListContainer>
            <MenuAction>
              <Search
                value={search}
                placeholder='Search for a company'
                onChange={setSearch}
              />
              <AddButton
                title='Add new company'
                handleClick={() => history.push('/dashboard/companies/details')}
              />
            </MenuAction>
            <CardGroup>{renderCompanies()}</CardGroup>
          </MenuListContainer>
        ) : (
          <EmptyStates menu='companies' />
        )}
      </PageContainer>
    </BusyIndicator>
  );
};

export default CompanyList;
