import React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import BusyIndicator from "../../../../../../components/busy-indicator";
import Header from "../../../../../../components/header";
import SegmentTab from "../../../../../../components/segment-tab";
import { CompanyRestaurantDTO } from "../../../../../../dtos/companyRestaurantDTO";
import useSearch from "../../../../../../hooks/use-search";
import useSegment from "../../../../../../hooks/use-search/use-segment";
import CompanyRestaurantService from "../../../../../../services/CompanyRestaurantService";
import CompanyService from "../../../../../../services/CompanyService";
import CardGroup from "../../../../components/card-group";
import MenuAction from "../../../../components/menu-action";
import MenuContainer from "../../../../components/menu-container";
import PageContainer from "../../../../components/page-container";
import Search from "../../../../components/search";
import CompanyRestaurantCard from "./component/card";

const CompanyRestaurants = () => {
  // Attributes
  const { companyId }: any = useParams();
  const companyRestaurantsQuery = useQuery(
    `company-restaurants-${companyId}`,
    refreshCompanyRestaurants
  );
  const companyQuery = useQuery(`company-${companyId}`, refreshCompany);
  const { search, filtered, setSearch } = useSearch(
    companyRestaurantsQuery.data ? companyRestaurantsQuery.data : [],
    ["restaurant.name"]
  );
  const { setSegment, segmented } = useSegment(
    filtered,
    handleSegment,
    "Assigned"
  );

  // Handlers
  async function refreshCompanyRestaurants() {
    return (await CompanyRestaurantService.get(companyId)).data;
  }

  async function refreshCompany() {
    return (await CompanyService.get(companyId)).data;
  }

  function handleSegment(item: CompanyRestaurantDTO, segment: any) {
    const { isAssigned } = item;

    const segmentValue = mapSegmentWithValue(segment);

    return isAssigned === segmentValue;
  }

  function mapSegmentWithValue(segment: string) {
    switch (segment) {
      case "Assigned":
        return true;

      case "Not Assigned":
        return false;
    }
  }

  function renderRestaurants() {
    return segmented.map((restaurant) => (
      <CompanyRestaurantCard
        restaurant={restaurant}
        refetch={companyRestaurantsQuery.refetch}
        key={`company-${restaurant.id}`}
      />
    ));
  }

  return (
    <BusyIndicator queries={[refreshCompany, refreshCompanyRestaurants]}>
      <PageContainer>
        <Header
          hasBackButton={true}
          title={`${companyQuery?.data?.name} - Restaurants`}
          actionButtons={{
            routes: ["restaurants", "employees", "details"],
            menu: "companies",
            id: companyId,
          }}
          navigationHeader={[
            { name: "Companies", path: `/dashboard/companies` },
            {
              name: `${companyQuery?.data?.name}`,
              path: `/dashboard/companies/${companyId}`,
            },
            { name: "Restaurants" },
          ]}
        />
        <MenuContainer>
          <MenuAction>
            <Search
              placeholder="Search for a restaurant"
              onChange={setSearch}
              value={search}
            />
            <SegmentTab
              segments={["Assigned", "Not Assigned"]}
              handleChange={(segment: string) => setSegment(segment)}
            />
          </MenuAction>
          <CardGroup>
            {companyRestaurantsQuery?.data && renderRestaurants()}
          </CardGroup>
        </MenuContainer>
      </PageContainer>
    </BusyIndicator>
  );
};

export default CompanyRestaurants;
