import React from "react";
import "../style.css";
import { ReactComponent as CrossIcon } from "./assets/cross_circle.svg";
import { SideDishCardProps } from "./props";

const SideDishCard = ({ product, handleRemoveClick }: SideDishCardProps) => {
  // Render
  return (
    <div className="side-dish-card-container">
      <div className="side-dish-card-row">
        <div className="side-dish-card-row">
          <img
            src={product?.pictureUrl}
            style={{
              width: "44px",
              height: "44px",
              objectFit: "cover",
              borderRadius: "3px",
              marginRight: "12px",
            }}
            alt={`product ${product?.name}`}
          />
          <div>{product?.name}</div>
        </div>
        <CrossIcon onClick={handleRemoveClick} className="cross-icon" />
      </div>
    </div>
  );
};

export default SideDishCard;
