import { CompanyReqCreateDTO } from "../../../../../../../../dtos/go-api";

export interface CommercialInformtionProps {
    company: CompanyReqCreateDTO,
    dispatch: any
}

export enum COMPANY_FIELDS {
    NAME = 'name',
    VATNUMBER = 'vatNumber',
    DELIVERY_COMMENTS = 'deliveryComments',
    CODE = 'code',
    KITCHEN_PRIORITY = 'kitchenPriority'
}