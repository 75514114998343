import React from 'react';
import './styles.css';
import { ReactComponent as CloseIcon } from '../../assets/close.svg';
import { NotificationDialogByOrdersHeaderProps } from './props';
import { useTranslation } from '../../../../../../../../../../components/translation-context/TranslationContext';

const NotificationDialogByOrdersHeader = ({
  onClose,
  onSave,
}: NotificationDialogByOrdersHeaderProps) => {
  // Attributes
  const { translate } = useTranslation();
  return (
    <div className='notification-dialog-header-container'>
      <div className='notification-dialog-header-left-part'>
        <CloseIcon
          onClick={onClose}
          style={{ cursor: 'pointer', marginRight: 20 }}
        />
        <div>{translate('Send notification')}</div>
      </div>
      <div className='notification-dialog-header-right-part' onClick={onSave}>
        {translate('Save')}
      </div>
    </div>
  );
};

export default NotificationDialogByOrdersHeader;
