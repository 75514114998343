import { useTranslation } from "../../../../../../../../../../components/translation-context/TranslationContext";
import React, { useState } from "react";
import "./styles.css";
import { capitalize } from "lodash";
import InputText from "../../../../../../../../../../components/input-text";
import { NotificationByOrdersDateProps } from "./props";
import { format } from "date-fns";
import InputDate from "../../../../../../../../components/input-date";
const NotificationByOrdersDate = ({
  publicationDay,
  onChangeDay,
  publicationHour,
  onChangeHour,
}: NotificationByOrdersDateProps) => {
  // Attributes
  const { translate } = useTranslation();
  const [isNow, setIsNow] = useState(true);

  function handleOnDateChange(value: string) {
    if (value !== "") {
      const newDate = new Date(value).toISOString();
      onChangeDay(newDate);
    }
  }

  function handleOnTimeChange(value: string) {

    if (value !== "") {
      const newDate = format(new Date("05 October 2011 " + value), "HH:mm");
      onChangeHour(newDate);
    }
  }

  return (
    <div className='notification-dialog-body-date-container'>
      <div className='notification-dialog-body-date-title'>
        {translate("publication-information")}
      </div>
      <div className='notification-dialog-body-date-checkbox-container'>
        <div>
          <input
            type='radio'
            id='publish-now'
            checked={isNow}
            onChange={() => setIsNow(true)}
            style={{ marginRight: 9 }}
          />
          <label
            className='notification-dialog-body-date-checkbox-label'
            htmlFor='publish-now'
          >
            {capitalize(translate("publish-now"))}
          </label>
        </div>
        <div>
          <input
            type='radio'
            id='custom-publication-time'
            checked={!isNow}
            onChange={() => setIsNow(false)}
            style={{ marginRight: 9 }}
          />
          <label
            className='notification-dialog-body-date-checkbox-label'
            htmlFor='custom-publication-time'
          >
            {capitalize(translate("custom-publication-time"))}
          </label>
        </div>
      </div>
      {!isNow && (
        <div className='notification-dialog-body-date-input-container'>
          <div className='notification-dialog-body-date-input-group'>
            <div className='notification-dialog-body-date-input-label'>
              {translate("date").toUpperCase()}
            </div>
            <InputDate
              value={format(new Date(publicationDay), "yyyy-MM-dd")}
              min={new Date().toISOString().split("T")[0]}
              onChange={(event: any) => handleOnDateChange(event.target.value)}
              style={{ width: "fit-content" }}
            />
          </div>
          <div className='notification-dialog-body-date-input-group'>
            <div className='notification-dialog-body-date-input-label'>
              {translate("time").toUpperCase()}
            </div>
            <InputText
              style={{ width: "fit-content" }}
              type='time'
              value={publicationHour}
              onChange={(event: any) => handleOnTimeChange(event.target.value)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default NotificationByOrdersDate;
