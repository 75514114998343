import React from 'react';
import { AddButtonProps } from './props';
import { ReactComponent as AddIcon } from './assets/add.svg'
import Translation from '../translation';
import './style.css'

const AddButton = ({ title, handleClick }: AddButtonProps) => {
    return (
        <div className='add-button-container' onClick={handleClick}>
            <AddIcon />
            <div className='add-button-title' >
                <Translation>
                    {title}
                </Translation>
            </div>
        </div>
    )
}

export default AddButton;