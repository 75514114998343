import React from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as GreenArrowIcon } from './assets/green_arrow.svg';
import { BackButtonProps } from './props';
import './style.css'

const BackButton = ({ onClick }: BackButtonProps) => {

    // Attributes
    const history = useHistory()

    return (
        <div className="back-button-container">
            <GreenArrowIcon onClick={onClick} className='green-arrow-icon' />
        </div>
    )
}

export default BackButton;