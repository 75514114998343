import React from "react";
import {
  NotificationDestinationType,
  NotificationType,
} from "../../../../../../../../../dtos/go-api";
import { NotificationsSelectTypeProps } from "./props";
import "./style.css";

const NotificationsSelectType = ({
  type,
  setType,
  destinationType,
}: NotificationsSelectTypeProps) => {
  // Render
  return (
    <div className='notifications-select-type-container'>
      <div className='notifications-select-type-content'>
        {Object.values(NotificationType)
          ?.filter((type) =>
            destinationType === NotificationDestinationType.USER
              ? type !== NotificationType.RECURRING
              : true
          )
          ?.map((t, index) => (
            <div
              className='notifications-select-type-tab'
              key={`notifications-select-type-${index}`}
              onClick={() => {
                setType(t);
              }}
            >
              <div className='notifications-select-type-tab-text'>{t}</div>
              {t === type && (
                <div className='notifications-select-type-tab-selected' />
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default NotificationsSelectType;
