import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { RestaurantResDTO } from '../../../../../../../../dtos/go-api';
import BackgroundDetailsForm from '../../../../../../components/background-details-form';
import InputSelect from '../../../../../../components/input-select';
import { OptionSelect } from '../../../../../../components/input-select/props';
import TitleInputGroup from '../../../../../../components/title-input-group';
import DoubleCheckbox from '../double-checkbox';
import { PromoQueryParams } from '../props';
import SelectedItem from '../selected-item';
import { PromoMultiSelectProps } from './props';
import './style.css';

const MultiSelectRestaurants = ({
  title,
  dispatch,
  promoRestaurants,
  promo,
  restaurants,
}: PromoMultiSelectProps) => {
  // Attributes
  const { promoId } = useParams<PromoQueryParams>();
  const selectedChoice =
    restaurants?.length !== 0 &&
    promo.restaurants.length === restaurants?.length
      ? 'All restaurants'
      : 'Selected restaurants';
  const [selectOptions, setSelectOptions] = useState<OptionSelect[]>(
    mapRestaurantsToSelectOptions(
      promoId
        ? restaurants.filter((rst) => !promoRestaurants.includes(rst.id))
        : restaurants
    )
  );

  const [selectedList, setSelectedList] = useState<RestaurantResDTO[]>(
    promoId
      ? restaurants.filter((rst) => promoRestaurants.includes(rst.id))
      : []
  );

  useEffect(() => {
    dispatch({
      type: 'stateChanged',
      target: 'restaurants',
      payload: selectedList.map((rst) => rst.id),
    });
  }, [selectedList, dispatch]);

  // Handlers
  function mapRestaurantsToSelectOptions(
    restaurants: RestaurantResDTO[]
  ): OptionSelect[] {
    return restaurants.map((restaurant) => {
      return { label: restaurant.name, value: restaurant.id };
    });
  }
  function HandleCheck(value: string) {
    if (value === 'All restaurants') {
      dispatch({
        type: 'stateChanged',
        target: 'restaurants',
        payload: restaurants.map((rst) => rst.id),
      });
      setSelectedList(restaurants);
      setSelectOptions([]);
    }
  }

  function handleSelect(option: string) {
    // remove restaurant from search list
    setSelectOptions((previous) => {
      const copiedSelectOptions = [...previous];
      const index = copiedSelectOptions.findIndex(
        (item) => item.value === option
      );
      copiedSelectOptions.splice(index, 1);
      return copiedSelectOptions;
    });

    // add restaurant to selected list
    const restaurantId = restaurants.find((rst) => rst.id === option);
    setSelectedList((previous) => {
      const list = [...previous, restaurantId!];
      return list;
    });
  }

  function renderSelectedRestaurants() {
    return selectedList.map((rst) => {
      return (
        <SelectedItem
          item={rst}
          handleClose={() => handleClose(rst.id as string)}
          key={`selected-item-${rst.id}`}
        />
      );
    });
  }

  function handleClose(restaurantId: string) {
    // remove from list
    setSelectedList((previous) => {
      const index = previous.findIndex((elem) => elem.id === restaurantId);
      const copiedList = [...previous];
      copiedList.splice(index, 1);
      return copiedList;
    });

    // add to searchList
    setSelectOptions((previous) => {
      const copiedSelectOptions = [...previous];
      const restaurant = restaurants?.find(
        (elem: RestaurantResDTO) => elem.id === restaurantId
      );
      copiedSelectOptions.push({
        value: restaurant?.id,
        label: restaurant?.name,
      });
      return copiedSelectOptions;
    });
  }

  return (
    <BackgroundDetailsForm>
      <div className='promo-multi-select-container'>
        <div className='promo-multi-select-input-group'>
          <TitleInputGroup title={title} />
          <DoubleCheckbox
            firstChoice='Selected restaurants'
            secondChoice='All restaurants'
            handleCheck={HandleCheck}
            selectedChoice={selectedChoice}
          />
          <div style={{ marginTop: 20 }} />
          <InputSelect
            options={selectOptions}
            initialValue={promoRestaurants}
            handleSelect={handleSelect}
          />
        </div>
        <div className='promo-multi-select-results-container'>
          {selectedList.length > 0 && renderSelectedRestaurants()}
        </div>
      </div>
    </BackgroundDetailsForm>
  );
};

export default MultiSelectRestaurants;
