import React, { useEffect, useState } from "react";
import { format, parse } from "date-fns";
import Modal from "react-modal";
import { customStyles } from "../../../../../../components/popup/customStyle";
import { NotificationByOrdersDialogProps, NotificationFormFormik } from "./props";
import "./styles.css";
import NotificationDialogByOrdersHeader from "./components/header";
import NotificationDialogByOrdersFilters from "./components/filters";
import { INotificationByOrdersFilter } from "./components/filters/props";
import { useFormik } from "formik";
import { createForm } from "../../../../../../../../utils/formik";
import InputText from "../../../../../../../../components/input-text";
import { useTranslation } from "../../../../../../../../components/translation-context/TranslationContext";
import NotificationByOrdersTemplate from "./components/template";
import NotificationByOrdersDate from "./components/date";
import NotificationByOrdersMedia from "./components/media";
import { useCreateNotificationByOrderIds } from "../../../../../../../../services/go-api/notifications/notifications";
import { NotificationDeliveryType } from "../../../../../../../../dtos/go-api";
import Service from "../../../../../../../../services/Service";
import { usePopupContext } from "../../../../../../components/popup-context/context";
import { EnumPopupIndex } from "../../../../../../components/popup-context/props";
import BusyIndicator from "../../../../../../../../components/busy-indicator";

const NotificationByOrdersDialog = ({ isEveryOrderSelected, isOpen, onClose, q, minDate, maxDate, selectedPaymentTypes, selectedProducts, selectedCompanies, selectedRestaurants, selectedOrders, selectedContentOrders }: NotificationByOrdersDialogProps) => {
	// Attributes
	const { showPopup } = usePopupContext();
	const { translate } = useTranslation();
	const [filters, setFilters] = useState<INotificationByOrdersFilter>({
		companies: [],
		paymentTypes: [],
		products: [],
		restaurants: [],
	});
	const notificationFormFormik = useFormik<NotificationFormFormik>({
		initialValues: {
			title: "",
			description: "",
			deliveryType: NotificationDeliveryType.PUSH,
			publicationDay: format(new Date(), "yyyy-MM-dd"),
			publicationTime: format(new Date(), "HH:mm"),
		},
		onSubmit: handleSave,
	});
	const notificationForm = createForm(notificationFormFormik.values, {
		...notificationFormFormik,
	});
	const { mutateAsync: createNotification, isLoading } = useCreateNotificationByOrderIds({
		mutation: {
			onSuccess(data, variables, context) {
				Service.success.push(translate("Notification created !"));
			},
			onError(error, variables, context) {
				Service.errors.push(translate("Something wrong happened"));
			},
			onSettled(data, error, variables, context) {
				notificationFormFormik.resetForm();
				onClose();
			},
		},
	});

	// Effects
	// Set filters
	useEffect(() => {
		setFilters((prev) => {
			prev["paymentTypes"] = selectedPaymentTypes;
			prev["companies"] = selectedCompanies;
			prev["products"] = selectedProducts;
			prev["restaurants"] = selectedRestaurants;

			return { ...prev };
		});
	}, [selectedPaymentTypes, selectedProducts, selectedCompanies, selectedRestaurants]);

	// Handlers
	async function handleSave() {
		const publicationDate = new Date(notificationForm.publicationDay.value);

		const parsedDateTime = parse(notificationForm.publicationTime.value, "HH:mm", publicationDate);

		if (notificationForm.title.value === "" || notificationForm.description.value === "") {
			showPopup(EnumPopupIndex.MANDATORY);
			return;
		}

		if (isEveryOrderSelected) {
			await createNotification({
				params: {
					q: q,
					companyIds: selectedCompanies,
					restaurantIds: selectedRestaurants,
					productIds: selectedProducts,
					paymentTypes: selectedPaymentTypes,
					minDate: minDate,
					maxDate: maxDate,
				},
				data: {
					title: notificationForm.title.value,
					description: notificationForm.description.value,
					deliveryType: notificationForm.deliveryType.value,
					publicationDate: parsedDateTime.toISOString(),
					orderIds: [],
				},
			});
		} else {
			await createNotification({
				data: {
					title: notificationForm.title.value,
					description: notificationForm.description.value,
					orderIds: selectedOrders,
					deliveryType: notificationForm.deliveryType.value,
					publicationDate: parsedDateTime.toISOString(),
				},
			});
		}
	}
	return (
		<Modal
			isOpen={isOpen}
			style={{
				content: { ...customStyles.content, padding: 0 },
				overlay: { ...customStyles.overlay },
			}}
			onRequestClose={onClose}
		>
			<BusyIndicator queries={[isLoading]}>
				<NotificationDialogByOrdersHeader onClose={onClose} onSave={notificationForm.submitForm} />
				<div className="notification-dialog-header-bar" />
				<div className="notification-dialog-body-container">
					<NotificationDialogByOrdersFilters isEveryOrderSelected={isEveryOrderSelected} filters={filters} selectOrders={selectedOrders} selectedContentOrders={selectedContentOrders} />
					<NotificationByOrdersTemplate changeTitle={(value: string) => notificationForm.title.onChange(value)} changeDescription={(value: string) => notificationForm.description.onChange(value)} />
					<div className="notification-dialog-body-input-title">{translate("title")}</div>
					<InputText value={notificationForm.title.value} onChange={(event: any) => notificationForm.title.onChange(event.target.value)} placeholder={translate("insert title of the notification")} required={true} />
					<div className="notification-dialog-body-input-description">{translate("describe_notification").toUpperCase()}</div>
					<InputText value={notificationForm.description.value} onChange={(event: any) => notificationForm.description.onChange(event.target.value)} placeholder={translate("discover our new crispy chicken sandwich !!!")} required={true} type={"textarea"} />
					<NotificationByOrdersDate publicationDay={notificationForm.publicationDay.value} onChangeDay={notificationForm.publicationDay.onChange} publicationHour={notificationForm.publicationTime.value} onChangeHour={notificationForm.publicationTime.onChange} />
					<NotificationByOrdersMedia currentDeliveryType={notificationForm.deliveryType.value} onChange={(value: string) => notificationForm.deliveryType.onChange(value)} />
				</div>
			</BusyIndicator>
		</Modal>
	);
};

export default NotificationByOrdersDialog;
