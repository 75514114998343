import React from "react";

const Search = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox="0 0 13 13" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M12.0954 11.3717L9.11701 8.39216C9.76378 7.58696 10.1509 6.5637 10.1509 5.45047C10.1509 2.85413 8.04663 0.75 5.45047 0.75C2.85413 0.75 0.75 2.85431 0.75 5.45047C0.75 8.04681 2.85431 10.1509 5.45047 10.1509C6.56429 10.1509 7.58798 9.76339 8.39335 9.11607L11.3715 12.0953C11.4812 12.205 11.6193 12.2574 11.7596 12.2492C11.8938 12.2413 12.0114 12.1794 12.0953 12.0954C12.1793 12.0115 12.2412 11.894 12.2492 11.7598C12.2574 11.6195 12.2051 11.4814 12.0954 11.3717ZM5.45047 9.12736C3.4196 9.12736 1.77358 7.48148 1.77358 5.45047C1.77358 3.4196 3.41946 1.77358 5.45047 1.77358C7.48135 1.77358 9.12736 3.41946 9.12736 5.45047C9.12736 7.48134 7.48148 9.12736 5.45047 9.12736Z"
				strokeWidth="0.5"
				stroke={props.fill}
			/>
		</svg>
	);
};
export default Search;
