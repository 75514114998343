import React, { useReducer } from "react";
import InputText from "../../../../../../../../../components/input-text";
import InputWithLabel from "../../../../../../../../../components/input-with-label";
import TextArea from "../../../../../../../../../components/text-area";
import { NotificationTemplateDTO } from "../../../../../../../../../dtos/go-api";
import BackgroundDetailsForm from "../../../../../../../components/background-details-form";
import GroupInputDetails from "../../../../../../../components/group-input-details";
import { NotificationTemplateReducerAction } from "../../reducer";
import { TemplateTitleSectionProps } from "./props";

export default function TemplateTitleSection({
  template,
  dispatch,
}: TemplateTitleSectionProps) {
  // Render
  return (
    <BackgroundDetailsForm style={{ height: "none" }}>
      <GroupInputDetails title="title">
        <InputText
          required={true}
          placeholder="new"
          value={template.title}
          onChange={(event: any) =>
            dispatch({
              type: "stateChanged",
              target: "title",
              payload: event.target.value,
            })
          }
          size="medium"
          style={{ marginTop: 8, marginBottom: 44 }}
        />
        <InputWithLabel label="describeTemplate">
          <TextArea
            required={true}
            placeholder="describeTemplatePlaceHolder"
            size="medium"
            value={template.description}
            style={{ resize: "none" }}
            rows={4}
            onChange={(event: any) =>
              dispatch({
                type: "stateChanged",
                target: "description",
                payload: event.target.value,
              })
            }
          />
        </InputWithLabel>
      </GroupInputDetails>
    </BackgroundDetailsForm>
  );
}
