import React from "react";

const DownArrow = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg width="14" height="7" viewBox="0 0 14 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.3598 0.610102C12.5063 0.463311 12.7437 0.463298 12.8902 0.610073C13.0366 0.756848 13.0366 0.994831 12.8902 1.14162L7.92918 6.11456C7.41693 6.62813 6.58637 6.62853 6.07366 6.11546L1.11007 1.1493C0.963494 1.00265 0.963283 0.764664 1.1096 0.617751C1.25592 0.470837 1.49335 0.470626 1.63993 0.617279L6.60354 5.58346C6.82328 5.80336 7.17924 5.80319 7.3988 5.58306L12.3598 0.610102Z" fill="black" stroke="black" stroke-width="0.857143" />
        </svg>
    );
};
export default DownArrow;
