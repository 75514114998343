import { NotificationTemplateDTO } from "../../../../../../../dtos/go-api";

export const initialNotificationTemplate: NotificationTemplateDTO = {
  id: "",
  name: "",
  title: "",
  description: "",
};

export interface NotificationTemplateReducerAction {
  type: "serverChanged" | "stateChanged";
  target: string;
  payload: any;
}

export const reducer = (
  state: NotificationTemplateDTO,
  action: NotificationTemplateReducerAction
) => {
  switch (action.type) {
    case "serverChanged":
      return {
        ...state,
        ...action.payload,
      };

    case "stateChanged":
      return {
        ...state,
        [action.target]: action.payload,
      };
  }
};
