import React from "react";
import { InputSelectProps } from "./props";
import Select from "react-select";

const customStyles = {
  option: (provided: any) => ({
    ...provided,
    backgroundColor: "none",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#7F85A2",
    cursor: "pointer",
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    outline: "none",
    //width: 'fill',
    borderWidth: 2,
    borderColor: "rgba(143, 146, 161, 0.2)",
    height: "100%",
    minHeight: "48px",
    width: "100%",
    minWidth: "251px",
    boxShadow: "none !important",
  }),
  singleValue: () => ({
    opacity: 1,
  }),
  menu: (provided: any) => ({
    ...provided,
    width: "100%",
    heigth: "100%",
  }),
};

const InputSelect = ({
  options,
  initialValue,
  handleSelect,
  isSearchable = true,
  placeholder = "Select...",
}: InputSelectProps) => {
  // Handlers
  function handleChange(option: any) {
    handleSelect(option.value);
  }

  function getValueFromOptions() {
    if (Array.isArray(initialValue)) {
      return initialValue
        .map((item: any) => {
          return options.find((option) => option?.value === item) ?? null;
        })
        .reverse();
    }
    // Check if option is checked
    return options.find(
      (option) =>
        option?.label === initialValue || option?.value === initialValue
    );
  }

  return (
    <Select
      styles={customStyles}
      options={options}
      value={getValueFromOptions()}
      onChange={handleChange}
      placeholder={placeholder}
      maxMenuHeight={150}
      isSearchable={isSearchable}
    />
  );
};

export default InputSelect;
