import React from "react";
import BackButton from "./components/back-button";
import { HeaderProps } from "./props";
import "./style.css";
import NavigationHistory from "./components/navigation-history";
import ActionButton from "../action-button";
import { useHistory } from "react-router-dom";
import { usePopupContext } from "../../modules/dashboard/components/popup-context/context";
import { EnumPopupIndex } from "../../modules/dashboard/components/popup-context/props";

const Header = ({
    title,
    hasBackButton,
    saveButton,
    actionButtons,
    otherButtons,
    navigationHeader,
    subTitle,
    isUpdated,
    disableSaveButton = false,
    goBackUrl,
}: HeaderProps) => {
    // Attributes
    const history = useHistory();
    const { showPopup } = usePopupContext();

    // Handlers
    function renderActionButtons() {
        return actionButtons?.routes.map((route: any, index: any) => {
            const urlEnd = actionButtons?.id
                ? `${actionButtons?.id}/${route}`
                : `${route}`;

            if (index !== actionButtons?.routes.length - 1) {
                return (
                    <>
                        <ActionButton
                            isActive={
                                !disableSaveButton &&
                                history.location.pathname.includes(
                                    `/dashboard/${actionButtons?.menu}/${urlEnd}`
                                )
                            }
                            handleAction={() =>
                                history.push(
                                    `/dashboard/${actionButtons?.menu}/${urlEnd}`
                                )
                            }
                            name={route}
                        />
                        <div style={{ marginRight: 14 }} />
                    </>
                );
            }

            return (
                <ActionButton
                    isActive={
                        disableSaveButton &&
                        history.location.pathname.includes(
                            `/dashboard/${actionButtons?.menu}/${urlEnd}`
                        )
                    }
                    handleAction={() =>
                        history.push(
                            `/dashboard/${actionButtons?.menu}/${urlEnd}`
                        )
                    }
                    name={route}
                />
            );
        });
    }

    const form = document.querySelector("form");

    function handleGoBack() {
        const form = document.querySelector("form");

        if (isUpdated) {
            return showPopup(EnumPopupIndex.LEAVE);
        }

        if (!form || form.dataset.status === "clean") {
            goBackUrl ? history.push(goBackUrl) : history.goBack();
            return;
        }

        showPopup(EnumPopupIndex.LEAVE);
    }

    return (
        <div className="header-container">
            <div className="header-title-container">
                {hasBackButton && <BackButton onClick={handleGoBack} />}
                <div className="header-title">
                    <div>{title}</div>
                    {subTitle && (
                        <div className="header-subtitle">{subTitle}</div>
                    )}
                    {navigationHeader && (
                        <NavigationHistory
                            headerNavigation={navigationHeader}
                        />
                    )}
                </div>
            </div>
            {saveButton && saveButton}
            {actionButtons && (
                <div className="header-action-button-container">
                    {renderActionButtons()}
                </div>
            )}
            {otherButtons && (
                <div className="header-other-button-container">
                    {otherButtons}
                </div>
            )}
        </div>
    );
};

export default Header;
