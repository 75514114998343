import React from "react";
import InputWithLabel from "../../../../../../../../components/input-with-label";
import BackgroundDetailsForm from "../../../../../../components/background-details-form";
import GroupInputDetails from "../../../../../../components/group-input-details";
import InputGroup from "../../../../../../components/input-group";
import { actions } from "../../reducer";
import { COMPANY_FIELDS } from "../commercial-information/props";
import { KitchenPriorityProps } from "./props";
import './style.css';

const KitchenPriority = ({ company, dispatch }: KitchenPriorityProps) => {

  return (
    <BackgroundDetailsForm>
      <GroupInputDetails title="Priority">
        <InputGroup size="medium">
          <InputWithLabel label='ENTER A NUMBER'>
            <div className='company-information-priority'>
              <input
                className='company-information-priority-input'
                required={true}
                type='number'
                value={company.kitchenPriority}
                placeholder={'0'}
                onChange={(event: any) =>
                  dispatch({
                    type: actions.companyChanged,
                    target: COMPANY_FIELDS.KITCHEN_PRIORITY,
                    payload: parseInt(event.target.value),
                  })
                }
              />
            </div>
          </InputWithLabel>
        </InputGroup>
      </GroupInputDetails>
    </BackgroundDetailsForm>
  );
};

export default KitchenPriority;
