import React from "react";
import "./style.css";

const CardGroup = ({ children, customStyle }: any) => {
  return (
    <div className="dashboard-card-group-container" style={customStyle}>
      {children}
    </div>
  );
};

export default CardGroup;
