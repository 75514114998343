import { CompanyReqCreateDTO } from "../../../../../../../../dtos/go-api";

export interface OrderClosingHoursProps {
company: CompanyReqCreateDTO,
    dispatch: any,
    type: ClosingHoursType
}

export enum ClosingHoursType {
    START = "START",
    END = "END"
}