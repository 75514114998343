import React from 'react';
import Translation from '../../../../../../components/translation';
import { ReactComponent as AddIcon } from './assets/add.svg';
import { AddButtonProps } from './props';
import './style.css'

const EmptyAddButton = ({ title, handleOnClick }: AddButtonProps) => {
    return (
        <div className='empty-states-add-button-container' onClick={handleOnClick}>
            <AddIcon />
            <div className='empty-states-add-button-text'>
                <Translation>{title}</Translation>
            </div>

        </div>
    )
}

export default EmptyAddButton;