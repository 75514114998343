export default interface ActionButtonProps {
  name: string;
  backgroundColor?: any;
  handleAction: any;
  style?: any;
  isActive?: boolean;
  type?: "right" | "bottom";
  value?: any;
  isBeta?: boolean;
}

export enum ACTION_ICON {
  COMPANIES = "companies",
  INDEX = "index",
  DISHES = "dishes",
  DETAILS = "details",
  RESTAURANTS = "restaurants",
  USERS = "users",
  EMPLOYEES = "employees",
  STATISTICS = "statistics?type=day",
  STATISTICSV2 = "statistics-v2",
  PROMO = "promos",
  CLIENT = "client",
  ORDERS = "orders?type=day",
  OLD_ORDERS = "old-orders?type=day",
  NEW_ORDERS = "new-orders",
  NOTIFICATIONS = "notifications",
  ZONES = "zones",
  APP_VERSIONING = "app-versioning",
  SCREENS = "screens",
  PREPARATIONS_TIME = "preparations-time",
}
