import React, { useState } from 'react';
import { FiltersName } from '../../props';
import NotificationByOrdersFilterTag from './components/filter-tag';
import { NotificationDialogByOrdersFiltersProps } from './props';
import Polygon from './components/filter-tag/assets/polygon';
import './styles.css';
import { useTranslation } from '../../../../../../../../../../components/translation-context/TranslationContext';

const NotificationDialogByOrdersFilters = ({
  isEveryOrderSelected,
  filters,
  selectOrders,
  selectedContentOrders,
}: NotificationDialogByOrdersFiltersProps) => {
  // Attributes
  const { translate } = useTranslation()

  const [isHovered, setIsHovered] = useState(false);

  //Render
  return (
    <div className='notification-by-orders-filters'>

      {!isEveryOrderSelected && (
        <div
          className='notification-by-orders-filter-tag'
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {`${selectOrders?.length} selected`}
        </div>
      )}
      {isEveryOrderSelected && (
        <div className='notification-by-orders-filter-tag'>
          {translate("All Selected")}
        </div>
      )}

      {isHovered && selectOrders?.length > 0 && (
        <div
          className='table-action-pop-over-body-selected-container'
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <div className='notification-by-orders-filter-poligon-selected'>
            <Polygon />
          </div>
          <div className={'table-action-pop-over-selected'}>
            <div className={'table-action-pop-over-body-selected'}>
              {selectedContentOrders?.map(item =>
                <div>
                  <div className='notification-by-orders-filter-items-selected'>
                    {item.email}
                  </div>
                  <div className='notification-by-orders-filter-items-selected-company'>
                    {item.company}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {Object.keys(filters).map((key) => {
        const total = filters[key as keyof typeof FiltersName].length;
        if (total === 0) {
          return <></>;
        }
        return (
          <NotificationByOrdersFilterTag
            name={key as keyof typeof FiltersName}
            total={total}
            filters={filters}
          />
        );
      })}

    </div>
  );
};

export default NotificationDialogByOrdersFilters;
