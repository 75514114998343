import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CompanyResDTO } from '../../../../../../../../dtos/go-api';
import BackgroundDetailsForm from '../../../../../../components/background-details-form';
import InputSelect from '../../../../../../components/input-select';
import { OptionSelect } from '../../../../../../components/input-select/props';
import TitleInputGroup from '../../../../../../components/title-input-group';
import DoubleCheckbox from '../double-checkbox';
import { PromoQueryParams } from '../props';
import CompanyList from './component/list';
import { PromoMultiSelectProps } from './props';
import './style.css';

const MultiSelectCompanies = ({
  title,
  dispatch,
  promoCompanies,
  promo,
  companies,
}: PromoMultiSelectProps) => {
  // Attribute
  const { promoId } = useParams<PromoQueryParams>();
  const selectedChoice =
    companies?.length !== 0 && promo.companies.length === companies?.length
      ? 'All companies'
      : 'Selected companies';
  const [options, setOptions] = useState<OptionSelect[]>(
    mapCompaniesToSelectOption(
      promoId
        ? companies.filter((cmp) => !promoCompanies.includes(cmp.id))
        : companies
    )
  );
  const [selectedList, setSelectedList] = useState<CompanyResDTO[]>(
    promoId ? companies.filter((cmp) => promoCompanies.includes(cmp.id)) : []
  );

  // Effects
  // Update promoDTO with new selected list
  useEffect(() => {
    dispatch({
      type: 'stateChanged',
      target: 'companies',
      payload: selectedList.map((cmp) => cmp.id),
    });
  }, [selectedList, dispatch]);

  // Handlers
  function HandleCheck(value: string) {
    if (value === 'All companies' && companies) {
      // Update promoDTO
      const companyIds = companies.map((company) => company.id as string);
      dispatch({
        type: 'stateChanged',
        target: 'companies',
        payload: companyIds,
      });

      // Update selected companies list
      setSelectedList(companies);

      // Update input select options
      setOptions([]);
    }
  }

  function mapCompaniesToSelectOption(
    companies: CompanyResDTO[]
  ): OptionSelect[] {
    return companies.map((company) => {
      let option = { label: '', value: '' };
      option.label = company.name as string;
      option.value = company.id as string;

      return option;
    });
  }

  function handleSelect(id: string) {
    // remove company from search list
    setOptions((previous) => {
      const copiedSearchList = [...previous];
      const index = copiedSearchList.findIndex((item) => item.value === id);
      copiedSearchList.splice(index, 1);
      return copiedSearchList;
    });

    // add company to selected list
    const company = companies?.find((company) => company.id === id);
    setSelectedList((previous) => {
      if (company) {
        const list = [...previous, company];
        return list;
      }
      return previous;
    });
  }

  function handleClose(companyId: string) {
    // remove from list
    setSelectedList((previous) => {
      const index = previous.findIndex((elem) => elem.id === companyId);
      const copiedList = [...previous];
      copiedList.splice(index, 1);
      return copiedList;
    });

    // add to searchList
    setOptions((previous) => {
      const copiedSearchList = [...previous];
      const company = companies?.find(
        (elem: CompanyResDTO) => elem.id === companyId
      );

      copiedSearchList.push({ label: company?.name, value: company?.id });
      return copiedSearchList;
    });
  }

  return (
    <BackgroundDetailsForm>
      <div className='promo-multi-select-container'>
        <div className='promo-multi-select-input-group'>
          <TitleInputGroup title={title} />
          <DoubleCheckbox
            firstChoice='Selected companies'
            secondChoice='All companies'
            handleCheck={HandleCheck}
            selectedChoice={selectedChoice}
          />
          <div style={{ marginTop: 20 }} />
          <InputSelect
            options={options}
            initialValue={promoCompanies}
            handleSelect={handleSelect}
          />
        </div>
        <div className='promo-multi-select-results-container'>
          <CompanyList companies={selectedList} handleClose={handleClose} />
        </div>
      </div>
    </BackgroundDetailsForm>
  );
};

export default MultiSelectCompanies;
