import React, { useState } from "react";
import { RestaurantsIndicatorProps } from "./props";

const RestaurantsIndicator = ({ restaurants }: RestaurantsIndicatorProps) => {
	// Attributes
	const firstRestaurant = restaurants?.[0];
	const lastRestaurants = restaurants?.slice(1, restaurants.length);
	const [isHovering, setIsHovering] = useState<boolean>(false);

	// Render
	return (
		<div style={customStyles.container}>
			<span style={customStyles.text}>{firstRestaurant}</span>
			{lastRestaurants && lastRestaurants.length > 0 && (
				<>
					<div
						style={customStyles.indicatorContainer}
						onMouseEnter={() => setIsHovering(true)}
						onMouseLeave={() => setIsHovering(false)}
					>
						<span style={customStyles.indicatorText}>
							+{lastRestaurants?.length}
						</span>

						{/* TOOLTIP */}
						{isHovering && (
							<div style={customStyles.tooltipContainer}>
								<div style={customStyles.tooltipArrow} />
								<span style={customStyles.tooltipText}>
									{lastRestaurants.join(", ")}
								</span>
							</div>
						)}
					</div>
				</>
			)}
		</div>
	);
};

const customStyles: any = {
	container: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	text: {
		color: "#7F85A2",
	},
	indicatorContainer: {
		marginLeft: ".5rem",
		minWidth: "1.5rem",
		height: "1.5rem",
		backgroundColor: "#F5C755",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		borderRadius: ".4375rem",
		position: "relative",
	},
	indicatorText: {
		color: "#061A42",
		fontSize: ".5859rem",
	},
	tooltipContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		position: "absolute",
		backgroundColor: "#F5C755",
		left: "2.125rem",
		padding: "0 .25rem",
		zIndex: 10,
	},
	tooltipArrow: {
		position: "absolute",
		left: "-.1875rem",
		top: ".3125rem",
		width: ".625rem",
		height: ".625rem",
		backgroundColor: "#F5C755",
		transform: "rotate(45deg)",
		zIndex: 9,
	},
	tooltipText: {
		color: "#061A42",
		fontSize: ".5859rem",
		zIndex: 10,
		whiteSpace: "nowrap",
	},
};

export default RestaurantsIndicator;
