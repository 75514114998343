import Axios from 'axios';
import { CompanyRestaurantDTO } from '../dtos/companyRestaurantDTO'
import CompanyRestaurantMockService from './mocks/CompanyRestaurantMockService'
import Service from './Service';

const companyRestaurants: CompanyRestaurantDTO[] = [];

async function get(companyId: string) {
    return Service.execute((Axios.get(`${Service.API_URL}/v1/api/companies/${companyId}/restaurants`, Service.config())));
}

async function put(companyId: string, companyRestaurant: CompanyRestaurantDTO) {
    companyRestaurant.indexPrice = parseFloat(companyRestaurant.indexPrice as any);
    return Service.execute((Axios.put(`${Service.API_URL}/v1/api/companies/${companyId}/restaurants/${companyRestaurant.id}`, companyRestaurant, Service.config())));
}

let exported = { get, put };

if(process.env.REACT_APP_MOCKED === "true")
{
    exported = CompanyRestaurantMockService;
}

export default exported;