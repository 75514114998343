import React from "react";
import { Route, Switch } from "react-router-dom";
import OrdersDetail from "./modules/detail";
import OrdersList from "./modules/list";

const OldOrders = () => {
  return (
    <Switch>
      <Route path="/dashboard/old-orders/:id" component={OrdersDetail} />
      <Route path="/dashboard/old-orders" component={OrdersList} />
    </Switch>
  );
};

export default OldOrders;
