import React from 'react';
import { useTranslation } from '../translation-context/TranslationContext';
import { TextAreaProps } from './props';
import './style.css';

const TextArea = ({
	onChange,
	value,
	placeholder,
	style,
	size = 'medium',
	required,
	rows = 3,
}: TextAreaProps) => {
	// Attributes
	const { translate } = useTranslation();

	return (
		<textarea
			rows={rows}
			required={required}
			className={`text-area-container ${size}`}
			value={value}
			placeholder={translate(placeholder)}
			onChange={onChange}
			style={{ ...style }}
		/>
	);
};

export default TextArea;
