import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "../../../../../../../../components/translation-context/TranslationContext";
import { ReactComponent as EditIcon } from "./assets/edit.svg";
import "./style.css";

const NotificationsListHeader = () => {
  // Attributes
  const { translate } = useTranslation();
  const history = useHistory();

  const handleClick = () => {
    history.push(`/dashboard/notifications/templates`);
  };

  // Render
  return (
    <div className="notifications-header-container">
      <div className="notifications-header-title">
        <div>{translate("notifications")}</div>
      </div>
      <div className="manage-notification-templates" onClick={handleClick}>
        <EditIcon />
        <div>{translate("Manage templates")}</div>
      </div>
    </div>
  );
};

export default NotificationsListHeader;
