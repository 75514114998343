import React, { Fragment, useState } from 'react';
import { useTranslation } from '../../../../../../../../../../../../components/translation-context/TranslationContext';
import { NotificationByOrdersFilterTagProps } from './props';
import { useGetAllCompanies } from '../../../../../../../../../../../../services/go-api/company/company';
import './styles.css';
import { useGetAllProducts } from '../../../../../../../../../../../../services/go-api/product/product';
import { useGetRestaurants } from '../../../../../../../../../../../../services/go-api/restaurant/restaurant';
import Polygon from './assets/polygon';
import { FiltersName } from '../../../../props';

const NotificationByOrdersFilterTag = ({
  name,
  total,
  filters,
}: NotificationByOrdersFilterTagProps) => {
  // Attributes
  const { translate } = useTranslation();
  const [isHovered, setIsHovered] = useState(false);

  const { data: companiesNames, isLoading: isCompanyLoading } =
    useGetAllCompanies(
      { companyIds: filters?.companies },
      { query: { enabled: filters?.companies?.length > 0 } }
    );
  const { data: productsNames, isLoading: isProductLoading } =
    useGetAllProducts(
      { productIds: filters?.products, isAvailable: true },
      { query: { enabled: filters?.products?.length > 0 } }
    );
  const { data: restaurantsNames, isLoading: isRestaurantLoading } =
    useGetRestaurants(
      { restaurantIds: filters?.restaurants },
      { query: { enabled: filters?.restaurants?.length > 0 } }
    );

  function getFilterContent() {
    if (total === 0) {
      return <Fragment />;
    }
    switch (name) {
      case FiltersName.companies:
        return companiesNames?.data?.map(({ name }) => (
          <div className='notification-by-orders-filter-items'>{name}</div>
        ));
      case FiltersName.paymentTypes:
        return filters?.paymentTypes.map((payment) => (
          <div className='notification-by-orders-filter-items'>{payment}</div>
        ));
      case FiltersName.products:
        return productsNames?.data?.map(({ name }) => (
          <div className='notification-by-orders-filter-items'>{name}</div>
        ));
      case FiltersName.restaurants:
        return restaurantsNames?.data?.map(({ name }) => (
          <div className='notification-by-orders-filter-items'>{name}</div>
        ));
      default:
        return [];
    }
  }

  return (
    <div style={{ position: 'relative' }}>
      <div
        className={
          !isCompanyLoading && !isProductLoading && !isRestaurantLoading
            ? 'notification-by-orders-filter-tag'
            : 'notification-by-orders-filter-tag-loading'
        }
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {`${total} ${translate(name)}`}
      </div>
      {isHovered &&
        total > 0 &&
        !isCompanyLoading &&
        !isProductLoading &&
        !isRestaurantLoading && (
          <div
            className={'table-action-pop-over'}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <div className={'notification-by-orders-filter-poligon'}>
              <Polygon />
            </div>
            <div className={'table-action-pop-over-body'}>
              {getFilterContent()}
            </div>
          </div>
        )}
    </div>
  );
};

export default NotificationByOrdersFilterTag;
