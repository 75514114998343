import React from "react";
import { NotificationStatusBadgeProps } from "./props";
import "./style.css";

const NotificationStatusBadge = ({
	text,
	sent,
	customStyles,
}: NotificationStatusBadgeProps) => {
	//Render
	return (
		<div
			className="notification-status-badge-container"
			style={
				sent
					? {
							backgroundColor: "rgba(40, 98, 97, 0.34)",
							color: "#286261",
							...customStyles,
					  }
					: {
							backgroundColor: "rgba(229, 147, 104, 0.3)",
							color: "#EA7E45",
							...customStyles,
					  }
			}
		>
			{text}
		</div>
	);
};

export default NotificationStatusBadge;
