import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import RestaurantDetail from './modules/detail';
import RestaurantsDishes from './modules/dishes';
import RestaurantList from './modules/list';

const Restaurants = () => {
    return (
        <Switch>
            <Route path='/dashboard/restaurants/list' component={RestaurantList} />
            <Route path='/dashboard/restaurants/:restaurantId/dishes' component={RestaurantsDishes} />
            <Route path='/dashboard/restaurants/:restaurantId/details' component={RestaurantDetail} />
            <Route path='/dashboard/restaurants/details' component={RestaurantDetail} />
            <Route path='/dashboard/restaurants/' render={() => <Redirect to='/dashboard/restaurants/list' />} />
        </Switch>
    )
}

export default Restaurants;