import React, { useEffect, useRef, useState } from 'react';

const Form = ({ children }: any) => {

    // Attributes
    const [dirty, setDirty] = useState<boolean>(false);

    const form = useRef<HTMLFormElement>(null);

    useEffect(() => {
        form.current?.addEventListener("input", onFormInput);
        return () => form.current?.removeEventListener("input", onFormInput);
    }, []);

    function onFormInput() {
        if (dirty) {
            return;
        }

        setDirty(true);
    }

    return (
        <form ref={form} className='form' data-status={dirty ? 'dirty' : 'clean'}>
            {children}
        </form>
    )
}

export default Form;