/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */

export type RestaurantType = typeof RestaurantType[keyof typeof RestaurantType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RestaurantType = {
  B2C: 'B2C',
  B2B: 'B2B',
  BOTH: 'BOTH',
  UNKNOWN: 'UNKNOWN',
} as const;
