import React from 'react'

import './style.css'

import { MenuListContainerProps } from './props';

const MenuListContainer = ({ children }: MenuListContainerProps) => {

    return (
        <div className='menu-list-container'>
            {

                children
            }
        </div>
    )
}

export default MenuListContainer;