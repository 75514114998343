import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AddButton from '../../../../../../components/add-button';
import BusyIndicator from '../../../../../../components/busy-indicator';
import Header from '../../../../../../components/header';
import SegmentTab from '../../../../../../components/segment-tab';
import useSearch from '../../../../../../hooks/use-search';
import { useGetRestaurants } from '../../../../../../services/go-api/restaurant/restaurant';
import CardGroup from '../../../../components/card-group';
import EmptyStates from '../../../../components/empty-states';
import MenuListContainer from '../../../../components/menu-list-container';
import PageContainer from '../../../../components/page-container';
import Search from '../../../../components/search';
import RestaurantCard from './components/restaurant-card';
import './style.css';

const RestaurantList = () => {
	// Attributes
	const history = useHistory();
	const restaurant = useGetRestaurants(
		{ size: 1000 },
		{ query: { refetchOnWindowFocus: false } }
	);

	const restaurants = restaurant.data && restaurant.data.data;
	const { search, setSearch } = useSearch<any>(restaurants ?? [], ['name']);

	const [restaurantType, setRestaurantType] = useState<string>('All');

	const filteredResataurants = useMemo(() => {
		if (search) {
			return restaurants?.filter((restaurant) =>
				restaurant?.name?.toLowerCase().includes(search.toLowerCase())
			);
		}

		if (restaurantType === 'B2C' || restaurantType === 'B2B') {
			return restaurants?.filter(
				(restaurant) =>
					restaurant?.restaurantType === restaurantType ||
					restaurant?.restaurantType === 'BOTH'
			);
		}

		return restaurants;
	}, [restaurants, restaurantType, search]);

	function renderList() {
		if (filteredResataurants) {
			return filteredResataurants?.map((restaurant) => (
				<RestaurantCard restaurant={restaurant} key={`${restaurant.id}`} />
			));
		}
	}

	return (
		<BusyIndicator query={restaurant}>
			<PageContainer>
				<Header title='Our restaurants' />
				{restaurants && restaurants.length > 0 ? (
					<MenuListContainer>
						<div className='dashboard-companies-actions-container'>
							<div className='dashboard-restaurants-actions-first-part'>
								<Search
									value={search}
									placeholder='Search for a restaurant'
									onChange={setSearch}
								/>
								<div style={{ marginRight: 31 }} />
								<SegmentTab
									segments={['All', 'B2B', 'B2C']}
									handleChange={(segment: string) => setRestaurantType(segment)}
								/>
							</div>
							<AddButton
								title='Add new restaurant'
								handleClick={() =>
									history.push('/dashboard/restaurants/details')
								}
							/>
						</div>
						<CardGroup>{restaurant && renderList()}</CardGroup>
					</MenuListContainer>
				) : (
					<EmptyStates menu='restaurants' />
				)}
			</PageContainer>
		</BusyIndicator>
	);
};

export default RestaurantList;
