import { Dispatch, SetStateAction } from "react";

export interface OrdersListHeaderProps {
	timeScale: TimeScale;
	totalOrders: number;
	startDate: Date | null;
	setStartDate: Dispatch<SetStateAction<Date | null>>;
	endDate: Date | null;
	setEndDate: Dispatch<SetStateAction<Date | null>>;
}

export enum TimeScale {
	Hour = "hour",
	Day = "day",
	Week = "week",
	Month = "month",
	Pick = "pick",
}
