import React from "react";
import Select, { ValueType } from "react-select";
import { OrderTimingType } from "../../../../../../../../dtos/go-api";
import { actions } from "../../reducer";
import { OrderTimingProps } from "./props";

const OrderTiming = ({ company, dispatch }: OrderTimingProps) => {
  //Attributes
  const orderTimingOptions = [
    {
      value: OrderTimingType.NOON,
      label: "Noon (12am)",
    },
    {
      value: OrderTimingType.EVENING,
      label: "Evening (5pm)",
    },
    {
      value: OrderTimingType.BOTH,
      label: "Both",
    },
    {
      value: OrderTimingType.UNKNOWN,
      label: "UNKNOWN",
    },
  ];

  //Functions
  function getDefaultValue() {
    if (company.name) {
      return {
        value: company.orderTimingType,
        label: OrderTimingType[company.orderTimingType],
      };
    }
    return { value: OrderTimingType.NOON, label: "Noon (12am)" };
  }

  //Render
  return (
    <div style={{ marginTop: 74, width: 278 }}>
      <div className="input-group-title">Order Timing</div>
      <div>
        <label className="input-with-label-title">Time</label>
        {/* @ts-ignore */}
        <Select
          styles={style}
          value={getDefaultValue()}
          options={orderTimingOptions}
          onChange={(event: any) => {
            dispatch({
              type: actions.companyChanged,
              target: "orderTimingType",
              payload: event?.value ?? -1,
            });
          }}
        />
      </div>
    </div>
  );
};

const style = {
  control: (base: any) => ({
    ...base,
    border: "2px solid #8F92A133",
    // This line disable the blue border
    boxShadow: "none",
  }),
};

export default OrderTiming;
