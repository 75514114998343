import React from 'react';
import { useHistory } from 'react-router-dom';
import { NaviagationHistoryProps } from './props';
import './style.css'

const NavigationHistory = ({ headerNavigation }: NaviagationHistoryProps) => {


    // Attributes
    const history = useHistory()

    return (
        <div className="navigation-history-container">
            {headerNavigation.map((page, index) => {
                if (index !== headerNavigation.length - 1) {
                    return (
                        <>
                            <span className='navigation-history-link' onClick={() => history.push(page.path)}>{page.name}</span>
                            <span className="navigation-history-separator">-</span>
                        </>
                    )
                }
                return (
                    <span className='navigation-history-link'>{page.name}</span>
                )
            })}
        </div>
    )
}

export default NavigationHistory;