import React, { useState } from "react";
import Translation from "../../../../components/translation";
import { ToggleProps } from "./props";
import "./style.css";

const Toggle = ({
	handleClick,
	defaultValue = false,
	isSelectedText,
	isNotSelectedText,
}: ToggleProps) => {
	// Attributes
	const [isSelected, setIsSelected] = useState(defaultValue);

	// Handlers
	function handleToggle() {
		handleClick(!isSelected);
		setIsSelected((previous: any) => !previous);
	}

	function renderClassName() {
		if (isSelected) {
			return "toggle-ball-select-container";
		} else {
			return "toggle-ball-unselect-container";
		}
	}

	return (
		<div className="toggle-container">
			<div className={renderClassName()} onClick={handleToggle}>
				{isSelected ? (
					<>
						<span className="toggle-ball-selected"></span>
					</>
				) : (
					<>
						<span className="toggle-ball-unselected"></span>
					</>
				)}
			</div>
			{isSelectedText !== undefined && isNotSelectedText !== undefined && (
				<span
					className={`toggle-${
						isSelected ? "selected" : "unselected"
					}-text`}
				>
					<Translation>
						{isSelected ? isSelectedText : isNotSelectedText}
					</Translation>
				</span>
			)}
		</div>
	);
};

export default Toggle;
