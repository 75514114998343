/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */

export type NotificationDestinationType = typeof NotificationDestinationType[keyof typeof NotificationDestinationType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NotificationDestinationType = {
  USER: 'USER',
  COMPANY: 'COMPANY',
} as const;
