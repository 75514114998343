import * as Yup from 'yup';

interface ValidationSchemaProps {
    translate: (key: string) => string
}


export const saveZoneValidationSchema = ({translate}: ValidationSchemaProps) => {
  return Yup.object({
    name: Yup.string().required(
      translate("zone-missing-name"),
    ),
    companyIds: Yup.array().min(1, translate("zone-missing-companies"))
  });
};
