import React, { useState } from "react";
import { PopupContext } from "./context";
import Modal from "react-modal";
import Popup from "../popup";
import { customStyles } from "../popup/customStyle";
import PopupButton from "../../../../components/popup-button";
import { ReactComponent as WarningIcon } from "./assets/warning.svg";
import { useHistory } from "react-router-dom";
import { EnumPopupIndex } from "./props";

const PopupContextProvider = ({ children }: any) => {
  // Attributes
  const history = useHistory();
  const popups = [
    <Popup
      title='Not so fast!'
      subTitle='Please fill in all the mandatory fields to proceed.'
      secondTitle='You forgot mandatory fields.'
      icon={<WarningIcon style={{ width: 66, height: 69 }} />}
      buttons={[
        <PopupButton
          backgroundColor='yellow'
          handleClick={() => setIsOpen(false)}
          title='Ok, got it'
        />,
      ]}
    />,
    <Popup
      title='You are about to leave this screen!'
      subTitle='By leaving this screen, all the inserted data will be lost. Are you sure you want to leave?'
      icon={<WarningIcon style={{ width: 66, height: 69 }} />}
      buttons={[
        <PopupButton
          backgroundColor='grey'
          handleClick={() => setIsOpen(false)}
          title='Cancel'
        />,
        <PopupButton
          backgroundColor='yellow'
          handleClick={() => {
            history.goBack();
            setIsOpen(false);
          }}
          title='Ok, got it'
        />,
      ]}
    />,
    <Popup
      title='Not so fast!'
      subTitle='The VAT number must include 10 numbers. For example: BE 0563 432 233'
      secondTitle='Your VAT number is invalid.'
      icon={<WarningIcon style={{ width: 66, height: 69 }} />}
      buttons={[
        <PopupButton
          backgroundColor='yellow'
          handleClick={() => setIsOpen(false)}
          title='Ok, got it'
        />,
      ]}
    />,
    <Popup
      title='Not so fast!'
      subTitle='The selected time has already passed.'
      secondTitle='Wrong time selected.'
      icon={<WarningIcon style={{ width: 66, height: 69 }} />}
      buttons={[
        <PopupButton
          backgroundColor='yellow'
          handleClick={() => setIsOpen(false)}
          title='Ok, got it'
        />,
      ]}
    />,
  ];
  const [isOpen, setIsOpen] = useState(false);
  const [index, setIndex] = useState(0);

  // Handlers
  function showPopup(index: EnumPopupIndex) {
    setIndex(index);
    setIsOpen(true);
  }

  return (
    <PopupContext.Provider value={{ showPopup }}>
      {children}
      {isOpen && (
        <Modal style={customStyles} isOpen={isOpen}>
          {popups[index]}
        </Modal>
      )}
    </PopupContext.Provider>
  );
};

export default PopupContextProvider;
