import Axios from 'axios';
import { ProductDTO } from '../dtos/productDTO'
import ProductMockService from './mocks/ProductMockService'
import Service from './Service';


const products: ProductDTO[] = [];


async function get(restaurantId: string, productId?: string): Promise<any> {
    if (productId) {
        return Service.execute((Axios.get(`${Service.API_URL}/v1/api/restaurants/${restaurantId}/products/${productId}`, Service.config())));
    }
    return Service.execute((Axios.get(`${Service.API_URL}/v1/api/restaurants/${restaurantId}/products`, Service.config())));
}

async function put(restaurantId: string, product: ProductDTO) {
    product.unitPrice = parseFloat(product.unitPrice as any);
    return Service.execute((Axios.put(`${Service.API_URL}/v1/api/restaurants/${restaurantId}/products`, product, Service.config())))
}

async function post(restaurantId: string, product: ProductDTO) {
    product.unitPrice = parseFloat(product.unitPrice as any);

    return Service.execute((Axios.post(`${Service.API_URL}/v1/api/restaurants/${restaurantId}/products`, product, Service.config())))
}

async function deleteProduct(restaurantId: string, productId: string) {
    return Service.execute((Axios.delete(`${Service.API_URL}/v1/api/restaurants/${restaurantId}/products/${productId}`, Service.config())));
}

let exported = { get, post, put, deleteProduct };

// if(process.env.REACT_APP_MOCKED === "true")
// {
//     exported = ProductMockService;
// }

export default exported;