import React from "react";
import InputWithLabel from "../../../../../../../../components/input-with-label";
import TextArea from "../../../../../../../../components/text-area";
import BackgroundDetailsForm from "../../../../../../components/background-details-form";
import GroupInputDetails from "../../../../../../components/group-input-details";
import InputGroup from "../../../../../../components/input-group";
import { actions } from "../../reducer";
import { COMPANY_FIELDS } from "../commercial-information/props";
import { DeliveryInformationProps } from "./props";

const Delivery = ({ company, dispatch }: DeliveryInformationProps) => {
  // Attributes

  return (
    <BackgroundDetailsForm>
      <GroupInputDetails title="Delivery">
        <InputGroup size="medium">
          <InputWithLabel label="DELIVERY COMMENTS">
            <TextArea
              size="large"
              placeholder="ex: go to the reception desk and wait."
              onChange={(event: any) =>
                dispatch({
                  type: actions.companyChanged,
                  target: COMPANY_FIELDS.DELIVERY_COMMENTS,
                  payload: event.target.value,
                })
              }
              value={company.deliveryComments}
              required={true}
            />
          </InputWithLabel>
        </InputGroup>
      </GroupInputDetails>
    </BackgroundDetailsForm>
  );
};

export default Delivery;
