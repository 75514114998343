import React from 'react';
import { InputDateProps } from './props';
import './style.css';

const InputDate = ({ value, onChange, disabled, ...rest }: InputDateProps) => {
	return (
		<input
			disabled={disabled}
			className={`input-date`}
			type='date'
			value={value}
			onChange={onChange}
			{...rest}
		/>
	);
};

export default InputDate;
