import './style.css';
import React, { useState, useEffect } from 'react';

import { motion } from 'framer-motion';
import Service from '../../services/Service';
import Translation from '../translation';

const Alert = (props: any) => {

    // Attributes
    const { error, success } = props;
    console.log({error});
    console.log({success});
    
    return (
        <motion.div transition={{ duration: 0.4 }} initial={{ marginBottom: -50, opacity: 0 }} animate={{ marginBottom: 20, opacity: 1 }} className={error ? "alert-container" : "success-container"}>
            <Translation>
                {error ? error : success}
            </Translation>
        </motion.div>
    );
}

let previousLength = 0;
let previousSuccessLength = 0;


const AlertContainer = () => {

    const [error, setError] = useState<string>((''));
    const [success, setSuccess] = useState<string>('');

    // Effects
    useEffect(() => {
        setInterval(() => {
            if (Service.errors.length > previousLength) {
                previousLength = Service.errors.length;
                setError(Service.errors[Service.errors.length - 1]);
            }
            if (Service.success.length > previousSuccessLength) {
                previousSuccessLength = Service.success.length;
                setSuccess(Service.success[Service.success.length - 1]);
            }
        }, 500);
    }, []);

    useEffect(() => {
        if (error) {
            setTimeout(() => setError(""), 2000);
        }
        if (success) {
            setTimeout(() => setSuccess(""), 2000);
        }
    }, [error, success]);

    return (
        <div style={{ position: 'fixed', right: 20, bottom: 0 }}>
            {(error || success) && <Alert error={error} success={success} />}
        </div>
    );
}

export default AlertContainer;