/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */

export type PromoCodeStatus = typeof PromoCodeStatus[keyof typeof PromoCodeStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PromoCodeStatus = {
  PENDING: 'PENDING',
  EXPIRED: 'EXPIRED',
  ACTIVE: 'ACTIVE',
} as const;
