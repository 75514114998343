import { useTranslation } from '../../../../../../../../../../components/translation-context/TranslationContext';
import React from 'react';
import './styles.css';
import { NotificationDeliveryType } from '../../../../../../../../../../dtos/go-api';
import { NotificationByOrdersMediaProps } from './props';
const NotificationByOrdersMedia = ({
  currentDeliveryType,
  onChange,
}: NotificationByOrdersMediaProps) => {
  // Attributes
  const { translate } = useTranslation();
  return (
    <div className='notification-dialog-body-media-container'>
      <div className='notification-dialog-body-media-title'>
        {translate('send-by').toUpperCase()}
      </div>
      <div className='notification-dialog-body-media-input-group'>
        {Object.values(NotificationDeliveryType).map((key) => {
          return (
            <div className='notification-dialog-body-media-input-container'>
              <input
                type='radio'
                checked={currentDeliveryType === key}
                id={key}
                style={{ marginRight: 9, cursor: 'pointer' }}
                onChange={() => onChange(key)}
              />
              <label htmlFor={key}>{translate(key)}</label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default NotificationByOrdersMedia;
