import { AddressDTO } from "./addressDTO";

export interface CompanyDTO {
    id: string,
    name: string,
    vatNumber: string,
    deliveryComments: string,
    address: AddressDTO,
    addressId: string,
    logo: string,
    mobileLogo: string,
    orderTimingType: OrderTimingType,
    code: string,
}

export enum OrderTimingType {
    Unknown = -1,
    Noon,
    Evening,
    Both
}