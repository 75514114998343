/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation
} from 'react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  CompanyPagingResDTO,
  GetAllAvailableCompaniesParams,
  ZonePagingDTO,
  GetAllZonesParams,
  ZoneResDTO,
  ZoneReqCreateDTO,
  ZoneReqUpdateDTO,
  GetAllCompaniesByZoneIdParams
} from '../../../dtos/go-api'
import { customInstance } from '.././config';

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


export const getAllAvailableCompanies = (
    params?: GetAllAvailableCompaniesParams,
 signal?: AbortSignal
) => {
      return customInstance<CompanyPagingResDTO>(
      {url: `/v1/api/zones/available-companies`, method: 'get',
        params, signal
    },
      );
    }
  

export const getGetAllAvailableCompaniesQueryKey = (params?: GetAllAvailableCompaniesParams,) => [`/v1/api/zones/available-companies`, ...(params ? [params]: [])];

    
export type GetAllAvailableCompaniesQueryResult = NonNullable<Awaited<ReturnType<typeof getAllAvailableCompanies>>>
export type GetAllAvailableCompaniesQueryError = unknown

export const useGetAllAvailableCompanies = <TData = Awaited<ReturnType<typeof getAllAvailableCompanies>>, TError = unknown>(
 params?: GetAllAvailableCompaniesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAllAvailableCompanies>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllAvailableCompaniesQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllAvailableCompanies>>> = ({ signal }) => getAllAvailableCompanies(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getAllAvailableCompanies>>, TError, TData>({ queryKey, queryFn, ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export const getAllZones = (
    params?: GetAllZonesParams,
 signal?: AbortSignal
) => {
      return customInstance<ZonePagingDTO>(
      {url: `/v1/api/zones`, method: 'get',
        params, signal
    },
      );
    }
  

export const getGetAllZonesQueryKey = (params?: GetAllZonesParams,) => [`/v1/api/zones`, ...(params ? [params]: [])];

    
export type GetAllZonesQueryResult = NonNullable<Awaited<ReturnType<typeof getAllZones>>>
export type GetAllZonesQueryError = unknown

export const useGetAllZones = <TData = Awaited<ReturnType<typeof getAllZones>>, TError = unknown>(
 params?: GetAllZonesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAllZones>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllZonesQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllZones>>> = ({ signal }) => getAllZones(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getAllZones>>, TError, TData>({ queryKey, queryFn, ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export const createZone = (
    zoneReqCreateDTO: ZoneReqCreateDTO,
 ) => {
      return customInstance<ZoneResDTO>(
      {url: `/v1/api/zones`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: zoneReqCreateDTO
    },
      );
    }
  


    export type CreateZoneMutationResult = NonNullable<Awaited<ReturnType<typeof createZone>>>
    export type CreateZoneMutationBody = ZoneReqCreateDTO
    export type CreateZoneMutationError = unknown

    export const useCreateZone = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createZone>>, TError,{data: ZoneReqCreateDTO}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createZone>>, {data: ZoneReqCreateDTO}> = (props) => {
          const {data} = props ?? {};

          return  createZone(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof createZone>>, TError, {data: ZoneReqCreateDTO}, TContext>(mutationFn, mutationOptions);
    }
    export const getZoneById = (
    zoneId: string,
 signal?: AbortSignal
) => {
      return customInstance<ZoneResDTO>(
      {url: `/v1/api/zones/${zoneId}`, method: 'get', signal
    },
      );
    }
  

export const getGetZoneByIdQueryKey = (zoneId: string,) => [`/v1/api/zones/${zoneId}`];

    
export type GetZoneByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getZoneById>>>
export type GetZoneByIdQueryError = unknown

export const useGetZoneById = <TData = Awaited<ReturnType<typeof getZoneById>>, TError = unknown>(
 zoneId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getZoneById>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetZoneByIdQueryKey(zoneId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getZoneById>>> = ({ signal }) => getZoneById(zoneId, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getZoneById>>, TError, TData>({ queryKey, queryFn, enabled: !!(zoneId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export const updateZoneById = (
    zoneId: string,
    zoneReqUpdateDTO: ZoneReqUpdateDTO,
 ) => {
      return customInstance<ZoneResDTO>(
      {url: `/v1/api/zones/${zoneId}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: zoneReqUpdateDTO
    },
      );
    }
  


    export type UpdateZoneByIdMutationResult = NonNullable<Awaited<ReturnType<typeof updateZoneById>>>
    export type UpdateZoneByIdMutationBody = ZoneReqUpdateDTO
    export type UpdateZoneByIdMutationError = unknown

    export const useUpdateZoneById = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateZoneById>>, TError,{zoneId: string;data: ZoneReqUpdateDTO}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateZoneById>>, {zoneId: string;data: ZoneReqUpdateDTO}> = (props) => {
          const {zoneId,data} = props ?? {};

          return  updateZoneById(zoneId,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof updateZoneById>>, TError, {zoneId: string;data: ZoneReqUpdateDTO}, TContext>(mutationFn, mutationOptions);
    }
    export const deleteZoneById = (
    zoneId: string,
 ) => {
      return customInstance<ZoneResDTO>(
      {url: `/v1/api/zones/${zoneId}`, method: 'delete'
    },
      );
    }
  


    export type DeleteZoneByIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteZoneById>>>
    
    export type DeleteZoneByIdMutationError = unknown

    export const useDeleteZoneById = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteZoneById>>, TError,{zoneId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteZoneById>>, {zoneId: string}> = (props) => {
          const {zoneId} = props ?? {};

          return  deleteZoneById(zoneId,)
        }

        

      return useMutation<Awaited<ReturnType<typeof deleteZoneById>>, TError, {zoneId: string}, TContext>(mutationFn, mutationOptions);
    }
    export const getAllCompaniesByZoneId = (
    zoneId: string,
    params?: GetAllCompaniesByZoneIdParams,
 signal?: AbortSignal
) => {
      return customInstance<CompanyPagingResDTO>(
      {url: `/v1/api/zones/${zoneId}/companies`, method: 'get',
        params, signal
    },
      );
    }
  

export const getGetAllCompaniesByZoneIdQueryKey = (zoneId: string,
    params?: GetAllCompaniesByZoneIdParams,) => [`/v1/api/zones/${zoneId}/companies`, ...(params ? [params]: [])];

    
export type GetAllCompaniesByZoneIdQueryResult = NonNullable<Awaited<ReturnType<typeof getAllCompaniesByZoneId>>>
export type GetAllCompaniesByZoneIdQueryError = unknown

export const useGetAllCompaniesByZoneId = <TData = Awaited<ReturnType<typeof getAllCompaniesByZoneId>>, TError = unknown>(
 zoneId: string,
    params?: GetAllCompaniesByZoneIdParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAllCompaniesByZoneId>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllCompaniesByZoneIdQueryKey(zoneId,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllCompaniesByZoneId>>> = ({ signal }) => getAllCompaniesByZoneId(zoneId,params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getAllCompaniesByZoneId>>, TError, TData>({ queryKey, queryFn, enabled: !!(zoneId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

