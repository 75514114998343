import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import React, { useEffect, useReducer } from "react";
import { useHistory, useParams } from "react-router-dom";
import BusyIndicator from "../../../../../../components/busy-indicator";
import Header from "../../../../../../components/header";
import SaveButton from "../../../../../../components/header/components/save-button";
import { SAVE_BUTTON_TYPE } from "../../../../../../components/header/components/save-button/props";
import { useTranslation } from "../../../../../../components/translation-context/TranslationContext";
import {
  CreateNotificationDTO,
  NotificationCompany,
  NotificationDestinationType,
  NotificationFrequency,
  NotificationType,
  UpdateNotificationDTO,
} from "../../../../../../dtos/go-api";
import {
  useCreateNotification,
  useDeleteNotification,
  useGetNotificationById,
  useUpdateNotificationById,
} from "../../../../../../services/go-api/notifications/notifications";

import Service from "../../../../../../services/Service";
import Form from "../../../../components/form";
import MenuContainer from "../../../../components/menu-container";
import PageContainer from "../../../../components/page-container";
import { usePopupContext } from "../../../../components/popup-context/context";
import { EnumPopupIndex } from "../../../../components/popup-context/props";
import NotificationsCompaniesSection from "./components/companies-section";
import NotifTimingSection from "./components/notif-timing-section";
import NotificationTitleSection from "./components/title-section";
import { initialNotification, reducer } from "./reducer";
import "./style.css";

const NotificationsDetail = () => {
  // 	Attributes
  const { showPopup } = usePopupContext();
  const { translate } = useTranslation();
  const history = useHistory();
  const { notificationId }: any = useParams();
  const [notification, dispatch] = useReducer(reducer, initialNotification);

  const createNotificationQuery = useCreateNotification();
  const createNotification = createNotificationQuery.mutateAsync;
  const createNotificationIsSuccess = createNotificationQuery.isSuccess;

  const updateNotificationQuery = useUpdateNotificationById();
  const updateNotification = updateNotificationQuery.mutateAsync;

  const notificationQuery = useGetNotificationById(notificationId, {
    query: { enabled: !!notificationId },
  });
  const notificationData = notificationQuery && notificationQuery.data;

  const deleteNotificationQuery = useDeleteNotification();
  const deleteNotification = deleteNotificationQuery.mutateAsync;

  // Effects
  useEffect(() => {
    if (createNotificationIsSuccess) {
      history.goBack();
    }
  }, [createNotificationIsSuccess, history]);

  useEffect(() => {
    // Set starting info once notificationId loads
    if (notificationId) {
      dispatch({
        type: "serverChanged",
        target: "self",
        payload: notificationData,
      });
      dispatch({
        type: "stateChanged",
        target: "frequency",
        payload: notificationData?.frequency,
      });
      dispatch({
        type: "stateChanged",
        target: "frequencyDays",
        payload: notificationData?.frequencyDays,
      });
      var hours = notificationData?.publicationTime.split(":")[0];
      var minutes = notificationData?.publicationTime.split(":")[1];
      if (Number(hours) < 10 && hours?.length === 1) {
        hours = "0" + hours;
      }
      if (Number(minutes) < 10 && minutes?.length === 1) {
        minutes = "0" + minutes;
      }
      dispatch({
        type: "stateChanged",
        target: "publicationTime",
        payload: hours + ":" + minutes,
      });
    }
  }, [notificationData, notificationId]);

  // Functions
  async function handleNotificationSave() {
    if (
      notification.title === "" ||
      notification.title === undefined ||
      notification.description === "" ||
      notification.description === undefined
    ) {
      alert(translate("Please fill in all fields"));
      return;
    }

    // extract companies ids from notification companies
    const companiesIds = notification.companies.map(
      (company: NotificationCompany) => company.id
    );

    notificationId
      ? await handleUpdateNotification(companiesIds)
      : await handleCreateNotification(companiesIds);
  }

  async function handleUpdateNotification(companiesIds: any) {
    const timeNow = format(new Date(), "HH:mm");
    const dateNow = format(new Date(), "yyyy-MM-dd");
    const notifDay = format(
      new Date(notification.publicationDate),
      "yyyy-MM-dd"
    );
    if (
      notification.frequency === NotificationFrequency.UNIQUE_USE &&
      notification.publicationTime < timeNow &&
      notifDay === dateNow
    ) {
      showPopup(EnumPopupIndex.WRONGTIME);
      return;
    }
    let publicationDate = notification.publicationDate;
    let publicationTime = notification.publicationTime;
    if (notification.type === NotificationType.SENT) {
      var now = new Date();
      var tzoffset = now.getTimezoneOffset() * 60000; //offset in milliseconds
      var localISOTime = new Date(Date.now() - tzoffset).toISOString();
      var minutes =
        now.getMinutes() < 10 ? "0" + now.getMinutes() : now.getMinutes();
      var hours = now.getHours() < 10 ? "0" + now.getHours() : now.getHours();
      publicationDate = localISOTime;
      publicationTime = `${hours}:${minutes}`;
    }
    // update notificationUpdateDTO
    const notificationUpdateDTO: UpdateNotificationDTO = {
      title: notification.title,
      description: notification.description,
      publicationDate: publicationDate,
      publicationTime: publicationTime,
      frequency:
        notification.type === NotificationType.SENT ||
          notification.type === NotificationType.UPCOMING
          ? NotificationFrequency.UNIQUE_USE
          : notification.frequency,
      frequencyDays: notification.frequencyDays,
      companyIds: companiesIds,
      deliveryType: notification.deliveryType,
    };

    await updateNotification(
      {
        notificationId: notificationId as string,
        data: notificationUpdateDTO,
      },
      {
        onSuccess: () => {
          history.replace(`/dashboard/notifications?type=${notification.type}`);
          Service.success.push(translate("Notification updated !"));
        },
      }
    );
  }

  async function handleCreateNotification(companiesIds: any) {


    if (notification.publicationTime < Date.now() && notification.type !== NotificationType.SENT && notification.type !== NotificationType.RECURRING) {
      showPopup(EnumPopupIndex.WRONGTIME);
      return;
    }
    let publicationDate = notification.publicationDate;
    let publicationTime = notification.publicationTime;
    if (notification.type === NotificationType.SENT) {
      var now = new Date();
      var tzoffset = now.getTimezoneOffset() * 60000; //offset in milliseconds
      var localISOTime = new Date(Date.now() - tzoffset).toISOString();
      var minutes =
        now.getMinutes() < 10 ? "0" + now.getMinutes() : now.getMinutes();
      var hours = now.getHours() < 10 ? "0" + now.getHours() : now.getHours();
      publicationDate = localISOTime;
      publicationTime = `${hours}:${minutes}`;
    }
    // create new notificationCreationDTO
    const notificationCreationDTO: CreateNotificationDTO = {
      title: notification.title,
      description: notification.description,
      publicationDate: publicationDate,
      publicationTime: publicationTime,
      frequency:
        notification.type === NotificationType.SENT ||
          notification.type === NotificationType.UPCOMING
          ? NotificationFrequency.UNIQUE_USE
          : notification.frequency,
      frequencyDays: notification.frequencyDays,
      companyIds: companiesIds,
      deliveryType: notification.deliveryType,
    };

    await createNotification(
      {
        data: notificationCreationDTO,
      },
      {
        onSuccess: () => {
          history.replace(`/dashboard/notifications?type=${notification.type}`);
          Service.success.push(translate("Notification created !"));
        },
      }
    );
  }

  async function handleDeleteNotification() {
    await deleteNotification(
      { notificationId },
      {
        onSuccess: () => {
          history.goBack();
          Service.success.push(translate("Notification created !"));
        },
      }
    );
  }

  // Render
  return (
    <BusyIndicator
      queries={[
        notificationQuery,
        createNotificationQuery,
        updateNotificationQuery,
        deleteNotificationQuery,
      ]}
    >
      <PageContainer>
        <Header
          title={
            notificationData
              ? translate("Modifiy notification")
              : translate("Add a new notification")
          }
          hasBackButton={true}
          saveButton={
            <SaveButton
              handleClick={handleNotificationSave}
              type={
                notificationId
                  ? SAVE_BUTTON_TYPE.SAVE
                  : SAVE_BUTTON_TYPE.SAVE_AND_ADD
              }
              handleDelete={() => handleDeleteNotification()}
            />
          }
        />
        <MenuContainer>
          <Form>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              {/* LEFT SECTION */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "603px",
                }}
              >
                <NotificationTitleSection
                  notification={notification}
                  dispatch={dispatch}
                />

                {notification.destinationType !==
                  NotificationDestinationType.USER && (
                    <NotificationsCompaniesSection
                      notification={notification}
                      dispatch={dispatch}
                    />
                  )}
              </div>

              <div style={{ width: "603px" }}>
                {/* TIMING */}
                <NotifTimingSection
                  destinationType={notification.destinationType}
                  notification={notification}
                  dispatch={dispatch}
                  notificationData={notificationData}
                  isNewNotif={!notificationId}
                />
              </div>

              {/* RIGHT SECTION */}
            </div>
          </Form>
        </MenuContainer>
      </PageContainer>
    </BusyIndicator>
  );
};

export default NotificationsDetail;
