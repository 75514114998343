/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation
} from 'react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  NotificationTemplatePagingResDTO,
  GetNotificationTemplatesParams,
  NotificationTemplateDTO
} from '../../../dtos/go-api'
import { customInstance } from '.././config';

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


export const getNotificationTemplates = (
    params?: GetNotificationTemplatesParams,
 signal?: AbortSignal
) => {
      return customInstance<NotificationTemplatePagingResDTO>(
      {url: `/v1/api/notifications/templates`, method: 'get',
        params, signal
    },
      );
    }
  

export const getGetNotificationTemplatesQueryKey = (params?: GetNotificationTemplatesParams,) => [`/v1/api/notifications/templates`, ...(params ? [params]: [])];

    
export type GetNotificationTemplatesQueryResult = NonNullable<Awaited<ReturnType<typeof getNotificationTemplates>>>
export type GetNotificationTemplatesQueryError = unknown

export const useGetNotificationTemplates = <TData = Awaited<ReturnType<typeof getNotificationTemplates>>, TError = unknown>(
 params?: GetNotificationTemplatesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getNotificationTemplates>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetNotificationTemplatesQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getNotificationTemplates>>> = ({ signal }) => getNotificationTemplates(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getNotificationTemplates>>, TError, TData>({ queryKey, queryFn, ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export const createNotificationTemplate = (
    notificationTemplateDTO: NotificationTemplateDTO,
 ) => {
      return customInstance<NotificationTemplateDTO>(
      {url: `/v1/api/notifications/templates`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: notificationTemplateDTO
    },
      );
    }
  


    export type CreateNotificationTemplateMutationResult = NonNullable<Awaited<ReturnType<typeof createNotificationTemplate>>>
    export type CreateNotificationTemplateMutationBody = NotificationTemplateDTO
    export type CreateNotificationTemplateMutationError = unknown

    export const useCreateNotificationTemplate = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createNotificationTemplate>>, TError,{data: NotificationTemplateDTO}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createNotificationTemplate>>, {data: NotificationTemplateDTO}> = (props) => {
          const {data} = props ?? {};

          return  createNotificationTemplate(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof createNotificationTemplate>>, TError, {data: NotificationTemplateDTO}, TContext>(mutationFn, mutationOptions);
    }
    export const getNotificationTemplateById = (
    templateId: string,
 signal?: AbortSignal
) => {
      return customInstance<NotificationTemplateDTO>(
      {url: `/v1/api/notifications/templates/${templateId}`, method: 'get', signal
    },
      );
    }
  

export const getGetNotificationTemplateByIdQueryKey = (templateId: string,) => [`/v1/api/notifications/templates/${templateId}`];

    
export type GetNotificationTemplateByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getNotificationTemplateById>>>
export type GetNotificationTemplateByIdQueryError = unknown

export const useGetNotificationTemplateById = <TData = Awaited<ReturnType<typeof getNotificationTemplateById>>, TError = unknown>(
 templateId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getNotificationTemplateById>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetNotificationTemplateByIdQueryKey(templateId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getNotificationTemplateById>>> = ({ signal }) => getNotificationTemplateById(templateId, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getNotificationTemplateById>>, TError, TData>({ queryKey, queryFn, enabled: !!(templateId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export const updateNotificationTemplateById = (
    templateId: string,
    notificationTemplateDTO: NotificationTemplateDTO,
 ) => {
      return customInstance<NotificationTemplateDTO>(
      {url: `/v1/api/notifications/templates/${templateId}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: notificationTemplateDTO
    },
      );
    }
  


    export type UpdateNotificationTemplateByIdMutationResult = NonNullable<Awaited<ReturnType<typeof updateNotificationTemplateById>>>
    export type UpdateNotificationTemplateByIdMutationBody = NotificationTemplateDTO
    export type UpdateNotificationTemplateByIdMutationError = unknown

    export const useUpdateNotificationTemplateById = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateNotificationTemplateById>>, TError,{templateId: string;data: NotificationTemplateDTO}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateNotificationTemplateById>>, {templateId: string;data: NotificationTemplateDTO}> = (props) => {
          const {templateId,data} = props ?? {};

          return  updateNotificationTemplateById(templateId,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof updateNotificationTemplateById>>, TError, {templateId: string;data: NotificationTemplateDTO}, TContext>(mutationFn, mutationOptions);
    }
    export const deleteNotificationTemplateById = (
    templateId: string,
 ) => {
      return customInstance<NotificationTemplateDTO>(
      {url: `/v1/api/notifications/templates/${templateId}`, method: 'delete'
    },
      );
    }
  


    export type DeleteNotificationTemplateByIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteNotificationTemplateById>>>
    
    export type DeleteNotificationTemplateByIdMutationError = unknown

    export const useDeleteNotificationTemplateById = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteNotificationTemplateById>>, TError,{templateId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteNotificationTemplateById>>, {templateId: string}> = (props) => {
          const {templateId} = props ?? {};

          return  deleteNotificationTemplateById(templateId,)
        }

        

      return useMutation<Awaited<ReturnType<typeof deleteNotificationTemplateById>>, TError, {templateId: string}, TContext>(mutationFn, mutationOptions);
    }
    