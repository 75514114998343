import React from 'react';
import { ZoneCompanyDragCardProps } from './props';
import './styles.css';
import { ReactComponent as DeleteIcon } from './assets/delete.svg';
import { ReactComponent as DragIcon } from './assets/drag.svg';
import { capitalize } from 'lodash';
const ZoneCompanyDragCard = ({
  company,
  handleUnSelect,
  index,
}: ZoneCompanyDragCardProps) => {
  return (
    <div className='zone-company-drag-card-container'>
      <div className='zone-company-drag-card-body'>
        <div className='zone-company-drag-body-name'>
          {capitalize(company.name?.toLowerCase())}
        </div>
        <div className='zone-company-drag-body-end'>
          <div className='zone-company-drag-card-index'>{index + 1}</div>
          <DragIcon style={{ cursor: 'pointer' }} />
        </div>
      </div>

      <DeleteIcon
        onClick={() => handleUnSelect(company.id ?? '')}
        style={{ cursor: 'pointer', width: 33, height: 33 }}
      />
    </div>
  );
};

export default ZoneCompanyDragCard;
