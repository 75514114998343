import React from 'react';
import './style.css';
import { ReactComponent as ArrowRightIcon } from './assets/arrow-right.svg';
import { CompanyCardProps } from './props';
import { useHistory } from 'react-router-dom';
import ActionButton from '../../../../../../../../components/action-button';

const CompanyCard = ({ name, id, index }: CompanyCardProps) => {
  // Attributes
  const history = useHistory();

  // Handlers
  function renderActionButtons() {
    const routes = ['restaurants', 'employees', 'details', 'client'];
    return routes.map((route, index) => {
      if (route === 'client') {
        return (
          <ActionButton
            name={route}
            handleAction={() => history.push(`https://app.foodiz.be/${route}`)}
          />
        );
      }

      if (index !== routes.length - 1) {
        return (
          <>
            <ActionButton
              name={route}
              handleAction={() =>
                history.push(`/dashboard/companies/${id}/${route}`)
              }
            />
            <div style={{ marginRight: 16 }} />
          </>
        );
      }
      return (
        <ActionButton
          name={route}
          handleAction={() =>
            history.push(`/dashboard/companies/${id}/${route}`)
          }
        />
      );
    });
  }

  return (
    <div className='company-card-container'>
      <div className='company-card-header'>
        <div className='company-card-header-name'>{name}</div>
        <ArrowRightIcon
          style={{ cursor: 'pointer' }}
          onClick={() => history.push(`/dashboard/companies/${id}/details`)}
        />
      </div>
      <div className='company-card-actions-container'>
        {renderActionButtons()}
      </div>
    </div>
  );
};

export default CompanyCard;
