/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */

export type OrderTimingType = typeof OrderTimingType[keyof typeof OrderTimingType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderTimingType = {
  NOON: 'NOON',
  EVENING: 'EVENING',
  BOTH: 'BOTH',
  UNKNOWN: 'UNKNOWN',
} as const;
