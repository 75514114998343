import React from "react";

const ChevronRight = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path d="M12.7102 12.0001L9.17018 15.5401C8.98393 15.7274 8.87939 15.9809 8.87939 16.2451C8.87939 16.5092 8.98393 16.7627 9.17018 16.9501C9.26314 17.0438 9.37374 17.1182 9.4956 17.1689C9.61746 17.2197 9.74817 17.2458 9.88018 17.2458C10.0122 17.2458 10.1429 17.2197 10.2648 17.1689C10.3866 17.1182 10.4972 17.0438 10.5902 16.9501L14.8302 12.7101C14.9239 12.6171 14.9983 12.5065 15.0491 12.3846C15.0998 12.2628 15.126 12.1321 15.126 12.0001C15.126 11.868 15.0998 11.7373 15.0491 11.6155C14.9983 11.4936 14.9239 11.383 14.8302 11.2901L10.5902 7.00005C10.4967 6.90737 10.3859 6.83404 10.2641 6.78428C10.1422 6.73452 10.0118 6.70929 9.88018 6.71005C9.74857 6.70929 9.61811 6.73452 9.49627 6.78428C9.37444 6.83404 9.26362 6.90737 9.17018 7.00005C8.98393 7.18741 8.87939 7.44087 8.87939 7.70505C8.87939 7.96924 8.98393 8.22269 9.17018 8.41005L12.7102 12.0001Z" />
		</svg>
	);
};
export default ChevronRight;
