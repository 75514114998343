import Axios from "axios";
import { CompanyDTO } from "../dtos/companyDTO";
import { CompanyResDTO } from "../dtos/go-api";
import CompanyMockService from "./mocks/CompanyMockService";
import Service from "./Service";

async function get(companyId?: string) {
    if (companyId) {
        return Service.execute((Axios.get(`${Service.API_URL}/v1/api/companies/${companyId}`, Service.config())));
    }
    return Service.execute((Axios.get(`${Service.API_URL}/v1/api/companies`, Service.config())));
}

async function post(company: CompanyResDTO) {
    return Service.execute((Axios.post(`${Service.API_URL}/v1/api/companies`, company, Service.config())));
}

async function put(company: CompanyResDTO) {
    return Service.execute((Axios.put(`${Service.API_URL}/v1/api/companies`, company, Service.config())));
}

async function deleteCompany(companyId: string) {
    return Service.execute((Axios.delete(`${Service.API_URL}/v1/api/companies/${companyId}`, Service.config())));
}

let exported = { get, post, put, deleteCompany };

if (process.env.REACT_APP_MOCKED === "true") {
    exported = CompanyMockService;
}

export default exported;