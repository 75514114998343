/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */
import {
  useMutation
} from 'react-query'
import type {
  UseMutationOptions,
  MutationFunction
} from 'react-query'
import type {
  FileResDTO,
  FileReqCreateDTO
} from '../../../dtos/go-api'
import { customInstance } from '.././config';

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


export const createFile = (
    fileReqCreateDTO: FileReqCreateDTO,
 ) => {
      return customInstance<FileResDTO>(
      {url: `/v1/api/files`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: fileReqCreateDTO
    },
      );
    }
  


    export type CreateFileMutationResult = NonNullable<Awaited<ReturnType<typeof createFile>>>
    export type CreateFileMutationBody = FileReqCreateDTO
    export type CreateFileMutationError = unknown

    export const useCreateFile = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createFile>>, TError,{data: FileReqCreateDTO}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createFile>>, {data: FileReqCreateDTO}> = (props) => {
          const {data} = props ?? {};

          return  createFile(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof createFile>>, TError, {data: FileReqCreateDTO}, TContext>(mutationFn, mutationOptions);
    }
    