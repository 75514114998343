import React from 'react';
import BusyIndicator from '../../../../../../../../../../components/busy-indicator';
import Translation from '../../../../../../../../../../components/translation';
import './style.css';

const PurchaseCard = () => {
    //Attributes
    //const { companyId, employeeId }:any = useParams();
    // const query = useQuery(`orders-${employeeId}`, refreshOrders)

    //Functions
    // async function refreshOrders() {
    //     var response = await OrderService.get(companyId, employeeId);
    //     return response.data;
    // }    

    return (
        <BusyIndicator>
            <div className='user-purchase-container'>
                <div className='user-purchase-title'><Translation>Purchase history</Translation></div>
                <table className='user-purchase-table-container'>
                    <thead>
                        <tr>
                            <th className='user-purchase-table-header'>Date</th>
                            <th className='user-purchase-table-header'><Translation>Amount</Translation></th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* {query.data.map((elt:any) => {
                            <tr className='user-purchase-row'>
                                <td className='user-purchase-data'>{elt.deliveryDate}</td>
                                <td className='user-purchase-data'>12€</td>
                                
                                //un order ne contient pas le prix donc faut aller le chercher dn basketProduct
                            </tr>    
                        })} */}
                        <tr className='user-purchase-row'>
                            <td className='user-purchase-data'>2020-20-17</td>
                            <td className='user-purchase-data'>12€</td>
                        </tr>
                        <tr className='user-purchase-row'>
                            <td className='user-purchase-data'>2020-20-17</td>
                            <td className='user-purchase-data'>12€</td>
                        </tr>
                        <tr className='user-purchase-row'>
                            <td className='user-purchase-data'>2020-20-17</td>
                            <td className='user-purchase-data'>12€</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </BusyIndicator>
    )
}

export default PurchaseCard;