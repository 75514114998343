import React, { useEffect, useState } from 'react';

const useSegment = (data: any[], handleSegment: any, filter: string) => {


    // Attributes
    const list = data;
    const [segment, setSegment] = useState<any>(filter)
    const segmented = list.length > 0 ? list.filter(item => {
        return handleSegment(item, segment)
    }) : [];


    return { segmented, segment, setSegment }
}

export default useSegment;