

export enum EnumFilterTypes {
    COMPANIES = "companies",
    RESTAURANTS = "restaurants",
    PAYMENT_TYPES = "payments",
    SEARCH = "search",
    MIN_DATE = "minDate",
    MAX_DATE = "maxDate",
    TIME_SCALE = "type",
    PAGE_NUMBER = "page"
    
    
}

export const filterTypes = {
    companies: EnumFilterTypes.COMPANIES,
    restaurants: EnumFilterTypes.RESTAURANTS,
    payment_types: EnumFilterTypes.PAYMENT_TYPES,
    search: EnumFilterTypes.SEARCH,
    minDate: EnumFilterTypes.MIN_DATE,
    maxDate: EnumFilterTypes.MAX_DATE,
    time_scale: EnumFilterTypes.TIME_SCALE,
    page_number: EnumFilterTypes.PAGE_NUMBER,

}