/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */
import {
  useQuery
} from 'react-query'
import type {
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  ProductTagPagingDTO,
  GetProductTagsByProductIdParams
} from '../../../dtos/go-api'
import { customInstance } from '.././config';

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


export const getProductTagsByProductId = (
    productId: string,
    params?: GetProductTagsByProductIdParams,
 signal?: AbortSignal
) => {
      return customInstance<ProductTagPagingDTO>(
      {url: `/v1/api/products/${productId}/tags`, method: 'get',
        params, signal
    },
      );
    }
  

export const getGetProductTagsByProductIdQueryKey = (productId: string,
    params?: GetProductTagsByProductIdParams,) => [`/v1/api/products/${productId}/tags`, ...(params ? [params]: [])];

    
export type GetProductTagsByProductIdQueryResult = NonNullable<Awaited<ReturnType<typeof getProductTagsByProductId>>>
export type GetProductTagsByProductIdQueryError = unknown

export const useGetProductTagsByProductId = <TData = Awaited<ReturnType<typeof getProductTagsByProductId>>, TError = unknown>(
 productId: string,
    params?: GetProductTagsByProductIdParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getProductTagsByProductId>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetProductTagsByProductIdQueryKey(productId,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProductTagsByProductId>>> = ({ signal }) => getProductTagsByProductId(productId,params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getProductTagsByProductId>>, TError, TData>({ queryKey, queryFn, enabled: !!(productId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

