import React, { useEffect, useState } from 'react';
import Select, {
	components,
	IndicatorContainerProps,
	InputActionMeta,
	OptionTypeBase,
} from 'react-select';
import Tag from '../../../../components/tag';
import Translation from '../../../../components/translation';
import { InputTagsProps, ITag } from './props';
import './styles.css';

const customStyles = {
	option: (provided: any) => ({
		...provided,
		backgroundColor: 'none',
		fontFamily: 'Poppins',
		fontStyle: 'normal',
		fontWeight: 300,
		fontSize: '14px',
		lineHeight: '20px',
		color: '#7F85A2',
		cursor: 'pointer',
	}),
	control: (provided: any, state: any) => ({
		...provided,
		outline: 'none',
		//width: 'fill',
		borderWidth: 2,
		borderColor: 'rgba(143, 146, 161, 0.2)',
		height: '100%',
		minHeight: '48px',
		width: '100%',
		minWidth: '251px',
		boxShadow: 'none !important',
	}),
	singleValue: () => ({
		opacity: 1,
	}),
	menu: (provided: any) => ({
		...provided,
		width: '100%',
		heigth: '100%',
	}),
};

const AddTagButton = (props: any) => {
	return (
		<div className='add-tag-button' onClick={props.handleClick}>
			<div className='add-tag-button-title'>
				<Translation>{'Add tag'}</Translation>
			</div>
		</div>
	);
};

const InputTags = ({
	options,
	values,
	handleSelect,
	handleDelete,
	handleCreateTag,
	placeholder = 'Select...',
}: InputTagsProps) => {
	const [search, setSearch] = useState('');
	const [isNew, setIsNew] = useState<boolean>();

	// Handlers
	function handleChange(option: any) {
		handleSelect(option);
	}

	function createNewTag() {
		// create new tag if the label doesn't exist yet
		if (
			!values?.some(
				(value) =>
					value.label.toLocaleLowerCase().trim() ===
					search.toLocaleLowerCase().trim()
			)
		) {
			handleCreateTag({
				label: search,
			});
			setSearch('');
		}
	}

	// Clear button
	const IndicatorsContainer = ({
		children,
		...props
	}: IndicatorContainerProps<OptionTypeBase, false>) => {
		return (
			<components.IndicatorsContainer {...props}>
				{isNew && <AddTagButton handleClick={createNewTag} />}
				{children}
			</components.IndicatorsContainer>
		);
	};

	const handleKeyDown = (e: any) => {
		if (e.key === 'Enter' && search !== '' && isNew) createNewTag();
		// Prevent from selecting the first value from dropdown
		if (e.key === 'Enter') e.preventDefault();
	};

	const onInputChange = (newValue: string, actionMeta: InputActionMeta) => {
		// Prevents resetting our input after option has been selected
		if (actionMeta.action === 'input-change') setSearch(newValue);
	};

	useEffect(() => {
		if (
			search !== '' &&
			options.every(
				(o) =>
					o.label.toLocaleLowerCase().trim() !==
					search.toLocaleLowerCase().trim()
			)
		) {
			setIsNew(true);
		} else {
			setIsNew(false);
		}
	}, [search]);

	return (
		<div className='input-tag-wrapper'>
			<Select
				components={{
					IndicatorsContainer: IndicatorsContainer,
				}}
				styles={customStyles}
				options={options?.filter(
					(tag) => !values?.map((v) => v.label)?.includes(tag.label)
				)}
				value={{ label: search, value: search }}
				onChange={handleChange}
				placeholder={placeholder}
				isClearable={false}
				noOptionsMessage={() => null}
				onKeyDown={handleKeyDown}
				inputValue={search}
				onInputChange={onInputChange}
				closeMenuOnSelect
			/>
			<div className='input-tag-container'>
				<div className='input-tags-selected'>
					{values?.map((tag: ITag, index) => (
						<Tag
							key={`${tag.label}_${index}`}
							name={tag.label}
							handleDelete={() => handleDelete(tag)}
						/>
					))}
				</div>
			</div>
		</div>
	);
};

export default InputTags;
