import Axios from "axios";
import Service from "./Service";

async function get(companyId: string, employeeId: string): Promise<any> {
	return Service.execute(
		Axios.get(
			`${Service.API_URL}/v1/api/companies/${companyId}/employees/${employeeId}/orders`,
			Service.config()
		)
	);
}

async function getById(orderId: string): Promise<any> {
	return Service.execute(
		Axios.get(
			`${Service.API_URL}/v1/api/orders/${orderId}`,
			Service.config()
		)
	);
}

async function getAll(type: string = "day"): Promise<any> {
	return Service.execute(
		Axios.get(
			`${Service.API_URL}/v1/api/orders?type=${type}`,
			Service.config()
		)
	);
}

let exported = { get, getById, getAll };

export default exported;
