import Icons from './assets'

export function mapInformationWithMenu(menu: string, restaurantId?: string, companyId?: string) {
    let information = {
        icon: Icons.CompaniesIcon,
        title: '',
        subTitle: '',
        link: '',
        addTitle: ''
    }
    switch (menu) {
        case 'companies':
            information.title = 'Add your first company'
            information.addTitle = 'Add new company'
            information.icon = Icons.CompaniesIcon
            information.link = '/dashboard/companies/details'
            information.subTitle = 'You currently have no companies listed'
            return information;
        case 'restaurants':
            information.addTitle = 'Add new restaurants'
            information.icon = Icons.RestaurantsIcon
            information.link = '/dashboard/restaurants/details'
            information.title = 'Add your first restaurants'
            information.subTitle = 'You currently have no restaurants listed'
            return information;
        case 'users':
            information.addTitle = 'Add new user'
            information.icon = Icons.UsersIcon
            information.link = `/dashboard/companies/${companyId}/users/details`
            information.title = 'Add your first user'
            information.subTitle = 'You currently have no users'
            return information;
        case 'employees':
            information.addTitle = 'Add new employee'
            information.icon = Icons.UsersIcon
            information.link = `/dashboard/companies/${companyId}/employees/details`
            information.title = 'Add your first employee'
            information.subTitle = 'You currently have no employee'
            return information;
        case 'dishes':
            information.addTitle = 'Add new dish'
            information.icon = Icons.DishIcon
            information.link = `/dashboard/restaurants/${restaurantId}/dishes/details`
            information.title = 'Add your first dish'
            information.subTitle = 'You currently have no dishes'
            return information;
        case 'promos':
            information.addTitle = 'Add new promo code'
            information.icon = Icons.PromoIcon
            information.link = '/dashboard/promos/details'
            information.title = 'Add your first promo code'
            information.subTitle = 'You currently have no promo listed'
            return information;

        default:
            return information

    }
}