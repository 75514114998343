import React from "react";
import { useTranslation } from "../../../../../../../../components/translation-context/TranslationContext";
import { SelectionBannerProps } from "./props";
import SelectedPrintButton from "../filters/selected-print-button";

const SelectionBanner = ({
  isEveryOrderSelected,
  items,
  onClear,
  openNotificationDialog,
  allOrders,
  allOrdersWithoutPaging,
  isAllOrdersWithoutPagingLoading,
}: SelectionBannerProps) => {
  const { translate } = useTranslation();

  // Render
  if (items.length > 0 || isEveryOrderSelected) {
    return (
      <div style={customStyles.selectionContainer}>
        <div style={customStyles.itemsTagContainer}>
          <div style={customStyles.unselectAll} onClick={onClear}>
            {translate("Unselect all")}
          </div>
          <div style={customStyles.separator} />
          <div style={customStyles.label}>
            {isEveryOrderSelected ? (
              <>{translate("Selected all")}</>
            ) : (
              <>
                {items.length} {translate("Selected")}
              </>
            )}
          </div>
        </div>

        <div style={customStyles.controlsContainer}>
          <SelectedPrintButton
            isEveryOrderSelected={isEveryOrderSelected}
            selectedOrderIds={items}
            allOrders={allOrders}
            allOrdersWithoutPaging={allOrdersWithoutPaging}
            isAllOrdersWithoutPagingLoading={isAllOrdersWithoutPagingLoading}
          />
          <div style={customStyles.sendButton} onClick={openNotificationDialog}>
            {translate("Send notification")}
          </div>
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
};

const customStyles: any = {
  selectionContainer: {
    "font-size": "14px",
    padding: "20px",
    left: "85px",
    alignItems: "center",
    bottom: "0",
    width: "100%",
    position: "fixed",
    "background-color": "#061A42",
    color: "white",
    display: "flex",
    "justify-content": "space-between",
  },
  clearButton: {
    padding: "13px",
  },
  sendButton: {
    color: "#000",
    "background-color": "#fff",
    "border-radius": "12px",
    padding: "14px",
    cursor: "pointer",
  },
  label: {
    padding: "8px 14px",
    backgroundColor: "#F5C755",
    borderRadius: "50px",
    color: "#061A42",
  },
  itemsTagContainer: {
    display: "flex",
    gap: "15px",
  },
  controlsContainer: {
    display: "flex",
    "margin-right": "125px",
    gap: "12px",
  },
  separator: {
    height: "full",
    width: "1px",
    backgroundColor: "white",
  },
  unselectAll: {
    margin: "auto",
    cursor: "pointer",
  },
};

export default SelectionBanner;
