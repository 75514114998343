import React, { forwardRef, useCallback } from 'react';
import './style.css';
import 'react-datepicker/dist/react-datepicker.css';
import { DateFilterProps } from './props';
import { TimeScale } from '../../../../types';
import DatePicker from 'react-datepicker';
import { useTranslation } from '../../../../components/translation-context/TranslationContext';
import Calendar from '../../assets/calendar';
import { useHistory } from 'react-router-dom';

const DateFilter = ({
    selected,
    time,
    endDate,
    startDate,
    setStartDate,
    setEndDate,
    url
}: DateFilterProps) => {
    // Attributes
    const { translate } = useTranslation();
    const history = useHistory();

    // Functions
    const handleClick = useCallback((timeScale: string) => {
        history.replace(`${url}?type=${timeScale.toLowerCase()}`);
    }, [history, url]);

    const DatePickerIcon = forwardRef(({ onClick }: any, ref: any) => (
        <div onClick={onClick} ref={ref}>
            <Calendar width='2.375rem' height='2.375rem' fill='#061A42' />
        </div>
    ));

    const onDateChange = (dates: (Date | null)[]) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    return (
        <div
            className={`orders-time-scale-container-container ${selected ? 'selected' : ''}`}
            key={`time_scale_${time}`}
            onClick={() => handleClick(time)}
        >
            <div
                className='orders-time-scale-container-text'
                style={selected ? { color: '#061A42' } : { color: 'rgba(6, 26, 66, 0.25)' }}
            >
                {time === TimeScale.Pick ? (
                    <DatePicker
                        selected={startDate}
                        onChange={onDateChange}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        customInput={<DatePickerIcon />}
                        calendarStartDay={1}
                    />
                ) : (
                    translate(`time-scale-${time}`)
                )}
            </div>
        </div>
    );
};

export default DateFilter;
