import { CompanyReqCreateDTO } from "../../../../../../../../dtos/go-api";
import { CompanyResDTO } from "../../../../../../../../dtos/go-api/companyResDTO";

export interface AddressProps {
    company: CompanyReqCreateDTO,
    dispatch: any
}

export enum ADDRESS_FIELDS {
    STREETNAME = 'streetName',
    COUNTRY = 'country',
    CITYNAME = 'cityName',
    NUMBER = 'number',
    ZIPCODE = 'zipCode'
}