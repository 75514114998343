import React, { } from "react";
import { useTranslation } from "../../../../../../../../components/translation-context/TranslationContext";
import { OrdersListHeaderProps, TimeScale } from "./props";
import "./style.css";
import "react-datepicker/dist/react-datepicker.css";
import DateFilter from "../../../../../../components/date-filter";

const OrdersListHeader = ({
	totalOrders,
	timeScale,
	startDate,
	setStartDate,
	endDate,
	setEndDate,
}: OrdersListHeaderProps) => {
	// Attributes
	const { translate } = useTranslation();

	return (
		<div className="orders-header-container">
			<div className="orders-header-title">
				<div>{translate("orders")}</div>
				<div className="orders-header-title-total-orders">
					{totalOrders ? `(${totalOrders})` : ""}
				</div>
			</div>
			<div className="orders-time-scale-container">
				{Object.values(TimeScale).map((time) => (
					<DateFilter
						selected={time === timeScale}
						time={time}
						endDate={endDate}
						startDate={startDate}
						setEndDate={setEndDate}
						setStartDate={setStartDate}
						url={'/dashboard/new-orders'}
					/>
				))}
			</div>
		</div>
	);
};

export default OrdersListHeader;
