import { OrderTimingType } from "../../../../../../../../dtos/companyDTO";
import { EmployeeDTO, EmployeeType } from "../../../../../../../../dtos/employeeDTO";
import { UserDTO, UserType } from "../../../../../../../../dtos/userDTO";
interface ActionInterface {
    target: string,
    payload: any,
    type: UserActions
}

export enum UserActions {
    SERVER_CHANGED,
    USER_CHANGED,
    EMPLOYEE_CHANGED
}

export interface EmployeeReducer {
    employee: EmployeeDTO,
    dispatch: any
}

export enum USER_FIELDS {
    FIRSTNAME = 'firstName',
    LASTNAME = 'lastName',
    EMAIL = 'email',
    PHONENUMBER = 'phoneNumber',
    USERTYPE = 'userType',
}

export enum EMPLOYEE_FIELDS {
    EMPLOYEE_TYPE = 'employeeType'
}

export const initialEmployee: EmployeeDTO = {
    id: '',
    company: {
        id: '',
        name: '',
        vatNumber: '',
        deliveryComments: '',
        address: {
            id: '',
            streetName: '',
            number: '',
            zipCode: '',
            cityName: '',
            country: '',
        },
        addressId: '',
        logo: '',
        mobileLogo: '',
        orderTimingType: OrderTimingType.Noon,
        code: ''
    },
    companyId: '',
    userId: '',
    employeeType: EmployeeType.UNKNOWN,
    user: {
        email: '',
        firstName: '',
        id: "",
        lastName: "",
        phoneNumber: "",
        userType: UserType.UNKNOWN,
        password: "",
        oldPassword: ""
    }
}

export const employeeReducer = (state: EmployeeDTO, action: ActionInterface) => {
    switch (action.type) {
        case UserActions.SERVER_CHANGED:
            return {
                ...state,
                ...action.payload
            }
        case UserActions.USER_CHANGED:
            return {
                ...state,
                user: {
                    ...state.user,
                    [action.target]: action.payload
                }
            };
        case UserActions.EMPLOYEE_CHANGED:
            return {
                ...state,
                [action.target]: action.payload
            }
        default:
            return {
                ...state,
            }
    }

}