import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ZoneDetails from './modules/details';
import ZoneList from './modules/list';

const Zones = () => {
  return (
    <Switch>
      <Route path='/dashboard/zones/:zoneId/details' component={ZoneDetails} />
      <Route path='/dashboard/zones/details' component={ZoneDetails} />
      <Route path='/dashboard/zones' component={ZoneList} />
    </Switch>
  );
};

export default Zones;
