import React from 'react';
import InputText from '../../../../../../../../components/input-text';
import Translation from '../../../../../../../../components/translation';
import BackgroundDetailsForm from '../../../../../../components/background-details-form';
import GroupInputDetails from '../../../../../../components/group-input-details';
import { InputPromoCodeProps } from './props';

const InputPromoCode = ({ value, onChange }: InputPromoCodeProps) => {


    return (
        <BackgroundDetailsForm style={{ height: 'none' }}>
            <GroupInputDetails title='Promo code'>
                <InputText required={true} placeholder='ex: WRAP10' value={value} onChange={onChange} size='medium' style={{ marginTop: 20 }} />
                <div className='input-promo-code-sub-text'><Translation>Customers will enter this discount code at checkout.</Translation></div>
            </GroupInputDetails>
        </BackgroundDetailsForm>
    )
}

export default InputPromoCode;