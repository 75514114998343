import React from "react";

const Calendar = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg
			viewBox="0 0 38 38"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<rect width="38" height="38" rx="12" fill="#ECEEF3" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15.0002 14.9645C15.1327 14.9645 15.2638 14.9384 15.3862 14.8877C15.5086 14.8369 15.6197 14.7626 15.7134 14.6689C15.807 14.5751 15.8812 14.4639 15.9318 14.3415C15.9824 14.219 16.0083 14.0878 16.0082 13.9554V12.1359C16.0082 12.0036 15.9821 11.8725 15.9315 11.7502C15.8808 11.6279 15.8066 11.5168 15.713 11.4232C15.6194 11.3296 15.5082 11.2553 15.3859 11.2047C15.2636 11.154 15.1326 11.1279 15.0002 11.1279C14.8678 11.1279 14.7367 11.154 14.6144 11.2047C14.4921 11.2553 14.381 11.3296 14.2874 11.4232C14.1938 11.5168 14.1196 11.6279 14.0689 11.7502C14.0183 11.8725 13.9922 12.0036 13.9922 12.1359V13.9554C13.992 14.0878 14.018 14.219 14.0686 14.3415C14.1192 14.4639 14.1934 14.5751 14.287 14.6689C14.3806 14.7626 14.4918 14.8369 14.6142 14.8877C14.7365 14.9384 14.8677 14.9645 15.0002 14.9645Z"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M23.0002 14.9645C23.1327 14.9645 23.2638 14.9384 23.3862 14.8877C23.5086 14.8369 23.6197 14.7626 23.7134 14.6689C23.807 14.5751 23.8812 14.4639 23.9318 14.3415C23.9824 14.219 24.0083 14.0878 24.0082 13.9554V12.1359C24.0082 11.8686 23.902 11.6122 23.713 11.4232C23.5239 11.2341 23.2675 11.1279 23.0002 11.1279C22.7328 11.1279 22.4765 11.2341 22.2874 11.4232C22.0984 11.6122 21.9922 11.8686 21.9922 12.1359V13.9554C21.992 14.0878 22.018 14.219 22.0686 14.3415C22.1192 14.4639 22.1934 14.5751 22.287 14.6689C22.3806 14.7626 22.4918 14.8369 22.6142 14.8877C22.7365 14.9384 22.8677 14.9645 23.0002 14.9645Z"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M25.4777 12.4768V14.1396C25.4913 14.7709 25.2725 15.3852 24.8629 15.8658C24.4533 16.3464 23.8815 16.6598 23.256 16.7465C22.9109 16.7821 22.5622 16.745 22.2324 16.6373C21.9026 16.5297 21.5991 16.354 21.3415 16.1216C21.0839 15.8892 20.878 15.6053 20.737 15.2883C20.5961 14.9713 20.5233 14.6282 20.5234 14.2813V12.879C20.5231 12.7701 20.4796 12.6658 20.4025 12.5889C20.3254 12.5119 20.2209 12.4687 20.112 12.4688H17.888C17.7792 12.4688 17.6748 12.512 17.5979 12.5889C17.5209 12.6659 17.4777 12.7702 17.4777 12.879V14.1362C17.4921 14.7681 17.2737 15.3832 16.864 15.8645C16.4544 16.3458 15.8821 16.6597 15.256 16.7465C14.9109 16.7821 14.5622 16.745 14.2324 16.6373C13.9026 16.5297 13.5991 16.354 13.3415 16.1216C13.0839 15.8892 12.878 15.6053 12.737 15.2883C12.5961 14.9713 12.5233 14.6282 12.5234 14.2813V12.4768C12.1139 12.4964 11.7275 12.6725 11.444 12.9687C11.1605 13.265 11.0016 13.6587 11 14.0688V25.2688C11 25.6931 11.1686 26.1001 11.4686 26.4001C11.7687 26.7002 12.1757 26.8688 12.6 26.8688H25.4C25.8243 26.8688 26.2313 26.7002 26.5314 26.4001C26.8314 26.1001 27 25.6931 27 25.2688V14.0688C26.9984 13.6589 26.8397 13.2653 26.5564 12.9691C26.2732 12.6729 25.8871 12.4967 25.4777 12.4768ZM24.1429 23.3145C24.142 23.5724 24.0391 23.8196 23.8567 24.002C23.6743 24.1844 23.4271 24.2873 23.1691 24.2882H14.5977C14.3397 24.2873 14.0926 24.1844 13.9102 24.002C13.7278 23.8196 13.6249 23.5724 13.624 23.3145V19.5202C13.624 19.261 13.7269 19.0125 13.9102 18.8292C14.0934 18.646 14.342 18.543 14.6011 18.543H23.1726C23.4305 18.5439 23.6777 18.6468 23.8601 18.8292C24.0425 19.0116 24.1454 19.2588 24.1463 19.5168L24.1429 23.3145Z"
			/>
		</svg>
	);
};
export default Calendar;
