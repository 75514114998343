import React from "react";
import Checked from "../assets/checked";
import Unchecked from "../assets/unchecked";
import { CheckboxProps } from "./props";

const Checkbox = ({
    name,
    value,
    onChange,
}: CheckboxProps) => {

    // Render
    return (
        <div
            style={customStyles.optionContainer}
            key={`${name}_option_${value}`}
            onClick={() => {
                return onChange && onChange(value)
            }}
        >
            <div
                style={customStyles.optionIconContainer}
            >
                {value ? (
                    <Checked
                        width="2.125rem"
                        height="2.125rem"
                    />
                ) : (
                    <Unchecked
                        width="2.125rem"
                        height="2.125rem"
                    />
                )}
            </div>
        </div>
    );
};

const customStyles: any = {
    optionContainer: {
        display: "flex",
        alignItems: "center",
        gap: ".5rem",
        height: "2.125rem",
        cursor: "pointer",
    },
    optionIconContainer: {
        width: "2.125rem",
        height: "2.125rem",
    },
};

export default Checkbox;
