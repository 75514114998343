import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import AppVersioningList from "./modules/list";

const AppVersioning = () => {
	return (
		<Switch>
			<Route path="/dashboard/app-versioning/list" component={AppVersioningList} />
			<Route path='/dashboard/app-versioning/' render={() => <Redirect to="/dashboard/app-versioning/list" />} />
		</Switch>
	);
};

export default AppVersioning;
