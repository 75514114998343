import React from "react";

const ChevronLeft = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path d="M11.2898 11.9999L14.8298 8.45995C15.0161 8.27259 15.1206 8.01913 15.1206 7.75495C15.1206 7.49076 15.0161 7.23731 14.8298 7.04995C14.7369 6.95622 14.6263 6.88183 14.5044 6.83106C14.3825 6.78029 14.2518 6.75415 14.1198 6.75415C13.9878 6.75415 13.8571 6.78029 13.7352 6.83106C13.6134 6.88183 13.5028 6.95622 13.4098 7.04995L9.16982 11.2899C9.07609 11.3829 9.0017 11.4935 8.95093 11.6154C8.90016 11.7372 8.87402 11.8679 8.87402 11.9999C8.87402 12.132 8.90016 12.2627 8.95093 12.3845C9.0017 12.5064 9.07609 12.617 9.16982 12.7099L13.4098 16.9999C13.5033 17.0926 13.6141 17.166 13.7359 17.2157C13.8578 17.2655 13.9882 17.2907 14.1198 17.2899C14.2514 17.2907 14.3819 17.2655 14.5037 17.2157C14.6256 17.166 14.7364 17.0926 14.8298 16.9999C15.0161 16.8126 15.1206 16.5591 15.1206 16.2949C15.1206 16.0308 15.0161 15.7773 14.8298 15.5899L11.2898 11.9999Z" />
		</svg>
	);
};
export default ChevronLeft;
