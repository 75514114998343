/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation
} from 'react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  PromoCodeResDTO,
  PromoCodeReqUpdateDTO,
  PromoCodePagingResDTO,
  GetPromoCodesParams,
  PromoCodeReqCreateDTO,
  KlaviyoWebhookPromoCodeReqCreateDTO,
  CompanyResDTO,
  RestaurantResDTO
} from '../../../dtos/go-api'
import { customInstance } from '.././config';

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


export const getPromoCodeById = (
    promoCodeId: string,
 signal?: AbortSignal
) => {
      return customInstance<PromoCodeResDTO>(
      {url: `/v1/api/promocodes/${promoCodeId}`, method: 'get', signal
    },
      );
    }
  

export const getGetPromoCodeByIdQueryKey = (promoCodeId: string,) => [`/v1/api/promocodes/${promoCodeId}`];

    
export type GetPromoCodeByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getPromoCodeById>>>
export type GetPromoCodeByIdQueryError = unknown

export const useGetPromoCodeById = <TData = Awaited<ReturnType<typeof getPromoCodeById>>, TError = unknown>(
 promoCodeId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPromoCodeById>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetPromoCodeByIdQueryKey(promoCodeId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPromoCodeById>>> = ({ signal }) => getPromoCodeById(promoCodeId, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getPromoCodeById>>, TError, TData>({ queryKey, queryFn, enabled: !!(promoCodeId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export const updatePromoCodeById = (
    promoCodeId: string,
    promoCodeReqUpdateDTO: PromoCodeReqUpdateDTO,
 ) => {
      return customInstance<PromoCodeResDTO>(
      {url: `/v1/api/promocodes/${promoCodeId}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: promoCodeReqUpdateDTO
    },
      );
    }
  


    export type UpdatePromoCodeByIdMutationResult = NonNullable<Awaited<ReturnType<typeof updatePromoCodeById>>>
    export type UpdatePromoCodeByIdMutationBody = PromoCodeReqUpdateDTO
    export type UpdatePromoCodeByIdMutationError = unknown

    export const useUpdatePromoCodeById = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updatePromoCodeById>>, TError,{promoCodeId: string;data: PromoCodeReqUpdateDTO}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updatePromoCodeById>>, {promoCodeId: string;data: PromoCodeReqUpdateDTO}> = (props) => {
          const {promoCodeId,data} = props ?? {};

          return  updatePromoCodeById(promoCodeId,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof updatePromoCodeById>>, TError, {promoCodeId: string;data: PromoCodeReqUpdateDTO}, TContext>(mutationFn, mutationOptions);
    }
    export const deletePromoCodeById = (
    promoCodeId: string,
 ) => {
      return customInstance<PromoCodeResDTO>(
      {url: `/v1/api/promocodes/${promoCodeId}`, method: 'delete'
    },
      );
    }
  


    export type DeletePromoCodeByIdMutationResult = NonNullable<Awaited<ReturnType<typeof deletePromoCodeById>>>
    
    export type DeletePromoCodeByIdMutationError = unknown

    export const useDeletePromoCodeById = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deletePromoCodeById>>, TError,{promoCodeId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deletePromoCodeById>>, {promoCodeId: string}> = (props) => {
          const {promoCodeId} = props ?? {};

          return  deletePromoCodeById(promoCodeId,)
        }

        

      return useMutation<Awaited<ReturnType<typeof deletePromoCodeById>>, TError, {promoCodeId: string}, TContext>(mutationFn, mutationOptions);
    }
    export const getPromoCodes = (
    params?: GetPromoCodesParams,
 signal?: AbortSignal
) => {
      return customInstance<PromoCodePagingResDTO>(
      {url: `/v1/api/promocodes`, method: 'get',
        params, signal
    },
      );
    }
  

export const getGetPromoCodesQueryKey = (params?: GetPromoCodesParams,) => [`/v1/api/promocodes`, ...(params ? [params]: [])];

    
export type GetPromoCodesQueryResult = NonNullable<Awaited<ReturnType<typeof getPromoCodes>>>
export type GetPromoCodesQueryError = unknown

export const useGetPromoCodes = <TData = Awaited<ReturnType<typeof getPromoCodes>>, TError = unknown>(
 params?: GetPromoCodesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPromoCodes>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetPromoCodesQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPromoCodes>>> = ({ signal }) => getPromoCodes(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getPromoCodes>>, TError, TData>({ queryKey, queryFn, ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export const createPromoCode = (
    promoCodeReqCreateDTO: PromoCodeReqCreateDTO,
 ) => {
      return customInstance<PromoCodeResDTO>(
      {url: `/v1/api/promocodes`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: promoCodeReqCreateDTO
    },
      );
    }
  


    export type CreatePromoCodeMutationResult = NonNullable<Awaited<ReturnType<typeof createPromoCode>>>
    export type CreatePromoCodeMutationBody = PromoCodeReqCreateDTO
    export type CreatePromoCodeMutationError = unknown

    export const useCreatePromoCode = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createPromoCode>>, TError,{data: PromoCodeReqCreateDTO}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createPromoCode>>, {data: PromoCodeReqCreateDTO}> = (props) => {
          const {data} = props ?? {};

          return  createPromoCode(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof createPromoCode>>, TError, {data: PromoCodeReqCreateDTO}, TContext>(mutationFn, mutationOptions);
    }
    export const createKlaviyoWebhookPromoCode = (
    klaviyoWebhookPromoCodeReqCreateDTO: KlaviyoWebhookPromoCodeReqCreateDTO,
 ) => {
      return customInstance<void>(
      {url: `/v1/api/klaviyo/webhooks/promo-codes`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: klaviyoWebhookPromoCodeReqCreateDTO
    },
      );
    }
  


    export type CreateKlaviyoWebhookPromoCodeMutationResult = NonNullable<Awaited<ReturnType<typeof createKlaviyoWebhookPromoCode>>>
    export type CreateKlaviyoWebhookPromoCodeMutationBody = KlaviyoWebhookPromoCodeReqCreateDTO
    export type CreateKlaviyoWebhookPromoCodeMutationError = unknown

    export const useCreateKlaviyoWebhookPromoCode = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createKlaviyoWebhookPromoCode>>, TError,{data: KlaviyoWebhookPromoCodeReqCreateDTO}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createKlaviyoWebhookPromoCode>>, {data: KlaviyoWebhookPromoCodeReqCreateDTO}> = (props) => {
          const {data} = props ?? {};

          return  createKlaviyoWebhookPromoCode(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof createKlaviyoWebhookPromoCode>>, TError, {data: KlaviyoWebhookPromoCodeReqCreateDTO}, TContext>(mutationFn, mutationOptions);
    }
    export const getCompaniesByPromoCodeId = (
    promoCodeId: string,
 signal?: AbortSignal
) => {
      return customInstance<CompanyResDTO[]>(
      {url: `/v1/api/promocodes/${promoCodeId}/companies`, method: 'get', signal
    },
      );
    }
  

export const getGetCompaniesByPromoCodeIdQueryKey = (promoCodeId: string,) => [`/v1/api/promocodes/${promoCodeId}/companies`];

    
export type GetCompaniesByPromoCodeIdQueryResult = NonNullable<Awaited<ReturnType<typeof getCompaniesByPromoCodeId>>>
export type GetCompaniesByPromoCodeIdQueryError = unknown

export const useGetCompaniesByPromoCodeId = <TData = Awaited<ReturnType<typeof getCompaniesByPromoCodeId>>, TError = unknown>(
 promoCodeId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCompaniesByPromoCodeId>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCompaniesByPromoCodeIdQueryKey(promoCodeId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCompaniesByPromoCodeId>>> = ({ signal }) => getCompaniesByPromoCodeId(promoCodeId, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getCompaniesByPromoCodeId>>, TError, TData>({ queryKey, queryFn, enabled: !!(promoCodeId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export const getRestaurantsByPromoCodeId = (
    promoCodeId: string,
 signal?: AbortSignal
) => {
      return customInstance<RestaurantResDTO[]>(
      {url: `/v1/api/promocodes/${promoCodeId}/restaurants`, method: 'get', signal
    },
      );
    }
  

export const getGetRestaurantsByPromoCodeIdQueryKey = (promoCodeId: string,) => [`/v1/api/promocodes/${promoCodeId}/restaurants`];

    
export type GetRestaurantsByPromoCodeIdQueryResult = NonNullable<Awaited<ReturnType<typeof getRestaurantsByPromoCodeId>>>
export type GetRestaurantsByPromoCodeIdQueryError = unknown

export const useGetRestaurantsByPromoCodeId = <TData = Awaited<ReturnType<typeof getRestaurantsByPromoCodeId>>, TError = unknown>(
 promoCodeId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getRestaurantsByPromoCodeId>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRestaurantsByPromoCodeIdQueryKey(promoCodeId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getRestaurantsByPromoCodeId>>> = ({ signal }) => getRestaurantsByPromoCodeId(promoCodeId, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getRestaurantsByPromoCodeId>>, TError, TData>({ queryKey, queryFn, enabled: !!(promoCodeId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

