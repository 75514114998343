import Axios, { AxiosRequestConfig } from "axios";

const getAuthorizationHeader = () => {
  const access_token = localStorage.getItem("ACCESS_TOKEN");
  return {
    headers: {
      Authorization: `bearer ${access_token}`,
    },
  };
};

export const AXIOS_INSTANCE = Axios.create({
  baseURL: process.env.REACT_APP_RESOURCE_SERVER,
});

export interface InstanceOptions {
  testId: string;
}

export const customInstance = async <T>(
  config: AxiosRequestConfig,
  options?: InstanceOptions
): Promise<T> => {
  const source = Axios.CancelToken.source();
  const promise = AXIOS_INSTANCE({
    ...config,
    ...getAuthorizationHeader(),
    cancelToken: source.token,
  }).then(({ data }) => data);

  // eslint-disable-next-line
  // @ts-ignore
  promise.cancel = () => {
    source.cancel("Query was cancelled by React Query");
  };

  return promise;
};
