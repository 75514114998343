import { Defs, G, Path, Rect, Svg } from "@react-pdf/renderer";
import React from "react";

const FoodizLogo = () => {
  return (
    <Svg width='42' height='16' viewBox='0 0 42 16' fill='none'>
      <G clip-path='url(#clip0_9695_282)'>
        <Path
          d='M24.9808 9.79946C25.554 9.56204 25.8262 8.90492 25.5888 8.33175C25.3514 7.75857 24.6943 7.48639 24.1211 7.7238C23.5479 7.96122 23.2757 8.61834 23.5131 9.19151C23.7506 9.76469 24.4077 10.0369 24.9808 9.79946Z'
          fill='black'
        />
        <Path
          d='M18.5276 8.36191C19.148 8.36191 19.651 7.85897 19.651 7.23857C19.651 6.61817 19.148 6.11523 18.5276 6.11523C17.9072 6.11523 17.4043 6.61817 17.4043 7.23857C17.4043 7.85897 17.9072 8.36191 18.5276 8.36191Z'
          fill='black'
        />
        <Path
          d='M13.5422 9.10917C13.7796 8.53599 13.5074 7.87888 12.9342 7.64146C12.361 7.40404 11.7039 7.67623 11.4665 8.2494C11.2291 8.82258 11.5013 9.4797 12.0744 9.71711C12.6476 9.95453 13.3047 9.68235 13.5422 9.10917Z'
          fill='black'
        />
        <Path
          d='M33.6452 0H8C3.5817 0 0 3.5817 0 8C0 12.4183 3.5817 16 8 16H33.6452C38.0635 16 41.6452 12.4183 41.6452 8C41.6452 3.5817 38.0635 0 33.6452 0ZM9.02313 8.93528H7.61413V11.4032H5.27332V7.31589C5.27332 5.72578 6.56237 4.43695 8.15227 4.43695H9.83152V6.13725H8.70233C8.10129 6.13725 7.61413 6.62441 7.61413 7.22544H9.02313V8.9355V8.93528ZM12.5042 11.5609C10.9128 11.5609 9.62265 10.2707 9.62265 8.67934C9.62265 7.08793 10.9128 5.79779 12.5042 5.79779C14.0956 5.79779 15.3857 7.08793 15.3857 8.67934C15.3857 10.2707 14.0956 11.5609 12.5042 11.5609ZM18.5276 10.12C16.9361 10.12 15.646 8.82987 15.646 7.23846C15.646 5.64705 16.9361 4.35691 18.5276 4.35691C20.119 4.35691 21.4091 5.64705 21.4091 7.23846C21.4091 8.82987 20.119 10.12 18.5276 10.12ZM27.4325 8.76176C27.4325 10.0397 26.6004 11.1232 25.4487 11.5006C25.1661 11.5932 24.8644 11.6433 24.5509 11.6433C22.9595 11.6433 21.6694 10.3532 21.6694 8.76176C21.6694 7.17035 22.9595 5.88022 24.5509 5.88022C24.7782 5.88022 24.9993 5.90668 25.2114 5.95635V4.43695H27.4327V8.76176H27.4325ZM30.6439 11.4032H28.4272V5.95548H30.6439V11.4032ZM30.6439 5.45183H28.4272V4.35691H30.6439V5.45183ZM36.3721 7.69417L34.2269 9.82697H36.3721V11.4032H31.6386V9.69878L33.8374 7.53149H31.6386V5.95526H36.3721V7.69417Z'
          fill='black'
        />
      </G>
      <Defs>
        <clipPath id='clip0_9695_282'>
          <Rect width='41.6452' height='16' fill='white' />
        </clipPath>
      </Defs>
    </Svg>
  );
};

export default FoodizLogo;
