import React from "react";
import { UploadPictureRestaurantDetailProps } from "./props";
import UploadPicture from "../../../../components/upload-picture";
import { RestaurantType } from "../../../../../../../../dtos/go-api";

const UploadPictureRestaurantDetail = ({
  restaurant,
  dispatch,
  restaurantType,
}: UploadPictureRestaurantDetailProps) => {
  // Attributes

  //Functions
  function handleChangePicture(pictureUrl: string, target?: string) {
    dispatch({
      type: "restaurantChanged",
      target: target,
      payload: pictureUrl,
    });
  }

  function renderUploadFileByRestaurantType() {
    switch (restaurantType) {
      case RestaurantType.B2B:
        return (
          <UploadPicture
            title="uploadHoverImage"
            pictureUrl={restaurant?.hoverPictureUrl as string}
            handleChangePictureUrl={handleChangePicture}
            target={"hoverPictureUrl"}
          />
        );
      case RestaurantType.B2C:
        return (
          <UploadPicture
            title="uploadAdvertisement"
            pictureUrl={restaurant?.advertisementUrl as string}
            handleChangePictureUrl={handleChangePicture}
            target={"advertisementUrl"}
          />
        );
      default:
        return (
          <>
            <UploadPicture
              title="uploadHoverImage"
              pictureUrl={restaurant?.hoverPictureUrl as string}
              handleChangePictureUrl={handleChangePicture}
              target={"hoverPictureUrl"}
            />
            <UploadPicture
              title="uploadAdvertisement"
              pictureUrl={restaurant?.advertisementUrl as string}
              handleChangePictureUrl={handleChangePicture}
              target={"advertisementUrl"}
            />
          </>
        );
    }
  }

  //Render
  return (
    <>
      <UploadPicture
        title="uploadRestaurantLogo"
        pictureUrl={restaurant?.pictureUrl}
        handleChangePictureUrl={handleChangePicture}
        target={"pictureUrl"}
      />
      <UploadPicture
        title="uploadBannerImage"
        pictureUrl={restaurant?.bannerPictureUrl as string}
        handleChangePictureUrl={handleChangePicture}
        target={"bannerPictureUrl"}
      />
      <UploadPicture
        title="uploadMobileBannerImage"
        pictureUrl={restaurant?.mobileBannerPictureUrl as string}
        handleChangePictureUrl={handleChangePicture}
        target={"mobileBannerPictureUrl"}
      />

      {/*Check restaurantType to see which file should be uploaded */}
      {renderUploadFileByRestaurantType()}
    </>
  );
};

export default UploadPictureRestaurantDetail;
