import React, { useMemo } from 'react';
import Search from '../../../../../../components/search';
import { AppVersioningFiltersProps } from './props';
import './style.css';
import Pager from '../../../../../../../../components/pager';
import Close from '../assets/close';

const AppVersioningFilters = ({
  page,
  totalPages,
  setPage,
  search,
  setSearch,
}: AppVersioningFiltersProps) => {
  // Attributes
  const hasFilters = useMemo(() => {
    return (
      search !== ''
    );
  }, [search]);

  // Handlers
  function onResetFilters() {
    setSearch('');
    setPage && setPage(0);
  }

  // Render
  return (
    <div style={customStyles.container}>
      <div style={customStyles.row}>
        <div style={customStyles.filtersContainer}>
          <Search
            placeholder='Search'
            value={search}
            onChange={(value) => {
              setSearch(value);
              setPage && setPage(0);
            }}
            width='15.625rem'
            animation={false}
          />
        </div>
        <div style={customStyles.filtersRightContainer}>
          <Pager page={page} totalPages={totalPages} setPage={setPage} />
        </div>
      </div>
      {hasFilters && (
        <div
          style={customStyles.resetFiltersContainer}
          onClick={onResetFilters}
        >
          <Close width='.625rem' height='.625rem' fill='rgba(6, 26, 66, 1)' />
          <span style={customStyles.resetFiltersText}>Reset filters</span>
        </div>
      )}
    </div>
  );
};

const customStyles: any = {
  container: {
    margin: '2.875rem 2.5625rem 0 2.5625rem',
    display: 'flex',
    gap: '.9375rem',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '3.625rem',
  },
  filtersContainer: {
    display: 'flex',
    height: '100%',
    gap: '1.125rem',
  },
  filtersRightContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '1.3125rem',
  },
  resetFiltersContainer: {
    display: 'flex',
    gap: '.5rem',
    alignItems: 'center',
    cursor: 'pointer',
    width: 'fit-content',
  },
  resetFiltersText: {
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '15px',
    color: 'rgba(6, 26, 66, 0.3)',
  },
};

export default AppVersioningFilters;
