export const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: 50,
        border: 'none',
        borderRadius: 18,
        maxWidth: 509
    },
    overlay: {
        backgroundColor: 'rgba(0,0,0,0.5)'
    }
};