import React from 'react';
import './style.css';
import Translation from '../../../../../../../../components/translation';
import CompaniesIcon from './assets';

const EmptyStates = () => {

  return (
    <div className='empty-states-container'>
      <CompaniesIcon />

      <div className='empty-states-header-title'>
        <Translation>{'No orders found'}</Translation>
      </div>

    </div>
  );
};

export default EmptyStates;
