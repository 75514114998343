export enum TimeScale {
  Hour = "hour",
  Day = "day",
  Week = "week",
  Month = "month",
  Pick = "pick",
}

export enum DateFormat {
  HH_MM_DD_MM_YYYY = "HH:mm - dd/MM/yyyy",
}
