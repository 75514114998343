import { format, parse } from 'date-fns';
import React from 'react';
import InputText from '../../../../../../../../components/input-text';
import { actions } from '../../reducer';
import { OrderClosingHoursProps, ClosingHoursType } from './props';

const OrderClosingHours = ({
	company,
	dispatch,
	type,
}: OrderClosingHoursProps) => {
	// Functions

	// Render
	return (
		<div className='company-info' style={{ marginTop: 74, width: 300 }}>
			<div className='input-group-title'>
				{type === ClosingHoursType.END
					? 'Kitchen closes until'
					: 'Kitchen closes from'}
			</div>
			<div className={`company-info-container`}>
				<div className='company-info' style={{ marginRight: 8 }}>
					<label className='input-with-label-title'>{'HOUR & MINUTES'}</label>
					<InputText
						size='small'
						type='time'
						placeholder='Insert hour'
						onChange={(event: any) =>
							dispatch({
								type: actions.companyChanged,
								target:
									type === ClosingHoursType.END
										? 'kitchenClosingHourEnd'
										: 'kitchenClosingHourBegin',
								payload: parse(event.target.value, 'HH:mm', new Date()),
							})
						}
						value={
							type === ClosingHoursType.END
								? company?.kitchenClosingHourEnd &&
								  format(new Date(company?.kitchenClosingHourEnd), 'HH:mm')
								: company?.kitchenClosingHourBegin &&
								  format(new Date(company?.kitchenClosingHourBegin), 'HH:mm')
						}
						required={true}
					/>
				</div>
			</div>
		</div>
	);
};

export default OrderClosingHours;
