import React from 'react';
import { useHistory } from 'react-router-dom';
import Translation from '../../../../components/translation';
import EmptyAddButton from './components/empty-add-button';
import { EmptyStatesProps } from './props';
import './style.css';
import { mapInformationWithMenu } from './utils';
import Icons from './assets';

const EmptyStates = ({ menu, restaurantId, companyId }: EmptyStatesProps) => {
  //Attributes
  const history = useHistory();
  const { title, subTitle, link } = mapInformationWithMenu(
    menu,
    restaurantId,
    companyId
  );

  // Handler
  function renderIcon() {
    switch (menu) {
      case 'companies':
        return <Icons.CompaniesIcon />;
      case 'users':
        return <Icons.UsersIcon />;
      case 'employees':
        return <Icons.UsersIcon />;
      case 'restaurants':
        return <Icons.RestaurantsIcon />;
      case 'dishes':
        return <Icons.DishIcon />;
      case 'promos':
        return <Icons.PromoIcon />;
      default:
        return <Icons.DishIcon />;
    }
  }

  return (
    <div className='empty-states-container'>
      {renderIcon()}
      <div className='empty-states-header-title'>
        <Translation>{title}</Translation>
      </div>
      <div className='empty-states-header-subTitle'>
        <Translation>{subTitle}</Translation>
      </div>
      <EmptyAddButton title={title} handleOnClick={() => history.push(link)} />
    </div>
  );
};

export default EmptyStates;
