import React, { useState } from 'react';
import Translation from '../../../../components/translation';
import { ReactComponent as CheckedIcon } from './assets/check.svg';
import { ReactComponent as UnCheckedIcon } from './assets/uncheck.svg';
import { CheckboxSquareProps } from './props';
import './style.css';

const CheckboxSquare = ({
  title,
  handleOnCheck,
  value,
}: CheckboxSquareProps) => {
  // Handlers
  function handleOnClick() {
    handleOnCheck();
  }

  return (
    <div className='checkbox-square-container'>
      {value ? (
        <CheckedIcon onClick={handleOnClick} className='checkbox-square-icon' />
      ) : (
        <UnCheckedIcon
          onClick={handleOnClick}
          className='checkbox-square-icon'
        />
      )}
      <div className='checkbox-square-title'>
        <Translation>{title}</Translation>
      </div>
    </div>
  );
};

export default CheckboxSquare;
