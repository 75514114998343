import React, { useMemo, useRef, useState } from "react";
import { ReactComponent as PrinterIcon } from "../../../assets/print-big.svg";
import * as _ from "lodash";
import "./style.css";
import OrderLogService from "../../../../../../../../../services/OrderLogService";
import Modal from "react-modal";
import Popup from "../../../../../../../components/popup";
import PopupButton from "../../../../../../../../../components/popup-button";
import { customStyles } from "../../../../../../orders/modules/list/components/printable-order";
import animationData from "../../../assets/loading.json";
import Lottie from "react-lottie";
import { SelectedPrintButtonProps } from "./props";
import { OrderDTO } from "../../../../../../../../../dtos/go-api";
import { pdf } from "@react-pdf/renderer";
import printJS from "print-js";
import PrintTicket from "../../print-ticket";

const SelectedPrintButton = ({
  selectedOrderIds,
  allOrders,
  isEveryOrderSelected,
  allOrdersWithoutPaging,
  isAllOrdersWithoutPagingLoading,
}: SelectedPrintButtonProps) => {
  //Attributes
  const [printableOrders, setPrintableOrders] = useState<OrderDTO[]>([]);
  const [isPrintLoading, setIsPrintLoading] = useState(false);
  const [isOrdersUpdating, setIsOrdersUpdating] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const selectedOrders = useMemo(() => {
    if (isEveryOrderSelected) {
      return allOrdersWithoutPaging;
    }

    return allOrders?.filter((order) =>
      selectedOrderIds.includes(order?.id ?? "")
    );
  }, [
    allOrders,
    selectedOrderIds,
    isEveryOrderSelected,
    allOrdersWithoutPaging,
  ]);

  async function handlePrintJS() {
    const blob = await pdf(<PrintTicket orders={selectedOrders} />).toBlob();
    const blobURL = URL.createObjectURL(blob);
    printJS({
      printable: blobURL,
      onPrintDialogClose: () => {
        setIsModalOpen(true);
      },
    });
  }

  //Functions
  async function handleOrdersLogUpdate() {
    try {
      setIsOrdersUpdating(true);
      const updatePromises = printableOrders?.map(
        async (order) =>
          await OrderLogService.createOrderLog({
            orderId: order?.id ?? "",
            orderLogType: 0,
          })
      );

      await Promise.all(updatePromises);
    } catch (err) {
      console.error(err);
    } finally {
      setIsModalOpen(false);
      setIsOrdersUpdating(false);
      setIsPrintLoading(false);
    }
  }

  async function handleClick() {
    setIsPrintLoading(true);
    setPrintableOrders(selectedOrders);
    await handlePrintJS();
  }

  return (
    <>
      <div
        className={`multi-print-button-container ${
          selectedOrders?.length === 0 ? "disabled" : ""
        }`}
        onClick={selectedOrders?.length !== 0 ? handleClick : undefined}
        style={{ minWidth: 180, justifyContent: "center" }}
      >
        {isPrintLoading || !!isAllOrdersWithoutPagingLoading ? (
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: animationData,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            height={20}
            width={50}
          />
        ) : (
          <>
            <PrinterIcon />
            <div className="multi-print-button-title">{`Print selected (${selectedOrders?.length})`}</div>
          </>
        )}
      </div>

      <Modal ariaHideApp={false} style={customStyles} isOpen={isModalOpen}>
        <Popup
          title="didOrderPrint"
          icon={<PrinterIcon style={{ width: 66, height: 69 }} />}
          buttons={[
            <PopupButton
              backgroundColor="grey"
              handleClick={(event: any) => {
                setIsModalOpen(false);
                setIsPrintLoading(false);
                event.stopPropagation();
              }}
              title="NO"
            />,
            <PopupButton
              backgroundColor="yellow"
              handleClick={(e: any) => {
                handleOrdersLogUpdate();
                e.stopPropagation();
              }}
              title="YES"
              isLoading={isOrdersUpdating}
            />,
          ]}
        />
      </Modal>
    </>
  );
};

export default SelectedPrintButton;
