import { StyleSheet, Text, View } from "@react-pdf/renderer";
import React from "react";
import { PrintTicketHeaderProps } from "./props";

const styles = StyleSheet.create({
  headerMainContainer: {
    padding: "11 17 0 17",
  },

  headerFlexContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottom: "1 solid black",
    paddingBottom: 17,
  },
  headerMainTitle: {
    fontSize: 11,
    textDecoration: "underline",
    fontFamily: "Poppins",
    fontWeight: 600
  },

  headerSubTitle: {
    fontSize: 6,
    fontFamily: "Inter"
  },

  headerLeftFlexItem: {
    display: "flex",
    flexDirection: "column",
    fontSize: 6,
  },

  headerRightFlexItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    fontSize: 6,
  },
});

const PrintTicketHeader = ({ order }: PrintTicketHeaderProps) => {
  return (
    <View fixed style={styles.headerMainContainer}>
      <View style={styles.headerFlexContainer}>
        <View style={styles.headerLeftFlexItem}>
          <Text style={styles.headerMainTitle}>{order.companyName}</Text>
          <Text style={styles.headerSubTitle}>
            {order.companyAddress.streetName} {order.companyAddress.number},{" "}
            {order.companyAddress.zipCode}
          </Text>
          <Text style={styles.headerSubTitle}>
            {order.companyAddress.cityName}
          </Text>
        </View>
        <View style={styles.headerRightFlexItem}>
          <Text style={styles.headerMainTitle}>
            {order.userFirstName} {order.userLastName}
          </Text>
          <Text style={styles.headerSubTitle}>{order.userEmail}</Text>
          <Text style={styles.headerSubTitle}>{order.userPhoneNumber}</Text>
        </View>
      </View>
    </View>
  );
};

export default PrintTicketHeader;
