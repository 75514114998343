import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Translation from '../../../../../../../../../../components/translation';
import { ReactComponent as EditIcon } from './assets/edit.svg';
import InfoCard from './components/info-card';
import Icons from './assets'

import './style.css';
import { UserInterfaceSummary } from './props';
import { EmployeeType } from '../../../../../../../../../../dtos/employeeDTO';

const DetailsCard = ({ employee }: UserInterfaceSummary) => {

    // Attributes
    const { employeeId, companyId }: any = useParams()
    const history = useHistory()

    //Functions
    function renderUserRole() {
        switch (employee.employeeType) {
            case EmployeeType.ADMIN:
                return 'Administrator';
            case EmployeeType.EMPLOYEE:
                return 'Employee';
            case EmployeeType.SUPERIOR:
                return 'Superior';
            case EmployeeType.UNKNOWN:
                return '';
        }
    }

    return (
        <div className='user-summary-card-container'>
            <div className='user-summary-card-title-container'>
                <div className='user-summary-card-title'><Translation>Account summary</Translation></div>
                <EditIcon onClick={() => history.push(`/dashboard/companies/${companyId}/employees/${employeeId}/details`)} style={{ cursor: 'pointer' }} />
            </div>
            <div className='user-summary-card-details-container'>
                <div className='user-summary-card-details-group'>
                    <InfoCard icon={<Icons.UserIcon />} data={employee.user.firstName} title='FIRST NAME' />
                    <InfoCard icon={<Icons.UserIcon />} data={employee.user.lastName} title='LAST NAME' />
                </div>
                <div className='user-summary-card-details-group'>
                    <InfoCard icon={<Icons.MessageIcon />} data={employee.user.email} title='EMAIL ADDRESS' />
                    <InfoCard icon={<Icons.StarIcon />} data={renderUserRole()} title='USER TYPE' />
                </div>
            </div>
        </div>
    )
}

export default DetailsCard;