/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */

export type FileDeviceType = typeof FileDeviceType[keyof typeof FileDeviceType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FileDeviceType = {
  MOBILE: 'MOBILE',
  WEB: 'WEB',
} as const;
