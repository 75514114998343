import React from 'react'
import { TagProps } from './props'
import { ReactComponent as CloseIcon } from './assets/close.svg';
import './style.css';

const Tag = ({ name, handleDelete }: TagProps) => {



    return (
        <div className='tag-container'>
            <div className='tag-title'>{name}</div>
            <CloseIcon onClick={handleDelete} />
        </div>
    )
}

export default Tag;