import Axios from "axios";
import { CompanyResDTO, OrderTimingType } from "../../dtos/go-api";

const data: CompanyResDTO[] = [
    {
        address: {
            cityName: "Alexandrie",
            country: "Egypte",
            number: "23",
            streetName: "rue de petra",
            zipCode: "1640"
        },
        deliveryComments: "In front of the door",
        id: "0",
        name: "Proximus",
        vatNumber: "19457594939",
        logo: '',
        mobileLogo: '',
        orderTimingType: OrderTimingType.NOON,
        code: '',
        kitchenPriority: 1
    },
    {
        address: {
            cityName: "Alexandrie",
            country: "Egypte",
            number: "23",
            streetName: "rue de petra",
            zipCode: "1640"
        },
        deliveryComments: "In front of the door",
        id: "1",
        name: "Belgacom",
        vatNumber: "19457594939",
        logo: '',
        mobileLogo: '',
        orderTimingType: OrderTimingType.NOON,
        code: '',
        kitchenPriority: 1
    },
    {
        address: {
            cityName: "Alexandrie",
            country: "Egypte",
            number: "23",
            streetName: "rue de petra",
            zipCode: "1640"
        },
        deliveryComments: "In front of the door",
        id: "2",
        name: "Base",
        vatNumber: "19457594939",
        logo: '',
        mobileLogo: '',
        orderTimingType: OrderTimingType.NOON,
        code: '',
        kitchenPriority: 1
    },
    {
        address: {
            cityName: "Alexandrie",
            country: "Egypte",
            number: "23",
            streetName: "rue de petra",
            zipCode: "1640"
        },
        deliveryComments: "In front of the door",
        id: "3",
        name: "Orange",
        vatNumber: "19457594939",
        logo: '',
        mobileLogo: '',
        orderTimingType: OrderTimingType.NOON,
        code: '',
        kitchenPriority: 1
    },
    {
        address: {
            cityName: "Alexandrie",
            country: "Egypte",
            number: "23",
            streetName: "rue de petra",
            zipCode: "1640"
        },
        deliveryComments: "In front of the door",
        id: "4",
        name: "Telenet",
        vatNumber: "19457594939",
        logo: '',
        mobileLogo: '',
        orderTimingType: OrderTimingType.NOON,
        code: '',
        kitchenPriority: 1
    },

];

async function get(companyId?: string): Promise<any> {
    if (companyId) {
        return data.find(elt => elt.id == companyId);
    }
    return data;
}

async function post(CompanyResDTO: CompanyResDTO) {
    CompanyResDTO.id = data.length.toString();
    data.push(CompanyResDTO);
    return CompanyResDTO;
}

async function deleteCompany(companyId: string) {
    const index = data.findIndex(company => company.id === companyId)
    data.splice(index, 1)
    return data;
}

async function put(CompanyResDTO: CompanyResDTO) {
    const company = data.find(elt => elt.id === CompanyResDTO.id);

    if (company) {
        company.deliveryComments = CompanyResDTO.deliveryComments;
        company.name = CompanyResDTO.name;
        company.vatNumber = CompanyResDTO.vatNumber;
        company.address.number = CompanyResDTO.address.number;
        company.address.streetName = CompanyResDTO.address.streetName;
        company.address.zipCode = CompanyResDTO.address.zipCode;
        company.address.cityName = CompanyResDTO.address.cityName;
        company.address.country = CompanyResDTO.address.country;
        return CompanyResDTO;
    }

    throw ("company doesn't exist.");
}


export default { get, post, put, deleteCompany };