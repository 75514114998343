import { StyleSheet, Text, View } from "@react-pdf/renderer";
import React from "react";

import { PrintTicketBodyProps } from "./props";
import PrintTicketRestaurantCard from "./print-ticket-restaurant-card";

const styles = StyleSheet.create({
  ticketBody: {
    padding: "15 16 0 18",
  },

  bodyTitle: {
    fontSize: 11,
    fontFamily: "Poppins",
    fontWeight: 700,
  },
});

const PrintTicketBody = ({ itemsByRestaurants }: PrintTicketBodyProps) => {
  return (
    <View style={styles.ticketBody}>
      <View>
        <Text style={styles.bodyTitle}>Order information</Text>
      </View>
      <View>
        {Object.keys(itemsByRestaurants).map((restaurantName, index) => (
          <PrintTicketRestaurantCard
            key={index}
            restaurantName={restaurantName}
            items={itemsByRestaurants[restaurantName]}
          />
        ))}
      </View>
    </View>
  );
};

export default PrintTicketBody;
