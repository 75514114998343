import React from 'react';
import InputWithLabel from '../../../../../../../../../../components/input-with-label';
import GroupInputDetails from '../../../../../../../../components/group-input-details';
import { EMPLOYEE_FIELDS, UserActions } from '../../reducer';
import { UserTypeProps } from './props';
import InputSelect from '../../../../../../../../components/input-select';
import { EmployeeType } from '../../../../../../../../../../dtos/employeeDTO';

const UserTypeSelect = ({ type, dispatch }: UserTypeProps) => {

    // Attributes
    const options = [
        {
            value: EmployeeType.ADMIN, label: 'Admin',
        },
        {
            value: EmployeeType.SUPERIOR, label: 'Superior'
        },
        {
            value: EmployeeType.EMPLOYEE, label: 'Employee'
        }
    ]

    return (
        <GroupInputDetails title='User type'>
            <div style={{ marginTop: 40, marginBottom: 74, width: 278 }}>

                <InputWithLabel label='USER TYPE'>
                    <InputSelect options={options} initialValue={type} handleSelect={(value: any) => dispatch({ target: EMPLOYEE_FIELDS.EMPLOYEE_TYPE, payload: value, type: UserActions.EMPLOYEE_CHANGED })} />
                </InputWithLabel>
            </div>
        </GroupInputDetails>
    )
}

export default UserTypeSelect;