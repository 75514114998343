import React from 'react';
import InputText from '../../../../../../../../components/input-text';
import InputWithLabel from '../../../../../../../../components/input-with-label';
import { CommercialInformtionProps, COMPANY_FIELDS } from './props';
import { actions } from '../../reducer';
import GroupInputDetails from '../../../../../../components/group-input-details';
import InputGroup from '../../../../../../components/input-group';
import { useTranslation } from '../../../../../../../../components/translation-context/TranslationContext';
import './style.css';

const CommercialInformation = ({
  company,
  dispatch,
}: CommercialInformtionProps) => {
  // Attributes
  const { translate } = useTranslation();

  return (
    <InputGroup size='medium'>
      <GroupInputDetails title='Commercial information'>
        <InputGroup size='medium'>
          <InputWithLabel label='NAME OF THE COMPANY'>
            <InputText
              value={company.name}
              onChange={(event: any) =>
                dispatch({
                  type: actions.companyChanged,
                  target: COMPANY_FIELDS.NAME,
                  payload: event.target.value,
                })
              }
              placeholder='insert name of the company'
              required={true}
            />
          </InputWithLabel>
          <InputWithLabel label='VAT NUMBER'>
            {/* <InputText
                          value={company.vatNumber}
                          onChange={(event: any) => dispatch({ type: actions.companyChanged, target: COMPANY_FIELDS.VATNUMBER, payload: event.target.value })}
                          placeholder='insert VAT number'
                          required={true}

                      /> */}
            <div className='company-information-vat'>
              <div className='company-information-vat-be'>BE</div>
              <input
                className='company-information-vat-input'
                required={true}
                type='text'
                value={company.vatNumber}
                placeholder={translate('0645 917 753')}
                onChange={(event: any) =>
                  dispatch({
                    type: actions.companyChanged,
                    target: COMPANY_FIELDS.VATNUMBER,
                    payload: event.target.value,
                  })
                }
              />
            </div>
          </InputWithLabel>
        </InputGroup>
        <InputGroup>
          <InputWithLabel label='CODE OF THE COMPANY'>
            <InputText
              value={company.code}
              onChange={(event: any) =>
                dispatch({
                  type: actions.companyChanged,
                  target: COMPANY_FIELDS.CODE,
                  payload: event.target.value,
                })
              }
              placeholder='company code'
              required={true}
              style={{ fontWeight: 'bold' }}
            />
          </InputWithLabel>
        </InputGroup>
      </GroupInputDetails>
    </InputGroup>
  );
};

export default CommercialInformation;
