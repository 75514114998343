/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */

export type PromoUsageType = typeof PromoUsageType[keyof typeof PromoUsageType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PromoUsageType = {
  ONETIMEUSE: 'ONETIMEUSE',
  MULTIPLEUSE: 'MULTIPLEUSE',
  UNKNOWN: 'UNKNOWN',
} as const;
