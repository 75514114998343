import React from "react";
import BackgroundDetailsForm from "../../../../../../components/background-details-form";
import GroupInputDetails from "../../../../../../components/group-input-details";
import { PromoCodeInputProps } from "../props";
import DoubleCheckbox from "../double-checkbox";
import { PromoUsageType } from "../../../../../../../../dtos/go-api";

const PromoUsageTypes = ({ value, dispatch }: PromoCodeInputProps) => {
  // Handlers
  function handleCheck(label: string) {
    const value = mapWithValue(label);

    dispatch({
      type: "stateChanged",
      target: "promoUsageType",
      payload: value,
    });
  }

  function mapWithValue(data: any) {
    switch (data) {
      case "Unlimited usage":
        return PromoUsageType.MULTIPLEUSE;
      case "Limit to one use per customer":
        return PromoUsageType.ONETIMEUSE;
      case "Unkown":
        return PromoUsageType.UNKNOWN;
    }
  }

  function mapValueWithChoice(choice: any) {
    switch (choice) {
      case PromoUsageType.MULTIPLEUSE:
        return "Unlimited usage";
      case PromoUsageType.ONETIMEUSE:
        return "Limit to one use per customer";
      case PromoUsageType.UNKNOWN:
        return "Unkown";
    }
  }

  return (
    <BackgroundDetailsForm>
      <GroupInputDetails title="Usage limit">
        <DoubleCheckbox
          firstChoice="Limit to one use per customer"
          secondChoice="Unlimited usage"
          selectedChoice={mapValueWithChoice(value)}
          handleCheck={handleCheck}
        />
      </GroupInputDetails>
    </BackgroundDetailsForm>
  );
};

export default PromoUsageTypes;
