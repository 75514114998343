import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from '../../../../../../../../../../components/translation-context/TranslationContext';
import { useGetNotificationTemplates } from '../../../../../../../../../../services/go-api/notification-templates/notification-templates';
import InputSelect from '../../../../../../../../components/input-select';
import { OptionSelect } from '../../../../../../../../components/input-select/props';
import { NotificationByOrdersTemplateProps } from './props';
import './styles.css';

const NotificationByOrdersTemplate = ({
  changeDescription,
  changeTitle,
}: NotificationByOrdersTemplateProps) => {
  // Attributes
  const { translate } = useTranslation();
  const { data: templates, isLoading } = useGetNotificationTemplates();
  const [options, setOptions] = useState<OptionSelect[]>([]);
  const handleSelect = useCallback(
    (value: string) => {
      const template = templates?.data?.find((tmpl) => tmpl?.id === value);

      if (template) {
        // Update input field title and description
        changeDescription(template?.description ?? '');
        changeTitle(template?.title ?? '');
      }
    },
    [templates?.data, changeDescription, changeTitle]
  );

  // Effects
  useEffect(() => {
    if (templates?.data) {
      setOptions((prev) => {
        const newOptions: OptionSelect[] =
          templates?.data?.map((tmpl) => {
            return { label: tmpl?.name ?? '', value: tmpl?.id ?? '' };
          }) ?? [];
        return newOptions;
      });
    }
  }, [templates?.data]);

  // Handlers

  return (
    <div className='notification-dialog-template-container'>
      <div className='notification-dialog-template-title'>
        {translate('Select a template (optional)')}
      </div>
      <InputSelect options={options} handleSelect={handleSelect} />
    </div>
  );
};

export default NotificationByOrdersTemplate;
