import React, { useState } from 'react';
import BusyIndicator from '../../../../components/busy-indicator';
import useQueryString from '../../../../hooks/useQueryString';
import { useGetNumberOfOrders } from '../../../../services/go-api/order/order';
import CardGroup from '../../components/card-group';
import MenuListContainer from '../../components/menu-list-container';
import PageContainer from '../../components/page-container';
import { EnumFilterTypes } from '../new-orders/modules/list/types';
import CompanyOrdersCard from './modules/list/components/company-orders-card';
import EmptyStates from './modules/list/components/empty-states';
import CompanyOrdersFilters from './modules/list/components/filters';
import CompanyOrdersHeader from './modules/list/components/header';
import Modal from 'react-modal';
import Popup from '../../components/popup';
import PopupButton from '../../../../components/popup-button';
import { ReactComponent as PrinterIcon } from './modules/list/assets/print-big.svg';
import { useDateFilter } from '../../../../utils';
import { TimeScale } from '../../../../types';

const today = new Date();

const Statistics = () => {
	const timeframe = useQueryString().get(EnumFilterTypes.TIME_SCALE);

	const timeScale = timeframe as TimeScale;
	const [startDate, setStartDate] = useState<Date | null>(
		new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1)
	);
	const [endDate, setEndDate] = useState<Date | null>(new Date());
	const [isModalOpen, setIsModalOpen] = useState(false);

	const [currentPage, setCurrentPage] = useState<number>(0);
	const [currentTotalPages, setCurrentTotalPages] = useState<number>(0);
	const [search, setSearch] = useState<string>('');
	const [minDate, maxDate] = useDateFilter(timeScale, startDate, endDate);

	const query = useGetNumberOfOrders(
		{
			minDate: (minDate || today).toISOString(),
			maxDate: (maxDate || today).toISOString(),
			q: search.length > 1 ? search : '',
			page: currentPage,
			size: 1000,
		},
		{ query: { refetchOnWindowFocus: false } }
	);

	//Render
	return (
		<BusyIndicator query={query}>
			<PageContainer>
				<CompanyOrdersHeader
					timeScale={timeScale}
					startDate={startDate}
					setStartDate={setStartDate}
					endDate={endDate}
					setEndDate={setEndDate}
				/>
				<CompanyOrdersFilters
					page={currentPage}
					totalPages={currentTotalPages === 0 ? 1 : currentTotalPages}
					setPage={setCurrentPage}
					search={search}
					setSearch={setSearch}
					minDate={minDate}
					maxDate={maxDate}
					companies={query?.data?.data || []}
					setIsModalOpen={setIsModalOpen}
				/>
				{query?.data?.data && query?.data?.data.length > 0 ? (
					<MenuListContainer>
						<CardGroup>
							{/* sort by number of orders descending */}
							{query?.data?.data
								?.sort(
									(a, b) =>
										(b.numberOfOrders as number) - (a.numberOfOrders as number)
								)
								.map((company, index) => {
									return (
										<CompanyOrdersCard
											index={index}
											name={company.companyName}
											id={company.companyId}
											key={company.companyId}
											orders={company.numberOfOrders || 0}
										/>
									);
								})}
						</CardGroup>
					</MenuListContainer>
				) : (
					<EmptyStates />
				)}
				<Modal ariaHideApp={false} style={customStyles} isOpen={isModalOpen}>
					<Popup
						title='didOrderPrint'
						icon={<PrinterIcon style={{ width: 66, height: 69 }} />}
						buttons={[
							<PopupButton
								backgroundColor='grey'
								handleClick={(event: any) => {
									setIsModalOpen(false);
									event.stopPropagation();
								}}
								title='NO'
							/>,
							<PopupButton
								backgroundColor='yellow'
								handleClick={(e: any) => {
									e.stopPropagation();
								}}
								title='YES'
							/>,
						]}
					/>
				</Modal>
			</PageContainer>
		</BusyIndicator>
	);
};

export const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		padding: 50,
		border: 'none',
		borderRadius: 18,
		maxWidth: 509,
	},
	overlay: {
		backgroundColor: 'rgba(0,0,0,0.5)',
	},
};

export default Statistics;
