import Axios from "axios";

let errors: string[] = [];
let success: string[] = [];

function config() {
	const access_token = localStorage.getItem("ACCESS_TOKEN");
	return { headers: { Authorization: `bearer ${access_token}` } };
}

async function execute(service: Promise<any>) {
	try {
		return await service;
	} catch (e: any) {
		if (e && e?.response?.status === 401) {
			localStorage.clear();
			window.open(
				window.location.origin +
					"/sign-in?redirect=" +
					window.location.pathname,
				"_self"
			);
		} else {
			errors.push(e?.response?.data);
		}
	}
}

const IAM_URL = process.env.REACT_APP_IAM_SERVER;
const API_URL = process.env.REACT_APP_RESOURCE_SERVER;

const iam = Axios.create({ baseURL: IAM_URL });

export default { iam, errors, success, execute, config, IAM_URL, API_URL };
