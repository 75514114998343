// @ts-nocheck
export interface FieldForm<T> {
    name: keyof T;
    value: any;
    meta: MetaForm;
    onChange: (value: any) => void;
  }
  
  export interface MetaForm {
    touched: boolean;
    error?: string;
  }
  
  // Generates the generic form with the keys of the field used
  export function createForm<T>(
    values: T,
    rest: {
      touched;
      errors;
      setFieldValue;
      submitForm;
    },
  ): IForm<T> & IDefaultForm {
    // All keys of values
    const keys = Object.keys(values);
  
    // Destruct
    const { touched, errors, setFieldValue, submitForm } = rest;
  
    // Generate Form
    const form = { submitForm };
  
    keys.forEach((key) => {
      form[key] = createFormField(values, touched, errors, setFieldValue, key);
    });
  
    return form as IForm<T> & IDefaultForm;
  }
  
  // Generates inputFieldForms
  function createFormField<T>(
    values,
    touched,
    errors,
    setFieldValue,
    name,
  ): FieldForm<T> {
    // Generate field
    const field = {
      name: name,
      value: values[name],
      meta: {
        touched: touched[name],
        error: errors[name],
      },
      onChange: (value: unknown) => setFieldValue(name, value),
    };
  
    return field;
  }
  
  type IDefaultForm = {
    submitForm: () => void;
  };
  
  export type IForm<T extends {}> = IDefaultForm & {
    [key in keyof T]: FieldForm<T>;
  };
  