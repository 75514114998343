import React from "react";
import { EditActionMenuProps } from "./props";
import "./style.css";

const EditActionMenu = ({ rows }: EditActionMenuProps) => {
	// Render
	return (
		<div className="edit-action-menu-container">
			{rows?.map((row, index) => (
				<div
					className="edit-action-menu-row"
					key={`edit-action-menu-row-${index}`}
					onClick={row.onClick}
				>
					{row.icon && (
						<div className="edit-action-menu-icon-container">
							{<row.icon />}
						</div>
					)}
					<div className="edit-action-menu-text">{row.text}</div>
				</div>
			))}
		</div>
	);
};

export default EditActionMenu;
