import React from "react";
import { useHistory } from "react-router-dom";
import AddButton from "../../../../../../../../components/add-button";
import Pager from "../../../../../../../../components/pager";
import { NotificationType } from "../../../../../../../../dtos/go-api";
import Search from "../../../../../../components/search";
import { NotificationsFiltersProps } from "./props";
import NotificationsSelectDestinationType from "./select-destination-type";
import NotificationsSelectType from "./select-type";
import "./style.css";

const NotificationsFilters = ({
  page,
  totalPages,
  setPage,
  selectedType,
  setSelectedType,
  selectedDestinationType,
  setSelectedDestinationType,
  search,
  setSearch,
}: NotificationsFiltersProps) => {
  // Attributes
  const history = useHistory();

  // Render
  return (
    <div style={customStyles.container}>
      <div style={customStyles.row}>
        <div style={customStyles.filtersContainer}>
          <Search
            placeholder='Search'
            value={search}
            onChange={(value) => {
              setSearch(value);
              setPage && setPage(0);
            }}
            width='15.625rem'
            animation={false}
          />
          <NotificationsSelectType
            type={selectedType}
            setType={setSelectedType}
            destinationType={selectedDestinationType}
          />
        </div>
        <div style={customStyles.filtersRightContainer}>
          <Pager page={page} totalPages={totalPages} setPage={setPage} />
          <AddButton
            title='Add a new notification'
            handleClick={() => history.push(`/dashboard/notifications/details`)}
          />
        </div>
      </div>
      <div>
        {selectedType !== NotificationType.RECURRING ? (
          <NotificationsSelectDestinationType
            type={selectedDestinationType}
            setType={setSelectedDestinationType}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

const customStyles: any = {
  container: {
    margin: "2.875rem 2.5625rem 0 2.5625rem",
    display: "flex",
    gap: ".9375rem",
    flexDirection: "column",
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: "3.625rem",
  },
  filtersContainer: {
    display: "flex",
    height: "100%",
    gap: "2.25rem",
  },
  filtersRightContainer: {
    display: "flex",
    alignItems: "center",
    gap: "1.3125rem",
  },
};

export default NotificationsFilters;
