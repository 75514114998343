import React, { useState } from "react";
import { NotificationDestinationType } from "../../../../../../../../../dtos/go-api";
import Checked from "./assets/checked";
import Unchecked from "./assets/unchecked";
import DownArrow from "./assets/downArrow";
import { NotificationsSelectDestinationTypeProps } from "./props";
import "./style.css";
import { useTranslation } from "../../../../../../../../../components/translation-context/TranslationContext";

const NotificationsSelectDestinationType = ({
  type,
  setType,
}: NotificationsSelectDestinationTypeProps) => {
  const [showDropDown, setShowDropDown] = useState<boolean>(false);

  const { translate } = useTranslation()

  // Render
  return (
    <div className="notifications-select-destination-type-container">
      <div
        className="notifications-select-destination-type-show-select"
        onClick={() => setShowDropDown(!showDropDown)}><div className="notifications-select-destination-type-down-arrow">{`${translate('Show by')}: ${translate(type)}`}</div><DownArrow /></div>
      {showDropDown ?
        <div className="notifications-select-destination-type-content">
          {Object.values(NotificationDestinationType)?.map((t, index) => (
            <div
              className="notifications-select-destination-type-tab"
              key={`notifications-select-destination-type-${index}`}
            >
              <div
                style={customStyles.optionContainer}
                onClick={() => setType(t)}
              >
                <div
                  style={customStyles.optionIconContainer}
                >
                  {type === t ? (
                    <Checked
                      width="2.125rem"
                      height="2.125rem"
                    />
                  ) : (
                    <Unchecked
                      width="2.125rem"
                      height="2.125rem"
                    />
                  )}
                </div>
              </div>
              <div className="notifications-select-destination-type-tab-text">{t}</div>
              {t === type && (
                <div className="notifications-select-destination-type-tab-selected" />
              )}
            </div>
          ))}
        </div>
        : <></>}
    </div>
  );
};

const customStyles: any = {
  optionContainer: {
    display: "flex",
    alignItems: "center",
    gap: ".5rem",
    height: "2.125rem",
    cursor: "pointer",
  },
  optionIconContainer: {
    width: "2.125rem",
    height: "2.125rem",
  },
};

export default NotificationsSelectDestinationType;
