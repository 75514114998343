import React, { useState } from 'react';
import InputText from '../../../../../../../../../../components/input-text';
import PopupButton from '../../../../../../../../../../components/popup-button';
import Translation from '../../../../../../../../../../components/translation';
import { ReactComponent as IndexIcon } from './assets/index.svg'
import { IndexModalProps } from './props'
import './style.css'

const IndexModal = ({ closeModal, value, handleIndexChange }: IndexModalProps) => {

    // Attributes
    const [indexPrice, setIndexPrice] = useState(value)
    const isDisabled = indexPrice < 0 ? 'disabled' : ''

    // Handlers
    function handleChange(event: any) {
        setIndexPrice(event.target.value)
    }

    function handleClick() {
        if (!isDisabled) {
            //replace comma with dot then convert to a Number bcs indexPrice is of type Number
            const formatedIndexPrice = indexPrice.toString().replace(',', '.');
            handleIndexChange(Number(formatedIndexPrice));
        }
    }

    return (
        <div>
            <div className='index-modal-header'>
                <IndexIcon />
                <div className='index-modal-header-title'>
                    <Translation>Add an index price</Translation><br />
                    <Translation>for a specific restaurant</Translation>
                </div>
            </div>
            <InputText placeholder='ex: 80' onChange={handleChange} value={indexPrice.toString().replace('.', ',')} style={{ marginTop: 34 }} size='fill' />
            <div className='index-modal-button-group'>
                <PopupButton backgroundColor='grey' title='cancel' handleClick={closeModal} />
                <PopupButton disabled={isDisabled} backgroundColor='yellow' title='Apply' handleClick={handleClick} />
            </div>
        </div>
    )
}

export default IndexModal;