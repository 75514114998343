
export interface RestaurantDTO {
  id: string;
  name: string;
  pictureUrl: string;
  advertisementUrl?: string;
  bannerPictureUrl?: string;
  mobileBannerPictureUrl?: string;
  paperformUrl?: string;
  order?: number;
  restaurantType: RestaurantType;
  colorReference?: string ;
  hoverPictureUrl?: string;
}

export enum RestaurantType {
  UNKOWN = -1,
  B2C,
  B2B,
  BOTH,
}
