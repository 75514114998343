import React from "react";

const Unchecked = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg
			viewBox="0 0 34 34"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<rect
				x="9"
				y="9"
				width="17"
				height="17"
				rx="2.83333"
				stroke="#97A5C2"
				strokeWidth="2"
			/>
		</svg>
	);
};
export default Unchecked;
