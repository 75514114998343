import React from 'react';
import './style.css';

const MenuContainer = React.forwardRef((props: any, ref: any) => {
	return (
		<div ref={ref} className='menu-container'>
			{props.children}
		</div>
	);
});

export default MenuContainer;
