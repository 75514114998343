import React from "react";
import ReactDOM from "react-dom";

const Tooltip = ({ children, position }: any) => {
  return ReactDOM.createPortal(
    <div
      style={{
        position: "fixed",
        top: position.top ?? 0,
        left: 25,
        zIndex: 1000,
      }}
    >
      {children}
    </div>,
    document.body
  );
};

export default Tooltip;
