/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */

export type NotificationDay = typeof NotificationDay[keyof typeof NotificationDay];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NotificationDay = {
  MONDAY: 'MONDAY',
  TUESDAY: 'TUESDAY',
  WEDNESDAY: 'WEDNESDAY',
  THURSDAY: 'THURSDAY',
  FRIDAY: 'FRIDAY',
  SATURDAY: 'SATURDAY',
  SUNDAY: 'SUNDAY',
} as const;
