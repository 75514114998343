import React, { useMemo, useRef, useState } from "react";
import useOnClickOutside from "../../../../../../../../../hooks/useOnClickOutside";
import Checked from "../../assets/checked";
import ChevronDown from "../../assets/chevron-down";
import Search from "../../assets/search";
import Unchecked from "../../assets/unchecked";
import { MultiSelectSearchProps } from "./props";
import "./style.css";

const MultiSelectSearch = ({
	name,
	value,
	onChange,
	options,
	placeholder,
	searchPlaceholder,
	search,
	setSearch,
	enlarge,
}: MultiSelectSearchProps) => {
	// Attributes
	const [isDroppedDown, setIsDroppedDown] = useState<boolean>(false);
	const selectedValueLabels = options
		?.filter((option) => value?.includes(option.value))
		.map((option) => option.label);
	const ref = useRef<HTMLDivElement>(null);
	useOnClickOutside(ref, () => {
		setSearch && setSearch("");
		setIsDroppedDown(false);
	});
	const sortedOptions = useMemo(
		() =>
			options?.sort((option1, option2) => {
				const isOptionSelected1 = value?.includes(option1.value);
				const isOptionSelected2 = value?.includes(option2.value);

				if (
					(isOptionSelected1 && isOptionSelected2) ||
					(!isOptionSelected1 && !isOptionSelected2)
				) {
					return option1.label < option2.label ? -1 : 1;
				}
				return isOptionSelected1 ? -1 : 1;
			}),
		[options, value]
	);

	// Render
	return (
		<div style={enlarge ? customStyles.largeContainer : customStyles.container} ref={ref}>
			<div
				style={customStyles.inputContainer}
				onClick={() => setIsDroppedDown((prev) => !prev)}
			>
				{selectedValueLabels && selectedValueLabels?.length > 0 ? (
					<div style={customStyles.inputTextContainer}>
						<span style={customStyles.inputText}>
							{selectedValueLabels[0]}
						</span>
						{selectedValueLabels.length > 1 && (
							<span style={customStyles.inputTextIndicator}>{`+${selectedValueLabels.length - 1
								}`}</span>
						)}
					</div>
				) : (
					<span style={customStyles.inputTextPlaceholder}>
						{placeholder}
					</span>
				)}

				<div style={customStyles.iconContainer}>
					<ChevronDown
						width="1.5rem"
						height="1.5rem"
						stroke="#1E1F20"
					/>
				</div>
			</div>
			{isDroppedDown && (
				<div style={customStyles.optionsContainer}>
					<div style={customStyles.searchContainer}>
						<Search
							width=".6875rem"
							height=".6875rem"
							fill="#C8CACD"
						/>
						<input
							className="input-placeholder"
							style={customStyles.searchInput}
							placeholder={searchPlaceholder}
							value={search}
							onChange={(e) =>
								setSearch && setSearch(e.currentTarget.value)
							}
						/>
					</div>
					<div style={customStyles.divider} />
					<div style={customStyles.optionsColumn}>
						{sortedOptions?.map((option) => {
							const isChecked = !!value?.find(
								(v) => v === option.value
							);
							return (
								<div
									style={customStyles.optionContainer}
									key={`${name}_option_${option.value}`}
									onClick={() =>
										onChange && onChange(option.value)
									}
								>
									<div
										style={customStyles.optionIconContainer}
									>
										{isChecked ? (
											<Checked
												width="2.125rem"
												height="2.125rem"
											/>
										) : (
											<Unchecked
												width="2.125rem"
												height="2.125rem"
											/>
										)}
									</div>
									<div style={customStyles.optionText}>
										{option.label}
									</div>
								</div>
							);
						})}
					</div>
				</div>
			)}
		</div>
	);
};

const customStyles: any = {
	container: {
		position: "relative",
		width: "11.25rem",
	},
	largeContainer: {
		position: "relative",
		width: "21.25rem",
	},
	inputContainer: {
		width: "100%",
		height: "3.625rem",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		padding: "0 1rem 0 1.5rem",
		borderRadius: "1.125rem",
		backgroundColor: "white",
		cursor: "pointer",
		boxSizing: "border-box",
		boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.04)",
	},
	inputTextContainer: {
		display: "flex",
		alignItems: "center",
		gap: ".5rem",
		maxWidth: "7.5rem",
	},
	inputText: {
		color: "#1E1F20",
		fontWeight: "600",
		fontSize: ".75rem",
		lineHeight: "1.375rem",
		whiteSpace: "nowrap",
		overflowX: "auto",
	},
	inputTextIndicator: {
		color: "#F5C755",
		fontWeight: "600",
		fontSize: ".75rem",
	},
	inputTextPlaceholder: {
		color: "#7F85A2",
		fontWeight: "600",
		fontSize: ".75rem",
		lineHeight: "1.375rem",
		whiteSpace: "nowrap",
		overflowX: "auto",
	},
	iconContainer: {
		width: "1.5rem",
		height: "1.5rem",
	},
	optionsContainer: {
		width: "100%",
		display: "flex",
		flexDirection: "column",
		borderRadius: "1.125rem",
		backgroundColor: "white",
		border: "1px solid #EEEEEE",
		position: "absolute",
		top: "4.125rem",
		zIndex: 15,
		boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.04)",
	},
	searchContainer: {
		display: "flex",
		alignItems: "center",
		gap: ".625rem",
		padding: ".875rem 1.25rem 0 1.25rem",
	},
	searchInput: {
		color: "#C8CACD",
		fontSize: ".75rem",
		fontWeight: "500",
		lineHeight: "1.25rem",
		border: "0",
		outline: "none",
		width: "100%",
	},
	divider: {
		width: "100%",
		height: ".0625rem",
		backgroundColor: "rgba(246, 246, 248, 0.8)",
		marginTop: ".5625rem",
		marginBottom: ".4375rem",
	},
	optionsColumn: {
		display: "flex",
		flexDirection: "column",
		gap: ".3125rem",
		margin: "0 .8125rem 1.0625rem .8125rem",
		maxHeight: "14.3125rem",
		overflowY: "auto",
	},
	optionContainer: {
		display: "flex",
		alignItems: "center",
		gap: ".5rem",
		height: "2.125rem",
		cursor: "pointer",
	},
	optionText: {
		color: "#061A42",
		fontSize: ".875rem",
		fontWeight: "600",
		lineHeight: "1.375rem",
	},
	optionIconContainer: {
		width: "2.125rem",
		height: "2.125rem",
	},
};

export default MultiSelectSearch;
