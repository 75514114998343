import React, { } from 'react';
import { useTranslation } from '../../../../../../../../components/translation-context/TranslationContext';
import './style.css';
import 'react-datepicker/dist/react-datepicker.css';
import { CompanyOrdersHeaderProps } from './props';
import { TimeScale } from '../../../../../../../../types';
import DateFilter from '../../../../../../components/date-filter';

const CompanyOrdersHeader = ({
  timeScale,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}: CompanyOrdersHeaderProps) => {
  // Attributes
  const { translate } = useTranslation();

  return (
    <div className='orders-header-container'>
      <div className='orders-header-title'>
        <div>{translate('Statistics')}</div>
      </div>
      <div className='orders-time-scale-container'>
        {Object.values(TimeScale).map((time) => (
          <DateFilter
            selected={time === timeScale}
            time={time}
            endDate={endDate}
            startDate={startDate}
            setEndDate={setEndDate}
            setStartDate={setStartDate}
            url={'/dashboard/statistics'}
          />
        ))}
      </div>
    </div>
  );
};

export default CompanyOrdersHeader;
