import {
  FoodizPaymentMethod,
  NotificationDeliveryType,
} from "../../../../../../../../dtos/go-api";
import { OrderContent } from "../../types";

export interface NotificationByOrdersDialogProps {
  isEveryOrderSelected: boolean;
  isOpen: boolean;
  onClose: () => void;
  q: string,
  minDate: string | undefined,
  maxDate: string | undefined,
  selectedPaymentTypes: FoodizPaymentMethod[];
  selectedProducts: string[];
  selectedRestaurants: string[];
  selectedCompanies: string[];
  selectedOrders: string[];
  selectedContentOrders: OrderContent[];
}

export enum FiltersName {
  companies = "companies",
  restaurants = "restaurants",
  products = "products",
  paymentTypes = "paymentTypes",
}

export interface NotificationFormFormik {
  title: string;
  description: string;
  deliveryType: NotificationDeliveryType;
  publicationDay: string;
  publicationTime: string;
}
