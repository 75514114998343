import { useMemo } from "react";
import { IUploadDocument } from "../services/FileService";
import { TimeScale } from "../types";

/** Replaces all non-word characters except `.` */
export const NUMBER_NORMALIZER_REGEX = /[^\w.]/g; //;

/* Takes in a `string` or `number` and formats into a human readable string.
 * @param {string | number} x The number to format
 */
export function numberWithCommas(
	x: string | number,
	options: {
		suffix?: string;
		stripDecimals?: boolean;
	} = {}
): string {
	if (!("Intl" in window)) {
		return x.toString();
	}

	const parts = Intl.NumberFormat("en-US").formatToParts(Number(x));
	const decimalPart = parts.find(p => p.type === 'decimal');
	const fractionPart = parts.find(p => p.type === 'fraction');

	if (!options.stripDecimals) {
		if (!decimalPart) {
			parts.push({ type: 'decimal', value: getDecimalCharacter("en-US") });
		}

		if (!fractionPart) {
			parts.push({ type: "fraction", value: "00" });
		} else if (fractionPart.value.length !== 2) {
			parts[parts.length - 1].value = parts[parts.length - 1].value.slice(0, 2);
		}
	}

	return `${parts.map((p) => p.value).join("")}${options.suffix || ""}`;
}

/**
 * Retrieves the decimal character for the provided locale,
 * defaults to the current one.
 * @param {string} [locale] The locale to use.
 */
export function getDecimalCharacter(locale?: string): string {
	return Intl.NumberFormat(locale)
		.formatToParts(1.01)
		.find((p) => p.type === "decimal")
		?.value || ".";
}

/**
 * 
 * @param file 
 * @returns fileBase64
 */

const toBase64 = (file: File) => new Promise<string>((resolve, reject) => {
	const reader = new FileReader();
	reader.readAsDataURL(file);
	reader.onload = () => resolve(reader.result as string);
	reader.onerror = error => reject(error);
});

export async function handleOnDrop(files: File[], uploadDocument: (dto: IUploadDocument) => void) {
	// extension
	const extensionIndex = files[0].name.lastIndexOf('.');

	// resize file
	const encodedFile = await resizeFile(files[0]);

	// pictureDTO
	const pictureDTO = {
		id: '',
		creationDate: '',
		modificationDate: '',
		file: encodedFile.split(',')[1],
		fileName: files[0].name,
		fileExtension: files[0].name.substr(extensionIndex),
		fileURL: '',
	}
	uploadDocument(pictureDTO)
}

export function resizeFile(file: any) {
	return new Promise<string>(resolve => {
		toBase64(file).then(result => resolve(result))
	});
}

export const downloadCSV = (
	keys: string[],
	headers: string[],
	flattenedObjectList: any[],
	fileName: string,
) => {
	let csvContent = '';

	const rows = [[...headers]];

	flattenedObjectList.forEach((list) => {
		const arr: any = [];
		keys.forEach((key) => arr.push(list[key]));
		rows.push(arr);
	});

	rows.forEach((rowArray) => {
		const row = rowArray.join(';');
		csvContent += row + '\r\n';
	});

	const blob = new Blob([`\uFEFF${csvContent}`], {
		type: 'text/csv;charset=utf-8;',
	});

	const link = document.createElement('a');
	link.download = `${fileName}.csv`;
	link.href = `${URL.createObjectURL(blob)}`;
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};

export const flattenObject = (obj: any) => {
	const flattened: any = {};

	Object.keys(obj).forEach((key) => {
		if (typeof obj[key] === 'object' && obj[key] !== null) {
			Object.assign(flattened, flattenObject(obj[key]));
		} else {
			flattened[key] = obj[key];
		}
	});

	return flattened;
};

export function useDateFilter(timeScale: TimeScale, startDate: Date| null, endDate?: Date | null): (Date | undefined | null)[]{
	return useMemo(() => {
		const now = new Date();
		switch (timeScale) {
		  case TimeScale.Hour: {
			const min = new Date(now);
			min.setHours(now.getHours() - 1);
			return [min, now];
		  }
		  case TimeScale.Day: {
			const min = new Date(
			  now.getFullYear(),
			  now.getMonth(),
			  now.getDate() - 1,
			  14,
			  0,
			  0
			);
			const max = new Date(
			  now.getFullYear(),
			  now.getMonth(),
			  now.getDate(),
			  23,
			  59,
			  59
			);
			return [min, max];
		  }
		  case TimeScale.Week: {
			const min = new Date(
			  now.getFullYear(),
			  now.getMonth(),
			  now.getDate() - now.getDay() + 1,
			  0,
			  0,
			  0
			);
			const max = new Date(
			  now.getFullYear(),
			  now.getMonth(),
			  min.getDate() + 6,
			  23,
			  59,
			  59
			);
			return [min, max];
		  }
		  case TimeScale.Month: {
			const min = new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0);
			const max = new Date(
			  now.getFullYear(),
			  now.getMonth() + 1,
			  0,
			  23,
			  59,
			  59
			);
			return [min, max];
		  }
		  case TimeScale.Pick: {
			// Check only one date is selected
			let maxDate;
			if (!endDate) {
			  // Get end of the start date
			  if (startDate) {
				maxDate = new Date(
				  startDate.getFullYear(),
				  startDate.getMonth(),
				  startDate.getDate(),
				  23,
				  59,
				  59
				);
			  }
			} else {
			  maxDate = new Date(
				endDate.getFullYear(),
				endDate.getMonth(),
				endDate.getDate(),
				23,
				59,
				59
			  );
			}
	
			return [startDate, maxDate];
		  }
		  default: {
			return [undefined, undefined];
		  }
		}
	}, [timeScale, startDate, endDate])
}