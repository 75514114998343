import "./style.css";
import Modal from "react-modal";
import { useHistory, useParams } from "react-router-dom";
import React, { useEffect, useReducer, useState } from "react";
import { ReactComponent as DeleteIcon } from "../../../../../../../assets/delete-icon.svg";
import { useTranslation } from "../../../../../../../components/translation-context/TranslationContext";
import { usePopupContext } from "../../../../../components/popup-context/context";
import {
  useCreateNotificationTemplate,
  useDeleteNotificationTemplateById,
  useGetNotificationTemplateById,
  useGetNotificationTemplates,
  useUpdateNotificationTemplateById,
} from "../../../../../../../services/go-api/notification-templates/notification-templates";
import { initialNotificationTemplate, reducer } from "./reducer";
import { EnumPopupIndex } from "../../../../../components/popup-context/props";
import Service from "../../../../../../../services/Service";
import BusyIndicator from "../../../../../../../components/busy-indicator";
import PageContainer from "../../../../../components/page-container";
import Header from "../../../../../../../components/header";
import SaveButton from "../../../../../../../components/header/components/save-button";
import { SAVE_BUTTON_TYPE } from "../../../../../../../components/header/components/save-button/props";
import MenuContainer from "../../../../../components/menu-container";
import Form from "../../../../../components/form";
import Popup from "../../../../../components/popup";
import PopupButton from "../../../../../../../components/popup-button";
import { customStyles } from "../../../../../components/popup/customStyle";
import InputTemplateName from "./components/template-name";
import TemplateTitleSection from "./components/template-title";
import { NotificationTemplateDTO } from "../../../../../../../dtos/go-api/notificationTemplateDTO";

const TemplateDetail = () => {
  /**
   * Attributes
   */
  const history = useHistory();
  const { templateId }: any = useParams();
  const { translate } = useTranslation();
  const { showPopup } = usePopupContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [template, dispatch] = useReducer(reducer, initialNotificationTemplate);

  const notificationTemplate = useGetNotificationTemplateById(templateId);

  const { mutateAsync: createNotificationTemplate } =
    useCreateNotificationTemplate();
  const { mutateAsync: updateNotificationTemplate } =
    useUpdateNotificationTemplateById(templateId);
  const { mutateAsync: deleteNotificationTemplate } =
    useDeleteNotificationTemplateById(templateId);
  const { refetch: refetchAllTemplates } = useGetNotificationTemplates();

  /**
   * Effects
   */
  useEffect(() => {
    if (notificationTemplate.data) {
      dispatch({
        type: "serverChanged",
        target: "self",
        payload: notificationTemplate.data,
      });
    }
  }, [notificationTemplate.data]);

  /**
   * Handlers
   */
  async function handleSave() {
    for (const attribute in template) {
      if (attribute === "name" || attribute === "title") {
        if (!template[attribute] || template[attribute].length === 0) {
          showPopup(EnumPopupIndex.MANDATORY);
          return;
        }
      }
      if (template[attribute] === -1) {
        showPopup(EnumPopupIndex.MANDATORY);
        return;
      }
    }

    if (templateId) {
      updateNotificationTemplate(
        {
          templateId: templateId,
          data: {
            name: template.name,
            title: template.title,
            description: template.description,
          },
        },
        {
          onSuccess: async () => {
            await refetchAllTemplates();
            Service.success.push(
              translate("modif_notificationTemplate_success")
            );
            history.push("/dashboard/notifications/templates");
          },
        }
      );
    } else {
      // Create dto
      const data: NotificationTemplateDTO = {
        name: template.name,
        title: template.title,
        description: template.description,
      };

      createNotificationTemplate(
        {
          data,
        },
        {
          onSuccess: async () => {
            await refetchAllTemplates();
            Service.success.push(translate("add_notificationTemplate_success"));
            history.push("/dashboard/notifications/templates");
          },
        }
      );
    }
  }

  async function handleDelete() {
    await deleteNotificationTemplate(
      { templateId: templateId },
      {
        onSuccess: async () => {
          await refetchAllTemplates();
          Service.success.push(
            translate("delete_notificationTemplate_success")
          );
          history.push(`/dashboard/notifications/templates`);
        },
      }
    );
  }

  return (
    <BusyIndicator query={notificationTemplate}>
      <PageContainer>
        <Header
          title={
            notificationTemplate.data && templateId
              ? template.name
              : "Add new template"
          }
          hasBackButton={true}
          saveButton={
            <SaveButton
              handleClick={handleSave}
              type={
                templateId
                  ? SAVE_BUTTON_TYPE.SAVE
                  : SAVE_BUTTON_TYPE.SAVE_AND_ADD
              }
              handleDelete={() => setIsModalOpen(true)}
            />
          }
        />
        <MenuContainer>
          <Form>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              {/* LEFT SECTION */}
              <div
                style={{
                  width: "100%",
                  marginRight: "30px",
                }}
              >
                <InputTemplateName
                  value={template.name}
                  onChange={(event: any) =>
                    dispatch({
                      type: "stateChanged",
                      target: "name",
                      payload: event.target.value,
                    })
                  }
                />
              </div>

              {/* RIGHT SECTION */}
              <div
                style={{
                  width: "100%",
                }}
              >
                <TemplateTitleSection template={template} dispatch={dispatch} />
              </div>
            </div>
          </Form>
        </MenuContainer>
        <Modal style={customStyles} isOpen={isModalOpen}>
          <Popup
            title="Are you sure you want to delete this notification template?"
            icon={<DeleteIcon style={{ width: 66, height: 69 }} />}
            buttons={[
              <PopupButton
                backgroundColor="grey"
                handleClick={() => setIsModalOpen(false)}
                title="Cancel"
              />,
              <PopupButton
                backgroundColor="yellow"
                handleClick={handleDelete}
                title="Yes, delete"
              />,
            ]}
          />
        </Modal>
      </PageContainer>
    </BusyIndicator>
  );
};

export default TemplateDetail;
