import React from 'react';
import { InputPriceProps } from './props';
import './style.css';


const InputPrice = ({ value, onChange, placeholder, style, size, required, removeZero, type }: InputPriceProps) => {

    // Handlers
    function handleFocus() {
        if (removeZero && value === 0) {
            removeZero()
        }
    }

    return (
        <div className={`input-price-container ${size}`}>
            <input onFocus={handleFocus} required={required} type="number" min="0.0" className={`input-price-input`} value={value} onChange={onChange} placeholder={placeholder} style={{ ...style }} />
            <div className="input-price-currency">{type === 'PERCENTAGE' ? '%' : 'EUR'}</div>
        </div>
    )
}

export default InputPrice;