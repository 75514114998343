/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation
} from 'react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  CompanyOrdersPagingResDTO,
  GetNumberOfOrdersParams,
  OrderDetailDTO,
  OrderResDTO,
  OrderReqUpdateDTO,
  OrderReqCreateDTO,
  OrderPagingDTO,
  GetOrdersParams,
  OrdersCountResponseDTO,
  GetUnprintedOrdersCountParams,
  OrderReportDTO,
  GetOrdersReportParams
} from '../../../dtos/go-api'
import { customInstance } from '.././config';

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


export const getNumberOfOrders = (
    params: GetNumberOfOrdersParams,
 signal?: AbortSignal
) => {
      return customInstance<CompanyOrdersPagingResDTO>(
      {url: `/v1/api/companies/number-orders`, method: 'get',
        params, signal
    },
      );
    }
  

export const getGetNumberOfOrdersQueryKey = (params: GetNumberOfOrdersParams,) => [`/v1/api/companies/number-orders`, ...(params ? [params]: [])];

    
export type GetNumberOfOrdersQueryResult = NonNullable<Awaited<ReturnType<typeof getNumberOfOrders>>>
export type GetNumberOfOrdersQueryError = unknown

export const useGetNumberOfOrders = <TData = Awaited<ReturnType<typeof getNumberOfOrders>>, TError = unknown>(
 params: GetNumberOfOrdersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getNumberOfOrders>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetNumberOfOrdersQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getNumberOfOrders>>> = ({ signal }) => getNumberOfOrders(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getNumberOfOrders>>, TError, TData>({ queryKey, queryFn, ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export const getOrderDetailByOrderId = (
    orderId: string,
 signal?: AbortSignal
) => {
      return customInstance<OrderDetailDTO>(
      {url: `/v1/api/orders/${orderId}`, method: 'get', signal
    },
      );
    }
  

export const getGetOrderDetailByOrderIdQueryKey = (orderId: string,) => [`/v1/api/orders/${orderId}`];

    
export type GetOrderDetailByOrderIdQueryResult = NonNullable<Awaited<ReturnType<typeof getOrderDetailByOrderId>>>
export type GetOrderDetailByOrderIdQueryError = unknown

export const useGetOrderDetailByOrderId = <TData = Awaited<ReturnType<typeof getOrderDetailByOrderId>>, TError = unknown>(
 orderId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOrderDetailByOrderId>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrderDetailByOrderIdQueryKey(orderId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrderDetailByOrderId>>> = ({ signal }) => getOrderDetailByOrderId(orderId, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getOrderDetailByOrderId>>, TError, TData>({ queryKey, queryFn, enabled: !!(orderId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export const getOrderById = (
    employeeId: string,
    orderId: string,
 signal?: AbortSignal
) => {
      return customInstance<OrderResDTO>(
      {url: `/v1/api/employees/${employeeId}/orders/${orderId}`, method: 'get', signal
    },
      );
    }
  

export const getGetOrderByIdQueryKey = (employeeId: string,
    orderId: string,) => [`/v1/api/employees/${employeeId}/orders/${orderId}`];

    
export type GetOrderByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getOrderById>>>
export type GetOrderByIdQueryError = unknown

export const useGetOrderById = <TData = Awaited<ReturnType<typeof getOrderById>>, TError = unknown>(
 employeeId: string,
    orderId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOrderById>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrderByIdQueryKey(employeeId,orderId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrderById>>> = ({ signal }) => getOrderById(employeeId,orderId, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getOrderById>>, TError, TData>({ queryKey, queryFn, enabled: !!(employeeId && orderId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export const updateOrderById = (
    employeeId: string,
    orderId: string,
    orderReqUpdateDTO: OrderReqUpdateDTO,
 ) => {
      return customInstance<OrderResDTO>(
      {url: `/v1/api/employees/${employeeId}/orders/${orderId}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: orderReqUpdateDTO
    },
      );
    }
  


    export type UpdateOrderByIdMutationResult = NonNullable<Awaited<ReturnType<typeof updateOrderById>>>
    export type UpdateOrderByIdMutationBody = OrderReqUpdateDTO
    export type UpdateOrderByIdMutationError = unknown

    export const useUpdateOrderById = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateOrderById>>, TError,{employeeId: string;orderId: string;data: OrderReqUpdateDTO}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateOrderById>>, {employeeId: string;orderId: string;data: OrderReqUpdateDTO}> = (props) => {
          const {employeeId,orderId,data} = props ?? {};

          return  updateOrderById(employeeId,orderId,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof updateOrderById>>, TError, {employeeId: string;orderId: string;data: OrderReqUpdateDTO}, TContext>(mutationFn, mutationOptions);
    }
    export const getCurrentOrder = (
    employeeId: string,
 signal?: AbortSignal
) => {
      return customInstance<OrderResDTO>(
      {url: `/v1/api/employees/${employeeId}/current-order`, method: 'get', signal
    },
      );
    }
  

export const getGetCurrentOrderQueryKey = (employeeId: string,) => [`/v1/api/employees/${employeeId}/current-order`];

    
export type GetCurrentOrderQueryResult = NonNullable<Awaited<ReturnType<typeof getCurrentOrder>>>
export type GetCurrentOrderQueryError = unknown

export const useGetCurrentOrder = <TData = Awaited<ReturnType<typeof getCurrentOrder>>, TError = unknown>(
 employeeId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCurrentOrder>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCurrentOrderQueryKey(employeeId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCurrentOrder>>> = ({ signal }) => getCurrentOrder(employeeId, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getCurrentOrder>>, TError, TData>({ queryKey, queryFn, enabled: !!(employeeId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export const getEmployeeOrders = (
    employeeId: string,
 signal?: AbortSignal
) => {
      return customInstance<OrderResDTO[]>(
      {url: `/v1/api/employees/${employeeId}/orders`, method: 'get', signal
    },
      );
    }
  

export const getGetEmployeeOrdersQueryKey = (employeeId: string,) => [`/v1/api/employees/${employeeId}/orders`];

    
export type GetEmployeeOrdersQueryResult = NonNullable<Awaited<ReturnType<typeof getEmployeeOrders>>>
export type GetEmployeeOrdersQueryError = unknown

export const useGetEmployeeOrders = <TData = Awaited<ReturnType<typeof getEmployeeOrders>>, TError = unknown>(
 employeeId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getEmployeeOrders>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEmployeeOrdersQueryKey(employeeId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getEmployeeOrders>>> = ({ signal }) => getEmployeeOrders(employeeId, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getEmployeeOrders>>, TError, TData>({ queryKey, queryFn, enabled: !!(employeeId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export const createOrder = (
    employeeId: string,
    orderReqCreateDTO: OrderReqCreateDTO,
 ) => {
      return customInstance<OrderResDTO>(
      {url: `/v1/api/employees/${employeeId}/orders`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: orderReqCreateDTO
    },
      );
    }
  


    export type CreateOrderMutationResult = NonNullable<Awaited<ReturnType<typeof createOrder>>>
    export type CreateOrderMutationBody = OrderReqCreateDTO
    export type CreateOrderMutationError = unknown

    export const useCreateOrder = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createOrder>>, TError,{employeeId: string;data: OrderReqCreateDTO}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createOrder>>, {employeeId: string;data: OrderReqCreateDTO}> = (props) => {
          const {employeeId,data} = props ?? {};

          return  createOrder(employeeId,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof createOrder>>, TError, {employeeId: string;data: OrderReqCreateDTO}, TContext>(mutationFn, mutationOptions);
    }
    export const getOrders = (
    params?: GetOrdersParams,
 signal?: AbortSignal
) => {
      return customInstance<OrderPagingDTO>(
      {url: `/v1/api/orders`, method: 'get',
        params, signal
    },
      );
    }
  

export const getGetOrdersQueryKey = (params?: GetOrdersParams,) => [`/v1/api/orders`, ...(params ? [params]: [])];

    
export type GetOrdersQueryResult = NonNullable<Awaited<ReturnType<typeof getOrders>>>
export type GetOrdersQueryError = unknown

export const useGetOrders = <TData = Awaited<ReturnType<typeof getOrders>>, TError = unknown>(
 params?: GetOrdersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOrders>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrdersQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrders>>> = ({ signal }) => getOrders(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getOrders>>, TError, TData>({ queryKey, queryFn, ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export const getUnprintedOrdersCount = (
    params?: GetUnprintedOrdersCountParams,
 signal?: AbortSignal
) => {
      return customInstance<OrdersCountResponseDTO>(
      {url: `/v1/api/orders/count`, method: 'get',
        params, signal
    },
      );
    }
  

export const getGetUnprintedOrdersCountQueryKey = (params?: GetUnprintedOrdersCountParams,) => [`/v1/api/orders/count`, ...(params ? [params]: [])];

    
export type GetUnprintedOrdersCountQueryResult = NonNullable<Awaited<ReturnType<typeof getUnprintedOrdersCount>>>
export type GetUnprintedOrdersCountQueryError = unknown

export const useGetUnprintedOrdersCount = <TData = Awaited<ReturnType<typeof getUnprintedOrdersCount>>, TError = unknown>(
 params?: GetUnprintedOrdersCountParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUnprintedOrdersCount>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUnprintedOrdersCountQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUnprintedOrdersCount>>> = ({ signal }) => getUnprintedOrdersCount(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getUnprintedOrdersCount>>, TError, TData>({ queryKey, queryFn, ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export const getOrdersReport = (
    params?: GetOrdersReportParams,
 signal?: AbortSignal
) => {
      return customInstance<OrderReportDTO[]>(
      {url: `/v1/api/orders-report`, method: 'get',
        params, signal
    },
      );
    }
  

export const getGetOrdersReportQueryKey = (params?: GetOrdersReportParams,) => [`/v1/api/orders-report`, ...(params ? [params]: [])];

    
export type GetOrdersReportQueryResult = NonNullable<Awaited<ReturnType<typeof getOrdersReport>>>
export type GetOrdersReportQueryError = unknown

export const useGetOrdersReport = <TData = Awaited<ReturnType<typeof getOrdersReport>>, TError = unknown>(
 params?: GetOrdersReportParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOrdersReport>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrdersReportQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrdersReport>>> = ({ signal }) => getOrdersReport(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getOrdersReport>>, TError, TData>({ queryKey, queryFn, ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

