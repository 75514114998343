import React from 'react';
import { OrderClientInformationFieldProps } from './props';
import './style.css';

const OrderClientInformationField = ({
  title,
  value,
  icon,
  customStyle,
  customValueStyle,
}: OrderClientInformationFieldProps) => {
  return (
    <div
      className='order-client-infomration-field-container'
      style={customStyle}
    >
      <div className='order-client-infomration-field-icon'>{icon}</div>
      <div className='order-client-infomration-field-content'>
        <div className='order-client-infomration-field-content-title'>
          {title}
        </div>
        <div
          style={customValueStyle}
          className='order-client-infomration-field-content-value'
        >
          {value ?? ''}
        </div>
      </div>
    </div>
  );
};

export default OrderClientInformationField;
