import React from 'react';
import CardGroup from '../../../../../../components/card-group';
import { OrderCardProps } from './props';
import './style.css';

const OrderCard = ({ title, children }: OrderCardProps) => {
  return (
    <CardGroup
      customStyle={{
        backgroundColor: 'white',
        padding: 0,
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.04)',
        flexDirection: 'column',
      }}
    >
      <div className='order-card-title'>{title}</div>
      {children}
    </CardGroup>
  );
};

export default OrderCard;
