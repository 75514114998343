import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import BusyIndicator from '../../../../../../../../components/busy-indicator';
import Header from '../../../../../../../../components/header';
import { EmployeeType } from '../../../../../../../../dtos/employeeDTO';
import EmployeeService from '../../../../../../../../services/EmployeeService';
import MenuContainer from '../../../../../../components/menu-container';
import PageContainer from '../../../../../../components/page-container';
import { initialEmployee } from '../detail/reducer';
import DetailsCard from './components/details-card';
import PurchaseCard from './components/purchase-card';
import './style.css'

const UserSummary = () => {

    // Attributes
    const { companyId, employeeId }: any = useParams();
    const [employee, setEmployee] = useState(initialEmployee);
    const query = useQuery(`user-${employeeId}`, refreshEmployee);

    // Handlers
    async function refreshEmployee() {
        const employee = await EmployeeService.get(companyId, employeeId);
        setEmployee(employee.data);        
        return employee;
    }

    function renderUserRole() {
        switch (employee.employeeType) {
            case EmployeeType.ADMIN:
                return 'Administrator';
            case EmployeeType.EMPLOYEE:
                return 'Employee';
            case EmployeeType.SUPERIOR:
                return 'Superior';
            case EmployeeType.UNKNOWN:
                return '';
        }
    }

    return (
        <PageContainer>
            <BusyIndicator query={query}>
                <Header
                    title={`${employee.user.firstName} ${employee.user.lastName}`}
                    hasBackButton={true}
                    subTitle={renderUserRole()}
                />
                <MenuContainer>
                    {query.data && (
                        <div className='user-summary-group'>
                            <DetailsCard employee={employee} />
                            <PurchaseCard />
                        </div>)}
                </MenuContainer>
            </BusyIndicator>
        </PageContainer>
    )
}

export default UserSummary;