import React from 'react';
import { SelectedItemProps } from './props';
import { ReactComponent as CloseIcon } from './assets/close.svg';
import './style.css';

const SelectedItem = ({ item, handleClose }: SelectedItemProps) => {
  return (
    <div className='selected-item-container'>
      <div className='selected-item-company-name'>{item.name}</div>
      <CloseIcon
        onClick={handleClose}
        style={{ width: 12, height: 12, cursor: 'pointer' }}
      />
    </div>
  );
};

export default SelectedItem;
