import React from "react";
import { Route, Switch } from "react-router-dom";
import NotificationsDetail from "./modules/detail";
import NotificationsList from "./modules/list";
import TemplateDetail from "./modules/templates/details";
import NotificationsTemplates from "./modules/templates/list";

const Notifications = () => {
  return (
    <Switch>
      <Route
        path={"/dashboard/notifications/templates/details"}
        component={TemplateDetail}
      />
      <Route
        path={"/dashboard/notifications/templates/:templateId/details"}
        component={TemplateDetail}
      />
      <Route
        path="/dashboard/notifications/:notificationId/details"
        component={NotificationsDetail}
      />
      <Route
        path="/dashboard/notifications/details"
        component={NotificationsDetail}
      />
      <Route
        path={"/dashboard/notifications/templates"}
        component={NotificationsTemplates}
      />
      <Route path="/dashboard/notifications" component={NotificationsList} />
    </Switch>
  );
};

export default Notifications;
