import React from "react";
import Translation from "../translation";
import { PopupButtonProps } from "./props";
import "./style.css";
import Lottie from "react-lottie";
import animationData from "../../modules/dashboard/modules/new-orders/modules/list/assets/loading.json";

const PopupButton = ({
  title,
  handleClick,
  backgroundColor,
  disabled,
  isLoading,
}: PopupButtonProps) => {
  return (
    <div
      className={`popup-button ${backgroundColor} ${disabled}`}
      onClick={handleClick}
    >
      {isLoading ? (
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice",
            },
          }}
          height={20}
          width={50}
        />
      ) : (
        <Translation>{title}</Translation>
      )}
    </div>
  );
};

export default PopupButton;
