import React from 'react';
import InputWithLabel from '../../../../../../../../../components/input-with-label';
import { useTranslation } from '../../../../../../../../../components/translation-context/TranslationContext';
import { NotificationDay, NotificationResDTO } from '../../../../../../../../../dtos/go-api';
import InputSelect from '../../../../../../../components/input-select';
import InputTime from '../../../../../../../components/input-time';
import { NotificationReducerAction } from '../../../reducer';
import { ReactComponent as CloseIcon } from './assets/close.svg';
import './style.css';

interface NotifFrequencySectionProps {
  notification: NotificationResDTO;
  dispatch: React.Dispatch<NotificationReducerAction>;
}

enum NotificationFrequencyType {
  EVERY_WEEK = 'EVERY_WEEK',
  EVERY_TWO_WEEKS = 'EVERY_TWO_WEEKS',
}

export default function NotificationFrequencySection({
  notification,
  dispatch,
}: NotifFrequencySectionProps) {
  // Attributes
  const { translate } = useTranslation();
  var frequencyTypes = Object.values(NotificationFrequencyType);
  var frequencyDays = Object.values(NotificationDay);
  const frequencyDaysOptions = frequencyDays.map((frequencyDay) => {
    return {
      value: frequencyDay,
      label: translate(frequencyDay),
    };
  });
  const frequencyOptions = frequencyTypes.map((frequencyType) => {
    return {
      value: frequencyType,
      label: translate(frequencyType),
    };
  });

  // Functions
  function handleFrequencyTypeChange(e: NotificationFrequencyType) {
    dispatch({
      type: 'stateChanged',
      target: 'frequency',
      payload: e,
    });
  }

  function handleFrequencyDayChange(
    e: NotificationDay,
    action: 'Add' | 'Delete' = 'Add'
  ) {
    // create new array of days
    var newFrequencyDays = [...(notification.frequencyDays ?? [])];

    // if day is already in array
    if (newFrequencyDays.includes(e)) {
      // if action is to delete, delete else do nothing
      if (action === 'Delete') {
        newFrequencyDays = newFrequencyDays.filter((day) => day !== e);
        // update state
        dispatch({
          type: 'stateChanged',
          target: 'frequencyDays',
          payload: newFrequencyDays,
        });
      }
      return;
    }

    // otherwise add it
    newFrequencyDays.push(e);

    // update state
    dispatch({
      type: 'stateChanged',
      target: 'frequencyDays',
      payload: newFrequencyDays,
    });
  }

  function handlePublishFrequencyTimingChange(e: any) {
    dispatch({
      type: 'stateChanged',
      target: 'publicationDate',
      payload: (new Date().toISOString()),
    });
    dispatch({
      type: 'stateChanged',
      target: 'publicationTime',
      payload: e.target.value,
    });
  }

  // Render
  return (
    <div>
      <div>
        <span className='publish-now-warning-text'>
          Choose how often you want to publish the notification
        </span>
        <div className='notification-frequency-selects-container'>
          <InputWithLabel style={{ width: '100%' }} label='frequency-type'>
            <InputSelect
              options={frequencyOptions}
              initialValue={notification.frequency}
              handleSelect={(e) => handleFrequencyTypeChange(e)}
              placeholder={translate('EVERY_WEEK')}
            />
          </InputWithLabel>
          <div style={{ width: '40px' }} />
          <InputWithLabel style={{ width: '100%' }} label='frequency-days'>
            <InputSelect
              options={frequencyDaysOptions}
              initialValue={notification.frequencyDays}
              handleSelect={(e) => handleFrequencyDayChange(e)}
              placeholder={translate('day')}
            />
          </InputWithLabel>
        </div>
        <InputWithLabel style={{ width: '100%' }} label='time'>
          <InputTime
            value={notification.publicationTime}
            onChange={(event: any) => handlePublishFrequencyTimingChange(event)}
            style={{ width: '256px' }}
          />
        </InputWithLabel>
        <div className='notification-frequency-days-container'>
          {/* FREQUENCY TYPE */}
          {notification.frequency && (
            <div className='notif-frequency-selected-item'>
              <p className='notif-frequency-selected-item-text'>
                {translate(notification.frequency)}
              </p>
            </div>
          )}
          {/* FREQUENCY DAYS */}
          {notification.frequencyDays?.map((frequencyDay) => {
            return (
              <div className='notif-frequency-day-selected-item'>
                {frequencyDay && (
                  <p className='notif-frequency-day-selected-item-text'>
                    {translate(frequencyDay)}
                  </p>
                )}
                <CloseIcon
                  onClick={() =>
                    handleFrequencyDayChange(frequencyDay, 'Delete')
                  }
                  style={{ width: 12, height: 12, cursor: 'pointer' }}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
