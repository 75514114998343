import React from 'react';
import { TableRowProps } from './props';
import { ReactComponent as EditIcon } from './assets/edit.svg';
import './style.css';
import { useHistory } from 'react-router-dom';
import { useTranslation } from '../../../../../../../../../../components/translation-context/TranslationContext';
import { format } from 'date-fns';
import { PromoType } from '../../../../../../../../../../dtos/go-api';

const TableRow = ({ promo }: TableRowProps) => {
  // Attributes
  const history = useHistory();
  const { translate } = useTranslation();
  const promoValue =
    promo.type === PromoType.AMOUNT ? `${promo.amount}€` : `${promo.amount}%`;

  // Handlers
  function renderIsActive() {
    const { endDate, startDate } = promo;

    if (!endDate && !startDate) {
      return 'Active';
    }

    const endTime = endDate ? new Date(endDate ?? '').getTime() : '-';
    const startTime = new Date(startDate ?? '').getTime();
    const today = new Date().getTime();

    if (startTime < today && !endTime) {
      return 'Active';
    }

    if (startTime > today) {
      return 'Pending';
    }

    if (startTime <= today && endTime === '-') {
      return 'Active';
    }

    if (startTime <= today && endTime >= today) {
      return 'Active';
    }

    if (endTime < today) {
      return 'Expired';
    }
  }

  function renderDate() {
    const { startDate, endDate } = promo;
    if (startDate && endDate) {
      return `${translate(
        format(new Date(startDate), 'MMMM dd').split(' ')[0]
      )} ${format(new Date(startDate), 'MMMM dd').split(' ')[1]} - ${translate(
        format(new Date(endDate), 'MMMM dd').split(' ')[0]
      )} ${format(new Date(endDate), 'MMMM dd').split(' ')[1]}`;
    }

    if (startDate && !endDate) {
      return `${translate(
        format(new Date(startDate), 'MMMM dd').split(' ')[0]
      )} ${format(new Date(startDate), 'MMMM dd').split(' ')[1]} - ${translate(
        'no date'
      )}`;
    }

    if (!startDate && !endDate) {
      return `${translate('no dates')}`;
    }
  }

  return (
    <tr className='promo-table-row-container'>
      <td className='promo-table-row-tuple'>{promo.name}</td>
      <td className='promo-table-row-tuple'>
        <div className={`promo-table-row-tuple-status ${renderIsActive()}`}>
          {renderIsActive()}
        </div>
      </td>
      <td className='promo-table-row-tuple grey'>{renderDate()}</td>
      <td className='promo-table-row-tuple grey'>{promo.promoUsageType}</td>
      <td className='promo-table-row-tuple grey'>{promoValue}</td>
      <td
        className='promo-table-row-tuple edit'
        onClick={() => history.push(`/dashboard/promos/${promo.id}/details`)}
      >
        <div className='promo-table-row-tuple-edit-container'>
          <EditIcon />
        </div>
      </td>
    </tr>
  );
};

export default TableRow;
