import React from "react";
import { useHistory, useParams } from "react-router-dom";
import ActionButton from "../../../../../../../../../../components/action-button";
import { PriorityEnum } from "../../../../../../../../../../dtos/productDTO";
import { useUpdateProductAvailibilityById } from "../../../../../../../../../../services/go-api/product/product";
import Toggle from "../../../../../../../../components/toggle";
import { DishCardProps } from "./props";
import "./style.css";

const DishCard = ({ dish, refetch }: DishCardProps) => {
  // Attributes
  const { restaurantId }: any = useParams();
  const { mutateAsync: updateProductAvailibility } =
    useUpdateProductAvailibilityById();
  //const { mutateAsync: updateProductById } = useUpdateProductById();
  const history = useHistory();
  const priorityLabel =
    dish.priority === PriorityEnum.HIGH
      ? "High"
      : dish.priority === PriorityEnum.MEDIUM
      ? "Medium"
      : dish.priority === PriorityEnum.UNKNOWN
      ? ""
      : "Low";

  // Handlers
  async function handleActive() {
    await updateProductAvailibility(
      {
        restaurantId,
        productId: dish?.id as string,
      },
      {
        onSuccess: async () => {
          await refetch();
        },
      }
    );
  }

  return (
    <div className="dish-card-container">
      <img src={dish.pictureUrl} alt="dish-img" className="dish-card-picture" />
      <div className="dish-card-footer">
        <div className="dish-card-name">{dish.name}</div>
        <div className="dish-card-price">{`${dish.unitPrice}€ - ${priorityLabel}`}</div>
        <div className="dish-card-footer-actions">
          <Toggle
            defaultValue={dish.isAvailable}
            handleClick={handleActive}
            isSelectedText="Active"
            isNotSelectedText="Not Active"
          />
          <div>
            <ActionButton
              name="details"
              handleAction={() =>
                history.push(
                  `/dashboard/restaurants/${restaurantId}/dishes/${dish.id}/details`
                )
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DishCard;
