import React from "react";
import { Route, Switch } from "react-router-dom";
import OrdersDetail from "./modules/detail";
import OrdersListIFrame from "../orders/modules/order-list-iframe";

const Orders = () => {
  return (
    <Switch>
      <Route path="/dashboard/new-orders/:id" component={OrdersDetail} />
      <Route path="/dashboard/new-orders" component={OrdersListIFrame} />
    </Switch>
  );
};

export default Orders;
