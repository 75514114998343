export interface UserDTO {
	id: string;
	firstName: string;
	lastName: string;
	email: string;
	phoneNumber: string;
	userType: UserType;
	password: string;
	oldPassword: string;
}

export enum UserType {
	UNKNOWN = -1,
	ADMIN,
	SUPERIOR,
	EMPLOYEE,
}

export enum UserPaymentMethod {
	UNKNOWN = -1,
	CREDITCARD,
	NEW_CARD,
	BANCONTACT,
	EDENRED,
	MONIZZE,
	SODEXO,
}
