import React, { useEffect, useState } from "react";
import InputWithLabel from "../../../../../../../../components/input-with-label";
import {
  NotificationDestinationType,
  NotificationFrequency,
  NotificationResDTO,
} from "../../../../../../../../dtos/go-api";
import BackgroundDetailsForm from "../../../../../../components/background-details-form";
import GroupInputDetails from "../../../../../../components/group-input-details";
import InputDate from "../../../../../../components/input-date";
import InputTime from "../../../../../../components/input-time";
import { NotificationReducerAction } from "../../reducer";
import NotificationFrequencySection from "./publish-frequency-section";
import PublishTypeRadioSection from "./publish-type-radio";
import "./style.css";

interface NotifTimingSectionProps {
  notification: NotificationResDTO;
  dispatch: React.Dispatch<NotificationReducerAction>;
  destinationType: NotificationDestinationType;
  notificationData: NotificationResDTO | undefined;
  isNewNotif: boolean;
}

export enum TimingType {
  PUBLISH_NOW,
  CUSTOM_DATETIME,
  FREQUENCY,
}

export default function NotifTimingSection({
  notification,
  dispatch,
  destinationType,
  notificationData,
  isNewNotif,
}: NotifTimingSectionProps) {
  // Attributes

  const [selectedTimingType, setSelectedTimingType] = useState<TimingType>(
    notification.frequency === NotificationFrequency.UNIQUE_USE
      ? TimingType.CUSTOM_DATETIME
      : TimingType.FREQUENCY
  );

  // Effects
  useEffect(() => {
    if (notificationData?.frequency) {
      setSelectedTimingType(
        notificationData?.frequency === NotificationFrequency.UNIQUE_USE
          ? TimingType.CUSTOM_DATETIME
          : TimingType.FREQUENCY
      );
    }
  }, [notificationData?.frequency]);

  // Functions
  function handlePublishTimingChange(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    if (
      notification.publicationDate === null ||
      notification.publicationDate === ""
    ) {
      var now = new Date();
      var tzoffset = now.getTimezoneOffset() * 60000; //offset in milliseconds
      var localISOTime = new Date(Date.now() - tzoffset).toISOString();

      dispatch({
        type: "stateChanged",
        target: "publicationDate",
        payload: localISOTime,
      });
    }

    dispatch({
      type: "stateChanged",
      target: "publicationTime",
      payload: event.target.value,
    });
  }

  // Render
  return (
    <BackgroundDetailsForm>
      <GroupInputDetails title='notifPubDate'>
        {/* RADIO */}
        <PublishTypeRadioSection
          notification={notification}
          dispatch={dispatch}
          selectedTimingType={selectedTimingType}
          setSelectedTimingType={setSelectedTimingType}
          isNewNotif={isNewNotif}
          destinationType={destinationType}
        />
        {/* DATE-TIME PICKER */}
        {selectedTimingType === TimingType.CUSTOM_DATETIME && (
          <div className='date-time-pickers-container'>
            <InputWithLabel style={{ width: "100%" }} label='date'>
              <InputDate
                value={notification.publicationDate?.split("T")[0]}
                min={new Date().toISOString().split("T")[0]}
                onChange={(event: any) =>
                  dispatch({
                    type: "stateChanged",
                    target: "publicationDate",
                    payload: new Date(event.target.value).toISOString(),
                  })
                }
                style={{ width: "90%" }}
              />
            </InputWithLabel>
            <InputWithLabel style={{ width: "100%" }} label='time'>
              <InputTime
                value={notification.publicationTime}
                onChange={(event: any) => handlePublishTimingChange(event)}
                style={{ width: "90%" }}
              />
            </InputWithLabel>
          </div>
        )}
        {/* FREQUENCY SECTION */}
        {selectedTimingType === TimingType.FREQUENCY && (
          <NotificationFrequencySection
            notification={notification}
            dispatch={dispatch}
          />
        )}
      </GroupInputDetails>
    </BackgroundDetailsForm>
  );
}
