import Service from "./Service";
import { AxiosResponse } from "axios";
import BearerTokenDTO from "../dtos/bearer-token";

async function post(email: string, password: string) {
    var details: any = {
        client_id: "client",
        grant_type: "internal",
        email: email,
        password: password,
        client_secret: "511536EF-F270-4058-80CA-1C89C192F69A",
    };

    let formBody: any = [];

    for (let property in details) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }

    formBody = formBody.join("&");

    try {
        return await Service.iam
            .post("/connect/token", formBody, {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    Accept: "application/json",
                    Authorization: "bearer undefined",
                },
            })
            .then((elt: AxiosResponse<BearerTokenDTO>) => elt.data);
    } catch (e) {
        Service.errors.push("Wrong credentials");
        return undefined;
    }
}

export default { post };
