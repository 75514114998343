import React from "react";
import { ReactComponent as PolygonIcon } from "./polygon.svg";
import { TemplateDescriptionColumnProps } from "./props";
import "./style.css";

const TemplateDescriptionColumn = ({
  description,
}: TemplateDescriptionColumnProps) => {
  //render
  return (
    <div className="description-parent">
      <div className="description">{description}</div>
      {description.length > 40 && (
        <div className="description-tool-tips">
          <div className="polygon-description">
            <PolygonIcon />
          </div>
          <div>{description}</div>
        </div>
      )}
    </div>
  );
};

export default TemplateDescriptionColumn;
