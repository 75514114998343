import React, { useState } from 'react';
import Translation from '../../../../../../../../../../components/translation';
import { CompanyRestaurantsIndexProps } from './props';
import { ReactComponent as PercentIcon } from './assets/percent.svg';
import polygon from './assets/polygon.png';

const CompanyRestaurantsIndex = ({ index, handleAction, style }: CompanyRestaurantsIndexProps) => {

    // Attributes
    const [isHover, setIsHover] = useState(false);

    // Handlers
    
    return (
        <div className='action-button-container' onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
            <div className='action-button' onClick={handleAction} style={{ ...style }}>
                {index >= 0 ? (
                    <div className='action-button-value-container'>
                        <div className='action-button-value'>
                            { index.toString().replace('.', ',') }
                        </div>
                        <div style={{ height: 'fill' }}>
                            <PercentIcon />
                        </div>

                    </div>
                ) : <PercentIcon />}
            </div>
            {isHover && (
                <div className="table-action-pop-over-bottom">
                    <div className="table-action-pop-over-polygon-bottom">
                        <img className="table-action-pop-over-polygon-image" src={polygon} alt="" />
                    </div>

                    <div className="table-action-pop-over-body-bottom">
                        <Translation>
                            index
                        </Translation>
                    </div>
                </div>
            )}
        </div>
    )
};

export default CompanyRestaurantsIndex;