import React from 'react';
import './style.css';

const MenuAction = (props: any) => {

    // Attributes
    const length = props.children.length

    return (
        <div className='dashboard-menu-action-container'>
            <div className='dashboard-menu-action-filters'>
                {props.children[0]}
                <div style={{ marginRight: 31 }} />
                {length > 2 && props.children[1]}
            </div>
            <div>
                {props.children[length - 1]}
            </div>
        </div>
    )
}

export default MenuAction;