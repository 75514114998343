import {
  PromoCodeResDTO,
  PromoType,
  PromoUsageType,
} from "../../../../../../dtos/go-api";

export const initialPromo: PromoCodeResDTO = {
  id: "",
  name: "",
  startDate: new Date().toISOString(),
  endDate: "",
  amount: 0,
  restaurants: [],
  companies: [],
  promoUsageType: PromoUsageType.MULTIPLEUSE,
  type: PromoType.UNKNOWN,
};

interface PromoReducerAction {
  type: "serverChanged" | "stateChanged";
  target: string;
  payload: any;
}

export const reducer = (state: PromoCodeResDTO, action: PromoReducerAction) => {
  switch (action.type) {
    case "serverChanged":
      return {
        ...state,
        ...action.payload,
      };

    case "stateChanged":
      return {
        ...state,
        [action.target]: action.payload,
      };
  }
};
