import React from "react";
import ChevronLeft from "./assets/chevron-left";
import ChevronRight from "./assets/chevron-right";
import { PagerProps } from "./props";

const Pager = ({ page, totalPages, setPage }: PagerProps) => {
	// Render
	return (
		<div style={customStyles.container}>
			<ChevronLeft
				width="1.5rem"
				fill="#1E1F20"
				cursor="pointer"
				onClick={() => setPage && setPage(Math.max((page || 0) - 1, 0))}
			/>
			<div style={customStyles.content}>
				<div style={customStyles.pageContainer}>
					<div style={customStyles.pageText}>
						{page !== undefined ? page + 1 : 0}
					</div>
				</div>
				<div style={customStyles.totalPageText}>of</div>
				<div style={customStyles.totalPageText}>{totalPages ?? 0}</div>
			</div>
			<ChevronRight
				width="1.5rem"
				fill="#1E1F20"
				cursor="pointer"
				onClick={() =>
					setPage &&
					setPage(
						Math.min(
							(page || 0) + 1,
							totalPages ? totalPages - 1 : 0
						)
					)
				}
			/>
		</div>
	);
};

const customStyles: any = {
	container: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		padding: ".875rem",
		gap: "1rem",
		backgroundColor: "white",
		boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.04)",
		borderRadius: "18px",
		boxSizing: "border-box",
	},
	content: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		gap: ".75rem",
	},
	pageContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		width: "1.75rem",
		height: "1.875rem",
		background: "rgba(40, 98, 97, 0.1)",
		borderRadius: ".4375rem",
	},
	pageText: {
		color: "#286261",
		fontSize: ".875rem",
		fontWeight: "700",
		lineHeight: "1.3125rem",
	},
	totalPageText: {
		color: "#1E1F20",
		fontSize: ".875rem",
		fontWeight: "600",
		lineHeight: "1.3125rem",
	},
};

export default Pager;
