import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Icons from "../../../../components/empty-states/assets";
import AddButton from "../../../../../../components/add-button";
import BusyIndicator from "../../../../../../components/busy-indicator";
import Header from "../../../../../../components/header";
import Translation from "../../../../../../components/translation";
import { PromoCodeStatus } from "../../../../../../dtos/go-api";
import { useGetPromoCodes } from "../../../../../../services/go-api/promo-code/promo-code";
import EmptyStates from "../../../../components/empty-states";
import MenuAction from "../../../../components/menu-action";
import MenuListContainer from "../../../../components/menu-list-container";
import PageContainer from "../../../../components/page-container";
import Search from "../../../../components/search";
import PromoTable from "./components/table";

const PromoList = () => {
  // Attributes

  const SIZE = 1000;
  const history = useHistory();
  const [search, setSearch] = useState<string>("");
  const promocodeRes = useGetPromoCodes({
    page: 0,
    size: SIZE,
    q: search,
  });
  const promoCodes = promocodeRes && promocodeRes.data;

  return (
    <PageContainer>
      <Header title="Promo codes" />
      <MenuListContainer>
        <MenuAction>
          <Search
            value={search}
            placeholder="Search for a promo code"
            onChange={setSearch}
          />
          <AddButton
            handleClick={() => history.push("/dashboard/promos/details")}
            title="Add new promo code"
          />
        </MenuAction>
        <BusyIndicator query={promocodeRes}>
          {promoCodes?.data?.length === 0 ? (
            search !== "" ? (
              <div className="empty-states-container">
                <Icons.PromoIcon />
                <div className="empty-states-header-title">
                  <Translation>
                    {"Pas de résultats pour votre recherche"}
                  </Translation>
                </div>
              </div>
            ) : (
              <EmptyStates menu="promos" />
            )
          ) : (
            <PromoTable
              promos={
                promoCodes?.data?.filter(
                  (promoCode) => promoCode.status !== PromoCodeStatus.EXPIRED
                ) ?? []
              }
            />
          )}
        </BusyIndicator>
      </MenuListContainer>
    </PageContainer>
  );
};

export default PromoList;
