import { motion } from "framer-motion";
import React, { ChangeEvent } from "react";
import { SearchProps } from "./props";
import { ReactComponent as SearchIcon } from "./assets/search.svg";
import "./style.css";

const Search = ({
	placeholder,
	value,
	onChange,
	width = "20rem",
	height = "3.625rem",
	animation = true,
}: SearchProps) => {
	// Handlers
	const handleInputChanged = (event: ChangeEvent<HTMLInputElement>) => {
		onChange?.(event.target.value);
	};

	return (
		<motion.div
			transition={{ duration: 0.3 }}
			initial={{ x: -10 }}
			animate={{ x: 0 }}
		>
			<motion.div
				animate={animation ? { width } : {}}
				whileHover={animation ? { width: 500 } : {}}
				className="search-container"
				style={{ width: width, height: height }}
			>
				<SearchIcon />
				<input
					value={value}
					onChange={handleInputChanged}
					className="search-input"
					placeholder={placeholder}
				/>
			</motion.div>
		</motion.div>
	);
};

export default Search;
