import React from 'react';
import Translation from '../../../../components/translation';
import { PopupProps } from './props';
import './style.css';

const Popup = ({ icon, title, subTitle, buttons, secondTitle }: PopupProps) => {
  return (
    <div className='popup-container'>
      {icon}
      <div className='popup-title'>
        <Translation>{title}</Translation>
      </div>
      {secondTitle && (
        <div className='popup-secondTitle'>
          <Translation>{secondTitle}</Translation>
        </div>
      )}
      {subTitle && (
        <div className='popup-subtitle'>
          <Translation>{subTitle}</Translation>
        </div>
      )}
      <div className='popup-buttons-group'>
        {buttons.map((button: any) => button)}
      </div>
    </div>
  );
};

export default Popup;
