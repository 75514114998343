/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */

export type NotificationType = typeof NotificationType[keyof typeof NotificationType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NotificationType = {
  SENT: 'SENT',
  UPCOMING: 'UPCOMING',
  RECURRING: 'RECURRING',
} as const;
