import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { UserCardProps } from './props';
import { ReactComponent as ArrowRight } from './assets/arrow-right.svg'
import './style.css'
import Translation from '../../../../../../../../../../components/translation';
import { EmployeeType } from '../../../../../../../../../../dtos/employeeDTO';

const UserCard = ({ employee }: UserCardProps) => {

    // Attributes
    const { companyId }: any = useParams()
    const history = useHistory()

    // Handler
    function renderUserType() {
        switch (employee.employeeType) {
            case EmployeeType.ADMIN:
                return 'Administrator';
            case EmployeeType.SUPERIOR:
                return 'Superior';
            case EmployeeType.EMPLOYEE:
                return 'Employee';
            default:
                return ''
        }
    }

    return (
        <div className='companies-users-card-container'>
            <div className='companies-users-card-details'>
                <div className='companies-users-card-details-name'>
                    {`${employee.user.firstName} ${employee.user.lastName}`}
                </div>
                <div className='companies-users-card-details-role'><Translation>{renderUserType()}</Translation></div>
            </div>
            <ArrowRight style={{ cursor: 'pointer' }} onClick={() => history.push(`/dashboard/companies/${companyId}/employees/${employee.id}/summary`)} />
        </div>
    )
}

export default UserCard;