import React, { useMemo } from "react";
import {
  ProductTagResDTO,
} from "../../../../../../../../../../../dtos/go-api";
import { useGetAllTags } from "../../../../../../../../../../../services/go-api/tags/tags";
import BackgroundDetailsForm from "../../../../../../../../../components/background-details-form";
import InputTags from "../../../../../../../../../components/input-tags";
import { ITag } from "../../../../../../../../../components/input-tags/props";
import TitleInputGroup from "../../../../../../../../../components/title-input-group";
import { VisibleTagsProps } from "../props";
import "../style.css";

const VisibleTags = ({ product, dispatch }: VisibleTagsProps) => {
  // Attributes
  const { data: allTags } = useGetAllTags({ size: 1000 });

  const options = useMemo(() => {
    if (allTags && allTags?.data)
      return allTags?.data.map((tag) => ({
        label: tag?.label ?? "",
        value: tag,
      }));
    return [];
  }, [allTags]);

  const mapValueToTag = (value: ProductTagResDTO) => {
    return { id: value.id, label: value.label, value: value };
  };

  // Render
  return (
    <BackgroundDetailsForm>
      <TitleInputGroup title="Visible tags" />
      <div style={{ marginTop: "1.5rem" }}>
        <InputTags
          values={product.visibleTags?.map((tag) => mapValueToTag(tag)) ?? []}
          options={options}
          handleSelect={(tag: ITag) => {
            dispatch({
              type: "productChanged",
              target: "visibleTags",
              payload: [
                ...(product.visibleTags ?? []),
                {
                  label: tag.label,
                  id: tag.value?.id,
                  productType: "VISIBLE",
                } as ProductTagResDTO,
              ],
            });
          }}
          handleDelete={(removedTag: ITag) => {
            dispatch({
              type: "productChanged",
              target: "visibleTags",
              payload: product.visibleTags?.filter(
                (tag) => tag.label !== removedTag.label
              ),
            });
          }}
          handleCreateTag={({ label }) =>
            dispatch({
              type: "productChanged",
              target: "visibleTags",
              payload: [
                ...(product.visibleTags ?? []),
                {
                  label: label,
                  productType: "VISIBLE",
                } as ProductTagResDTO,
              ],
            })
          }
        />
      </div>
    </BackgroundDetailsForm>
  );
};

export default VisibleTags;
