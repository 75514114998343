/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */

export type OrderLogType = typeof OrderLogType[keyof typeof OrderLogType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderLogType = {
  PRINTED: 'PRINTED',
  CLICKED: 'CLICKED',
  UNKNOWN: 'UNKNOWN',
} as const;
