import React, { useEffect, useReducer, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Header from '../../../../../../components/header';
import SaveButton from '../../../../../../components/header/components/save-button';
import { SAVE_BUTTON_TYPE } from '../../../../../../components/header/components/save-button/props';
import RestaurantService from '../../../../../../services/RestaurantService';
import MenuContainer from '../../../../components/menu-container';
import PageContainer from '../../../../components/page-container';
import RestaurantInformation from './components/information';
import { initialRestaurant, reducer } from './reducer';
import './style.css';
import Modal from 'react-modal';
import { customStyles } from '../../../../components/popup/customStyle';
import Popup from '../../../../components/popup';
import PopupButton from '../../../../../../components/popup-button';
import { ReactComponent as DeleteIcon } from '../../../../../../assets/delete-icon.svg';
import Form from '../../../../components/form';
import { usePopupContext } from '../../../../components/popup-context/context';
import { EnumPopupIndex } from '../../../../components/popup-context/props';
import Service from '../../../../../../services/Service';
import { useTranslation } from '../../../../../../components/translation-context/TranslationContext';
import BusyIndicator from '../../../../../../components/busy-indicator';
import {
	useCreateRestaurant,
	useDeleteRestaurantById,
	useGetRestaurantById,
	useUpdateRestaurantById,
} from '../../../../../../services/go-api/restaurant/restaurant';
import UploadPictureRestaurantDetail from './components/uploadPicture';
import { RestaurantType } from '../../../../../../dtos/go-api';

const RestaurantDetail = () => {
	// Attributes
	const history = useHistory();
	const { translate } = useTranslation();
	const { restaurantId }: any = useParams();
	const restaurant = useGetRestaurantById(restaurantId);
	const [restaurantValue, dispatch] = useReducer(reducer, initialRestaurant);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const { showPopup } = usePopupContext();
	const [restaurantType, setRestaurantType] = useState<RestaurantType>(
		restaurant?.data?.restaurantType ?? RestaurantType.UNKNOWN
	);

	const { mutateAsync: updateRestaurant } = useUpdateRestaurantById();
	const { mutateAsync: createRestaurant } = useCreateRestaurant();
	const { mutateAsync: deleteRestaurant } = useDeleteRestaurantById();

	// Effects
	useEffect(() => {
		if (restaurant?.data && restaurantId) {
			setRestaurantType(restaurant?.data?.restaurantType);
			dispatch({
				type: 'serverChanged',
				target: 'self',
				payload: restaurant.data,
			});
		}
	}, [restaurant?.data]);

	// Handlers
	function isUpdated() {
		if (restaurant.data && restaurantId) {
			for (const attribute in restaurantValue) {
				if (!Object.is(restaurant, restaurantValue[attribute])) {
					return true;
				}
			}
		}
		return false;
	}

	async function handleSave() {
		for (const attribute in restaurantValue) {
			if (
				attribute === 'name' ||
				attribute === 'pictureUrl' ||
				attribute === 'restaurantType'
			) {
				if (
					restaurantValue[attribute] === '' ||
					restaurantValue[attribute] === -1
				) {
					showPopup(EnumPopupIndex.MANDATORY);
					return;
				}
			}
		}

		let response;
		if (restaurantId) {
			response = await updateRestaurant({
				restaurantId: restaurantId,
				data: restaurantValue,
			});
		} else {
			response = await createRestaurant({
				data: restaurantValue,
			});
		}

		if (response) {
			history.goBack();
		}
	}

	async function handleDelete() {
		await deleteRestaurant(
			{
				restaurantId: restaurantId,
			},
			{
				onSuccess: () => {
					Service.success.push(translate('delete_restaurant_success'));
					history.push('/dashboard/restaurants/');
					return;
				},
			}
		);
	}

	return (
		<PageContainer>
			<Header
				title={restaurantId ? restaurantValue.name : 'Add new restaurantValue'}
				isUpdated={isUpdated()}
				hasBackButton={true}
				saveButton={
					<SaveButton
						handleClick={handleSave}
						type={
							restaurantId
								? SAVE_BUTTON_TYPE.SAVE
								: SAVE_BUTTON_TYPE.SAVE_AND_ADD
						}
						handleDelete={() => setIsModalOpen(true)}
					/>
				}
			/>
			<MenuContainer>
				<BusyIndicator query={restaurant}>
					<Form>
						<div
							style={{ flexWrap: 'wrap' }}
							className='restaurant-details-form-container'
						>
							<RestaurantInformation
								restaurant={restaurantValue}
								dispatch={dispatch}
								setRestaurantType={setRestaurantType}
								restaurantType={restaurantType}
							/>
							<UploadPictureRestaurantDetail
								dispatch={dispatch}
								restaurant={restaurantValue}
								restaurantType={restaurantType}
							/>
						</div>
					</Form>
				</BusyIndicator>
			</MenuContainer>
			<Modal style={customStyles} isOpen={isModalOpen}>
				<Popup
					title='Are you sure you want to delete this restaurant?'
					icon={<DeleteIcon style={{ width: 66, height: 69 }} />}
					buttons={[
						<PopupButton
							backgroundColor='grey'
							handleClick={() => setIsModalOpen(false)}
							title='Cancel'
						/>,
						<PopupButton
							backgroundColor='yellow'
							handleClick={handleDelete}
							title='Yes, delete'
						/>,
					]}
				/>
			</Modal>
		</PageContainer>
	);
};

export default RestaurantDetail;
