import React from "react";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import AddButton from "../../../../../../../../components/add-button";
import BusyIndicator from "../../../../../../../../components/busy-indicator";
import Header from "../../../../../../../../components/header";
import SegmentTab from "../../../../../../../../components/segment-tab";
import { EmployeeDTO } from "../../../../../../../../dtos/employeeDTO";
import useSearch from "../../../../../../../../hooks/use-search";
import useSegment from "../../../../../../../../hooks/use-search/use-segment";
import CompanyService from "../../../../../../../../services/CompanyService";
import EmployeeService from "../../../../../../../../services/EmployeeService";
import CardGroup from "../../../../../../components/card-group";
import EmptyStates from "../../../../../../components/empty-states";
import MenuAction from "../../../../../../components/menu-action";
import MenuListContainer from "../../../../../../components/menu-list-container";
import PageContainer from "../../../../../../components/page-container";
import Search from "../../../../../../components/search";
import UserCard from "./components/user-card";

const UserList = () => {
  // History
  const history = useHistory();
  const { companyId }: any = useParams();
  const query = useQuery(`${companyId}-users`, refreshUsers);
  const companyQuery = useQuery(`company-${companyId}`, getCompany);
  const { search, filtered, setSearch } = useSearch<EmployeeDTO>(
    query.data ? query.data : [],
    ["user.firstName", "user.lastName", "user.email"]
  );
  const { setSegment, segmented } = useSegment(filtered, handleSegment, "All");

  // Handlers
  async function refreshUsers() {
    return (await EmployeeService.get(companyId)).data;
  }

  async function getCompany() {
    const response = await CompanyService.get(companyId);
    return response.data;
  }

  function renderUsers() {
    if (search) {
      return filtered.map((employee) => (
        <UserCard
          employee={employee}
          key={`company-user-${employee.user.id}`}
        />
      ));
    }

    return segmented.map((employee) => (
      <UserCard employee={employee} key={`company-user-${employee.user.id}`} />
    ));
  }

  function handleSegment(employee: EmployeeDTO, segment: string) {
    if (segment === "All") {
      return true;
    }
    const segmentValue = mapSegmentWithValue(segment);
    return employee.employeeType === segmentValue;
  }

  function mapSegmentWithValue(segment: string) {
    switch (segment) {
      case "Admin":
        return 0;
      case "Superior":
        return 1;
      case "Employee":
        return 2;
      case "All":
        return 3;
    }
  }

  return (
    <BusyIndicator query={query}>
      <PageContainer>
        <Header
          title={`${companyQuery.data ? companyQuery.data.name : ""} - Users`}
          hasBackButton={true}
          navigationHeader={[
            { path: "/dashboard/companies", name: "Companies" },
            {
              name: companyQuery.data ? companyQuery.data.name : "",
              path: `/dashboard/companies/${companyId}/details`,
            },
            { name: "Users" },
          ]}
          actionButtons={{
            menu: "companies",
            id: companyId,
            routes: ["restaurants", "employees", "details"],
          }}
        />
        {query.data && query.data.length > 0 ? (
          <MenuListContainer>
            <MenuAction>
              <Search
                value={search}
                placeholder="Search for a user"
                onChange={setSearch}
              />
              <SegmentTab
                segments={["All", "Admin", "Superior", "Employee"]}
                handleChange={(segment: string) => setSegment(segment)}
              />
              <AddButton
                title="Add new user"
                handleClick={() =>
                  history.push(
                    `/dashboard/companies/${companyId}/employees/details`
                  )
                }
              />
            </MenuAction>
            <CardGroup>{query?.data && renderUsers()}</CardGroup>
          </MenuListContainer>
        ) : (
          <EmptyStates menu="employees" companyId={companyId} />
        )}
      </PageContainer>
    </BusyIndicator>
  );
};

export default UserList;
