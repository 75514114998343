import React from 'react';
import PromoTableHeader from './components/table-header';
import TableRow from './components/table-row';
import { PromoTableProps } from './props';
import './style.css';

const PromoTable = ({ promos }: PromoTableProps) => {
  // Handlers
  function renderPromos() {
    return promos.map((promo) => <TableRow promo={promo} key={promo.id} />);
  }

  return (
    <div className='promo-body-container'>
      <table className='promo-table-container'>
        <PromoTableHeader />
        <tbody className='pomo-table-body-container'>{renderPromos()}</tbody>
      </table>
    </div>
  );
};

export default PromoTable;
