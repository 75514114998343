import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "../../../../../../../../components/translation-context/TranslationContext";
import { CompanyResDTO } from "../../../../../../../../dtos/go-api";
import InputSelect from "../../../../../../components/input-select";
import { OptionSelect } from "../../../../../../components/input-select/props";
import { ZoneDetailsParams } from "../../props";
import ZoneCompanyDragCard from "./components/drag-card";
import { ZoneCompaniesProps } from "./props";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./styles.css";
import { useGetAllAvailableCompanies, useGetAllCompaniesByZoneId } from "../../../../../../../../services/go-api/zone/zone";

const ZoneCompanies = ({ companyIds, updateCompanyIds }: ZoneCompaniesProps) => {
	// Attributes
	const { translate } = useTranslation();
	const { zoneId } = useParams<ZoneDetailsParams>();
	const { data: companies } = useGetAllAvailableCompanies({ size: 1000 }, { query: { refetchOnWindowFocus: false } });

	const [options, setOptions] = useState<OptionSelect[]>([]);
	const [selectedCompanies, setSelectedCompanies] = useState<CompanyResDTO[]>([]);
	const [isDraggingOver, setIsDraggingOver] = useState(false);
	const [initialHeight, setInitialHeight] = useState<number>();
	const ref = useRef<HTMLDivElement>(null);
	const { data: zoneCompanies } = useGetAllCompaniesByZoneId(zoneId, {}, { query: { refetchOnWindowFocus: false, enabled: zoneId !== undefined } });

	// Effects
	// Initial height
	useEffect(() => {
		if (ref?.current && !isDraggingOver) {
			setInitialHeight(ref?.current.clientHeight);
		}
	}, [selectedCompanies, isDraggingOver]);

	// Options for multi-select
	useEffect(() => {
		if (companies?.data !== undefined) {
			let optionCompanies = companies?.data;

			if (zoneId) {
				// Remove zone companies from select options
				optionCompanies = companies.data.filter((dto) => !companyIds?.includes(dto.id ?? ""));
			}
			setOptions((prev) => {
				const options: OptionSelect[] = optionCompanies.map((company) => {
					return { value: company.id ?? "", label: company.name ?? "" };
				});
				return options;
			});
		}
	}, [zoneId, companies?.data, setOptions, companyIds]);

	// Update selected companies
	useEffect(() => {
		if (zoneId) {
			// Get zone companies
			if (zoneCompanies?.data !== undefined) {
				setSelectedCompanies(zoneCompanies.data);
			}
		}
	}, [zoneId, zoneCompanies?.data, setSelectedCompanies]);

	// update list of company ids in form
	useEffect(() => {
		const companyIds = selectedCompanies.map((cmp) => cmp.id ?? "");

		if (companyIds) {
			updateCompanyIds(companyIds);
		}
	}, [selectedCompanies]);

	// Handlers
	function handleSelect(id: string) {
		// remove company from search list
		setOptions((previous) => {
			const copy = [...previous];
			const index = copy.findIndex((item) => item.value === id);
			copy.splice(index, 1);
			return copy;
		});

		// add company to selected list
		const company = companies?.data?.find((company) => company.id === id);
		setSelectedCompanies((previous) => {
			if (company) {
				const list = [...previous, company];
				return list;
			}
			return previous;
		});
	}
	function handleUnSelect(id: string) {
		// add company to options
		const company = companies?.data?.find((company) => company.id === id);

		setOptions((previous) => {
			if (company) {
				const list = [...previous, { label: company.name ?? "", value: company.id ?? "" }];

				// sort by label
				list.sort((a, b) => {
					if (a.label < b.label) {
						return -1;
					}
					if (a.label > b.label) {
						return 1;
					}
					return 0;
				});
				return list;
			}
			return previous;
		});

		setSelectedCompanies((previous) => {
			const copy = [...previous];
			const index = copy.findIndex((item) => item.id === id);
			copy.splice(index, 1);
			return copy;
		});
	}

	// a little function to help us with reordering the result
	function reorder(list: CompanyResDTO[], startIndex: number, endIndex: number) {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);

		return result;
	}

	function onDragEnd(result: any) {
		if (!result.destination) {
			return;
		}

		const reorderedCompanies = reorder(selectedCompanies, result.source.index, result.destination.index);
		setSelectedCompanies(reorderedCompanies);
	}

	return (
		<div className="zone-details-input-box-container-company" style={{ height: isDraggingOver ? initialHeight : "fit-content" }} ref={ref}>
			<div className="zone-details-input-title">{translate("Select company")}</div>

			<InputSelect options={options} handleSelect={handleSelect} />

			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId="droppable-zone-companies">
					{(provided, snapshot) => {
						setIsDraggingOver(snapshot.isDraggingOver);
						return (
							<div {...provided.droppableProps} ref={provided.innerRef} className="zone-companies-selected-list-container">
								{selectedCompanies.map((cmp, index) => (
									<Draggable key={cmp.id} draggableId={cmp.id ?? ""} index={index}>
										{(provided, snapshot) => (
											<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
												<ZoneCompanyDragCard index={index} company={cmp} handleUnSelect={handleUnSelect} />
											</div>
										)}
									</Draggable>
								))}
							</div>
						);
					}}
				</Droppable>
			</DragDropContext>
		</div>
	);
};

export default ZoneCompanies;
