/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation
} from 'react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  TagPagingDTO,
  GetAllTagsParams,
  TagDTO
} from '../../../dtos/go-api'
import { customInstance } from '.././config';

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


export const getAllTags = (
    params?: GetAllTagsParams,
 signal?: AbortSignal
) => {
      return customInstance<TagPagingDTO>(
      {url: `/v1/api/tags`, method: 'get',
        params, signal
    },
      );
    }
  

export const getGetAllTagsQueryKey = (params?: GetAllTagsParams,) => [`/v1/api/tags`, ...(params ? [params]: [])];

    
export type GetAllTagsQueryResult = NonNullable<Awaited<ReturnType<typeof getAllTags>>>
export type GetAllTagsQueryError = unknown

export const useGetAllTags = <TData = Awaited<ReturnType<typeof getAllTags>>, TError = unknown>(
 params?: GetAllTagsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAllTags>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllTagsQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllTags>>> = ({ signal }) => getAllTags(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getAllTags>>, TError, TData>({ queryKey, queryFn, ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export const deleteTagById = (
    tagId: string,
 ) => {
      return customInstance<TagDTO>(
      {url: `/v1/api/tags/${tagId}`, method: 'delete'
    },
      );
    }
  


    export type DeleteTagByIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteTagById>>>
    
    export type DeleteTagByIdMutationError = unknown

    export const useDeleteTagById = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTagById>>, TError,{tagId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteTagById>>, {tagId: string}> = (props) => {
          const {tagId} = props ?? {};

          return  deleteTagById(tagId,)
        }

        

      return useMutation<Awaited<ReturnType<typeof deleteTagById>>, TError, {tagId: string}, TContext>(mutationFn, mutationOptions);
    }
    