import React from 'react';
import Translation from '../../../../components/translation';
import { InputGroupTitleProps } from './props';
import './style.css'

const TitleInputGroup = ({ title }: InputGroupTitleProps) => {

    return (
        <div className='input-group-title'>
            <Translation>
                {title}
            </Translation>
        </div>
    )
}

export default TitleInputGroup;