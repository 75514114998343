import './style.css';
import React from 'react';
import InputGroupProps from "./props";

const InputGroup = ({size="medium", children}: InputGroupProps) => {
    
    return (
        <div className={`input-group-flex-row ${size}`}>
            {children}
        </div>
    );
}

export default InputGroup;