import React from "react";
import { UploadPictureProps } from "./props";
import BackgroundDetailsForm from "../../../../components/background-details-form";
import TitleInputGroup from "../../../../components/title-input-group";
import Dropzone from "./components/dropzone";

const UploadPicture = ({
  pictureUrl,
  handleChangePictureUrl,
  size,
  title,
  uploadContainerSize,
  fileDeviceType,
  target,
}: UploadPictureProps) => {
  //Attributes
  let uploadSize = uploadContainerSize ?? 40;

  switch (size) {
    case "small":
      uploadSize = 140;
      break;
    case "medium":
      uploadSize = 320;
      break;
    case "large":
      uploadSize = 680;
      break;
    default:
      break;
  }

  // Handlers
  return (
    <BackgroundDetailsForm
      style={{
        width: size ? `${uploadSize}px` : `${uploadSize}%`,
        outerHeight: "fit-content",
      }}
    >
      <TitleInputGroup title={title ?? "Upload your image"} />
      <Dropzone
        pictureUrl={pictureUrl}
        handleChangePictureUrl={handleChangePictureUrl}
        fileDeviceType={fileDeviceType}
        target={target}
      />
    </BackgroundDetailsForm>
  );
};

export default UploadPicture;
