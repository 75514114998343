import React from "react";

const Checked = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg
			viewBox="0 0 34 34"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<rect
				x="8.91138"
				y="8.91113"
				width="16.8324"
				height="16.8324"
				rx="3"
				fill="#F5C755"
				stroke="#F5C755"
				strokeWidth="3"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14.1116 16.3354C13.5638 15.7876 12.6757 15.7876 12.1279 16.3354C11.5801 16.8832 11.5801 17.7713 12.1279 18.3191L14.9333 21.1245C15.4811 21.6723 16.3692 21.6723 16.917 21.1245L22.5278 15.5137C23.0756 14.9659 23.0756 14.0778 22.5278 13.53C21.98 12.9822 21.0919 12.9822 20.5441 13.53L15.9252 18.1489L14.1116 16.3354Z"
				fill="white"
			/>
		</svg>
	);
};
export default Checked;
