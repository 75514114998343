export const selectStyle = {
    option: (provided: any) => ({
        ...provided,
        backgroundColor: 'none',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '0.875rem',
        lineHeight: '1.375rem',
        color: "rgb(6, 26, 66);",
        cursor: 'pointer',
        fontFamily: "Poppins, sans-serif",
    }),
    control: (provided: any, state: any) => ({
        ...provided,
        outline: 'none',
        height: '100%',
        minHeight: '48px',
        border: "none",
        width: "21.25rem",
        minWidth: '251px',
        borderRadius: "1.125rem",
        backgroundColor: "white",
        cursor: "pointer",
        boxSizing: "border-box",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.04)",
        padding: "0px 1rem 0px 1.5rem;"
    }),
    singleValue: (provided: any) => ({
        ...provided,
        fontWeight: "600;",
    }),
    menu: (provided: any) => ({
        ...provided,
        backgroundColor: "white;",
        border: "1px solid rgb(238, 238, 238);",
        boxShadow: "rgb(0 0 0 / 4%) 0px 4px 10px;",
        borderRadius: "1.125rem;"
    }),
    menuList: (provided: any) => ({
        ...provided,
        backgroundColor: "white;",
        border: "1px solid rgb(238, 238, 238);",
        boxShadow: "rgb(0 0 0 / 4%) 0px 4px 10px;",
        borderRadius: "1.125rem;",
        padding: "0px 0.8125rem 1.0625rem;"
    }),
    indicatorSeparator: (provided: any) => ({
        ...provided,
        display: "none"
    }),
    indicatorContainer: (provided: any) => ({
        ...provided,
        stroke: "rgb(30, 31, 32)"
    }),
    valueContainer: (provided: any) => ({
        ...provided,
        fontFamily: "Poppins, sans-serif",
        color: "rgb(127, 133, 162);",
        fontWeight: "600;",
        fontSize: "0.75rem;",
        lineHeight: "1.375rem;",
        // white-space: nowrap;
        // overflow-x: auto;
    }),

};


export const customStyles: any = {
    container: {
        margin: '2.875rem 2.5625rem 0 2.5625rem',
        display: 'flex',
        gap: '.9375rem',
        flexDirection: 'column',
    },
    column: {
        display: 'flex',
        width: '100%',
        "flex-direction": "column"
    },
    filtersContainer: {
        display: 'flex',
        height: '100%',
        gap: '1.125rem',
    },
    filtersRightContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '1.3125rem',
        "margin-left": "auto",
        "margin-top": "20px"
    },
    exportContainer: {
        display: 'flex',
        gap: '.75rem',
        alignItems: 'center',
        cursor: 'pointer',
    },
    exportText: {
        fontWeight: '500',
        fontSize: '10px',
        lineHeight: '15px',
        color: '#000000',
    },
    exportIconContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '2.25rem',
        height: '2.25rem',
        backgroundColor: '#FFFFFF',
        borderRadius: '.5rem',
    },
    resetFiltersContainer: {
        display: 'flex',
        gap: '.5rem',
        alignItems: 'center',
        cursor: 'pointer',
        width: 'fit-content',
    },
    resetFiltersText: {
        fontWeight: '500',
        fontSize: '12px',
        lineHeight: '15px',
        color: 'rgba(6, 26, 66, 0.3)',
    },
};
