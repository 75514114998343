import Axios from 'axios';
import { OrderLogDTO } from '../dtos/orderLogDTO';
import Service from './Service';

async function getByOrderId(orderId: string): Promise<any> {
  return Service.execute(
    Axios.get(
      `${Service.API_URL}/v1/api/order-logs/${orderId}`,
      Service.config()
    )
  );
}

async function createOrderLog(orderLogDTO: OrderLogDTO) {
  return Service.execute(
    Axios.post(
      `${Service.API_URL}/v1/api/order-logs`,
      orderLogDTO,
      Service.config()
    )
  );
}

let exported = { getByOrderId, createOrderLog };

export default exported;
