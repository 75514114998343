import React from 'react';
import Translation from '../translation';
import { InputWithLabelProps } from './props';
import './style.css'

const InputWithLabel = ({ label, children, style }: InputWithLabelProps) => {



    return (
        <div className={`input-with-label-container`} style={{ ...style }}>
            <div className='input-with-label-title'>
                <Translation>
                    {label}
                </Translation>
            </div>
            {children}
        </div>
    )
}

export default InputWithLabel;