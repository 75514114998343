import { createContext, useContext } from "react";
import { EnumPopupIndex } from "./props";

export const PopupContext = createContext({
    showPopup: function (index: EnumPopupIndex) { }
})

export const usePopupContext = () => {
    return useContext(PopupContext)
}

