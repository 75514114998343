import React from "react";
import { PrintTicketRestaurantCardProps } from "./props";
import { StyleSheet, Text, View } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  orderCard: {
    display: "flex",
    flexDirection: "column",
    border: "1 solid #000",
    borderRadius: 3,
    padding: 9,
    marginTop: 12,
  },

  itemLine: {
    display: "flex",
    flexDirection: "row",
    marginTop: 9,
    width: "100%",
  },

  cardTitle: {
    fontSize: 11,
    paddingBottom: 9,
    borderBottom: "1 solid #000",
    fontFamily: "Poppins",
    fontWeight: 700,
  },

  itemName: {
    flexBasis: "50%",
    fontSize: 9,
    fontFamily: "Poppins",
    fontWeight: 500,
  },

  itemQuantityGreaterThanOne: {
    fontSize: 8,
    fontFamily: "Poppins",
    fontWeight: 600,
  },

  itemQuantity: {
    fontSize: 8,
    fontFamily: "Poppins",
    fontWeight: 600,
  },

  itemPriceContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    flexBasis: "40%",
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: 10,
  },
  blackBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: 13,
    width: 15,
    backgroundColor: "black",
    color: "white",
    borderRadius: 2,
  },
  noBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: 13,
    width: 15,
  },
  itemQuantityContainer: {
    flexBasis: "10%",
  },
});

const PrintTicketRestaurantCard = ({ restaurantName, items }: PrintTicketRestaurantCardProps) => {
  return (
    <View wrap={false} style={styles.orderCard}>
      <Text style={styles.cardTitle}>{restaurantName}</Text>
      {items.map((item, index) => (
        <View key={index} style={styles.itemLine}>
          <Text style={styles.itemName}>{item.name}</Text>
          <View style={styles.itemQuantityContainer}>
            {(item.quantity ?? 0) > 1 ? (
              <View style={styles.blackBox}>
                <Text style={styles.itemQuantityGreaterThanOne}>
                  {item.quantity}
                </Text>
              </View>
            ) : (
              <View style={styles.noBox}>
                <Text style={styles.itemQuantity}>{item.quantity}</Text>
              </View>
            )}
          </View>
          <View style={styles.itemPriceContainer}>
            <Text>
              {item.totalAmount?.toFixed(2).toString().replace(".", ",")}€
            </Text>
          </View>
        </View>
      ))}
    </View>
  );
};

export default PrintTicketRestaurantCard;
