import React from 'react';
import SelectedItem from '../../../selected-item';
import { CompanyListProps } from './props';

const CompanyList = ({ companies, handleClose }: CompanyListProps) => {
  return (
    <>
      {companies.map((item) => {
        return (
          <SelectedItem
            item={item}
            handleClose={() => handleClose(item.id as string)}
            key={item.id}
          />
        );
      })}
    </>
  );
};

export default CompanyList;
