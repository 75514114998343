import React from "react";
import { Document, Page, StyleSheet } from "@react-pdf/renderer";
import { PrintTicketProps } from "./props";
import _ from "lodash";
import JsBarcode from "jsbarcode";
import PrintTicketHeader from "./print-ticket-header";
import PrintTicketBody from "./print-ticket-body";
import PrintTicketFooter from "./print-ticket-footer";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    paddingBottom: 100,
  },

  ticketBody: {
    padding: "15 16 0 18",
  },

  bodyTitle: {
    fontSize: 11,
    fontFamily: "Poppins",
    fontWeight: 700,
  },
});

const PrintTicket = ({ orders }: PrintTicketProps) => {
  return (
    <Document>
      {orders.map((order) => {
        const itemsByRestaurants = _.groupBy(order.items, "restaurantName");
        const canvas = document.createElement("canvas");
        JsBarcode(canvas, order.orderTicketId ?? "", {
          displayValue: false,
          width: 1,
          height: 30,
        });
        const barcode = canvas.toDataURL();
        return (
          <Page key={order.id} size='A5' style={styles.page}>
            <PrintTicketHeader order={order} />
            <PrintTicketBody itemsByRestaurants={itemsByRestaurants} />
            <PrintTicketFooter order={order} barcode={barcode} />
          </Page>
        );
      })}
    </Document>
  );
};

export default PrintTicket;
