import React, { useRef } from "react";
import BackgroundDetailsForm from "../../../../../../../../components/background-details-form";
import { ProductDescriptionProps } from "./props";
import { Editor } from "@tinymce/tinymce-react";
import { Editor as EditorClass } from "tinymce";
import TitleInputGroup from "../../../../../../../../components/title-input-group";

const ProductDescription = ({ product, dispatch }: ProductDescriptionProps) => {
  // Attributes
  const editorRef = useRef(null);

  const handleChange = (value: any, editor: EditorClass) => {
    dispatch({
      type: "productChanged",
      target: "description",
      payload: value,
    });
  };

  return (
    <BackgroundDetailsForm>
      <TitleInputGroup title="Dish description" />
      <Editor
        apiKey={process.env.REACT_APP_TINY_API_KEY}
        value={product?.description}
        ref={editorRef}
        onEditorChange={(a, e) => handleChange(a, e)}
        init={{
          directionality: "ltr",
          inline_styles: true,
          menubar: "",
          font_size_formats:
            "6px 8px 10px 11px 12px 14px 16px 18px 20px 22px 24px 28px 32px 34px 38px 42px",
          font_family_formats:
            "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Oswald=oswald; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
          plugins: [
            "advlist",
            "autolink",
            "lists",
            "paste",
            "link",
            "charmap",
            "preview",
            "anchor",
            "searchreplace",
            "visualblocks",
            "code",
            "fullscreen",
            "insertdatetime",
            "table",
            "code",
            "help",
            "wordcount",
          ],
          toolbar:
            "undo redo | blocks | image |" +
            "bold italic underline strikethrough | fontsize fontfamily forecolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | ",
          content_style: "img { border-radius: 1rem}",
        }}
      />
    </BackgroundDetailsForm>
  );
};

export default ProductDescription;
