import React from "react";

import Lottie from "react-lottie";
import animationData from "./assets/loading.json";
import { BusyIndicatorProps } from "./props";
// style
import "./style.css";

const BusyIndicator = (props: BusyIndicatorProps) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  if (props.queries) {
    if (props.queries.find((elt: any) => elt.isLoading)) {
      return (
        <div className="busy-indicator-div-container">
          <div className="busy-indicator-div">
            <Lottie options={defaultOptions} height={300} width={300} />
          </div>
        </div>
      );
    }
  }
  if (props.query) {
    if (props.query.isLoading) {
      return (
        <div className="busy-indicator-div-container">
          <div className="busy-indicator-div">
            <Lottie options={defaultOptions} height={300} width={300} />
          </div>
        </div>
      );
    }

    if (props.query.status !== "success" && props.query.status !== "idle") {
      return <div>{props.query.status}</div>;
    }
  }

  return props.children;
};

export default BusyIndicator;
