export interface OrderLogDTO {
  id?: string;
  orderId: string;
  message?: string;
  orderLogType: OrderLogType | number;
  creationDate?: string;
}

export enum OrderLogType {
  Unknown = 'UNKNOWN',
  Printed = 'PRINTED',
  Clicked = 'CLICKED',
}
