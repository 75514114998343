import {
	CompanyReqCreateDTO,
	OrderTimingType,
} from '../../../../../../dtos/go-api';

export const initialState: CompanyReqCreateDTO = {
	vatNumber: '',
	name: '',
	address: {
		zipCode: '',
		streetName: '',
		country: '',
		cityName: '',
		number: '',
	},
	deliveryComments: '',
	logo: '',
	mobileLogo: '',
	orderTimingType: OrderTimingType.NOON,
	code: '',
	kitchenClosingHourBegin: '',
	kitchenClosingHourEnd: '',
	kitchenPriority: 0,
};

export const actions = {
	serverChanged: 'serverChanged',
	companyChanged: 'companyChanged',
	addressChanged: 'addressChanged',
};

export interface ActionInterface {
	target: string;
	type: string;
	payload: any;
}

export const companyReducer = (
	state: CompanyReqCreateDTO,
	action: ActionInterface
): CompanyReqCreateDTO => {
	switch (action.type) {
		case actions.serverChanged:
			return {
				...state,
				...action.payload,
			};

		case actions.companyChanged:
			return {
				...state,
				[action.target]: action.payload,
			};

		case actions.addressChanged: {
			return {
				...state,
				address: {
					...state.address,
					[action.target]: action.payload,
				},
			};
		}
		default:
			return state;
	}
};
