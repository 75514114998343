import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PromoDetail from './modules/detail';
import PromoList from './modules/list';

const Promo = () => {
    return (
        <Switch>
            <Route path='/dashboard/promos/list' component={PromoList} />
            <Route path='/dashboard/promos/:promoId/details' component={PromoDetail} />
            <Route path='/dashboard/promos/details' component={PromoDetail} />
            <Route path='/dashboard/promos/' render={() => <Redirect to="/dashboard/promos/list" />} />
        </Switch>
    )
}

export default Promo;