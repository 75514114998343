import React, { useState } from 'react';
import Translation from '../../../../../../../../../../components/translation';
import TitleInputGroup from '../../../../../../../../components/title-input-group';
import { ReactComponent as CheckIcon } from './assets/check.svg';
import { ReactComponent as UnCheckIcon } from './assets/uncheck.svg';
import './style.css'

const InvitationMail = () => {

    // Attributes
    const [isChecked, setIsChecked] = useState(false)

    return (
        <div>
            <TitleInputGroup title='Invitation mail' />
            <div className='invitation-mail-paraph'>
                <Translation>
                    You can send a invitation mail to the user. He will be redirected to his company's application and asked to finalize his account by creating his password.
                </Translation>
            </div>
            <div className="invitation-checkbox-container">
                {isChecked ?
                    (
                        <CheckIcon onClick={() => setIsChecked(false)} style={{ width: 18, height: 18 }} />
                    )
                    :
                    (
                        <UnCheckIcon onClick={() => setIsChecked(true)} style={{ width: 18, height: 18 }} />
                    )
                }
                <div className='invitation-mail-text'><Translation>Send invitation mail</Translation></div>
            </div>
        </div>
    )
}

export default InvitationMail;