import React from 'react';
import TitleInputGroup from '../title-input-group';
import InputGroupTitle from '../title-input-group';
import { GroupInputDetailsProps } from './props';
import './style.css';

const GroupInputDetails = ({ title, children }: GroupInputDetailsProps) => {

    return (
        <div className='details-form-group-container'>
            <TitleInputGroup title={title} />
            {children}
        </div>
    )
}

export default GroupInputDetails;