import React from 'react';
import InputText from '../../../../../../../../../../components/input-text';
import InputWithLabel from '../../../../../../../../../../components/input-with-label';
import GroupInputDetails from '../../../../../../../../components/group-input-details';
import { UserActions, USER_FIELDS } from '../../reducer';
import { UserInformationProps } from './props';
import './style.css'

const UserInformation = ({ user, dispatch }: UserInformationProps) => {
    return (
        <GroupInputDetails title='User information'>
            <div className="input-group-flex-row large">
                <InputWithLabel label='FIRST NAME'>
                    <InputText required={true} value={user.firstName} onChange={(event: any) => dispatch({ target: USER_FIELDS.FIRSTNAME, payload: event.target.value, type: UserActions.USER_CHANGED })} placeholder='insert first name' />
                </InputWithLabel>
                <InputWithLabel label='LAST NAME'>
                    <InputText required={true} value={user.lastName} onChange={(event: any) => dispatch({ target: USER_FIELDS.LASTNAME, payload: event.target.value, type: UserActions.USER_CHANGED })} placeholder='insert last name' />
                </InputWithLabel>
                <InputWithLabel label='EMAIL ADDRESS'>
                    <InputText required={true} value={user.email} onChange={(event: any) => dispatch({ target: USER_FIELDS.EMAIL, payload: event.target.value, type: UserActions.USER_CHANGED })} placeholder='insert email' />
                </InputWithLabel>
            </div>
        </GroupInputDetails>

    )
}

export default UserInformation;