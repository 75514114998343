import './style.css';
import Modal from 'react-modal';
import { reducer } from './reducer';
import { initialPromo } from './reducer';
import PromoDate from './components/date';
import PromoTypes from './components/type';
import PromoPrice from './components/price';
import Form from '../../../../components/form';
import Popup from '../../../../components/popup';
import InputPromoCode from './components/promo-code';
import Header from '../../../../../../components/header';
import { useHistory, useParams } from 'react-router-dom';
import React, { useEffect, useReducer, useState } from 'react';
import PageContainer from '../../../../components/page-container';
import MenuContainer from '../../../../components/menu-container';
import PopupButton from '../../../../../../components/popup-button';
import MultiSelectCompanies from './components/multi-select-companies';
import BusyIndicator from '../../../../../../components/busy-indicator';
import { customStyles } from '../../../../components/popup/customStyle';
import MultiSelectRestaurants from './components/multi-select-restaurants';
import { EnumPopupIndex } from '../../../../components/popup-context/props';
import { usePopupContext } from '../../../../components/popup-context/context';
import SaveButton from '../../../../../../components/header/components/save-button';
import { ReactComponent as DeleteIcon } from '../../../../../../assets/delete-icon.svg';
import { SAVE_BUTTON_TYPE } from '../../../../../../components/header/components/save-button/props';
import Service from '../../../../../../services/Service';
import { useTranslation } from '../../../../../../components/translation-context/TranslationContext';
import PromoUsageTypes from './components/usage-type';
import {
  useCreatePromoCode,
  useDeletePromoCodeById,
  useGetPromoCodeById,
  useUpdatePromoCodeById,
} from '../../../../../../services/go-api/promo-code/promo-code';
import {
  PromoCodeReqCreateDTO,
  PromoType,
  PromoUsageType,
} from '../../../../../../dtos/go-api';
import { useGetAllCompanies } from '../../../../../../services/go-api/company/company';
import { useGetRestaurants } from '../../../../../../services/go-api/restaurant/restaurant';

const PromoDetail = () => {
  /**
   * Attributes
   */
  const history = useHistory();
  const { promoId }: any = useParams();
  const { translate } = useTranslation();
  const { showPopup } = usePopupContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [promo, dispatch] = useReducer(reducer, initialPromo);
  const promoCode = useGetPromoCodeById(promoId);

  const { data: companies } = useGetAllCompanies(
    { size: 1000 },
    { query: { refetchOnWindowFocus: false } }
  );
  const { data: restaurants } = useGetRestaurants(
    { size: 1000 },
    { query: { refetchOnWindowFocus: false } }
  );

  const { mutateAsync: createPromoCode } = useCreatePromoCode();
  const { mutateAsync: updatePromoCode } = useUpdatePromoCodeById(promoId);
  const { mutateAsync: deletePromoCode } = useDeletePromoCodeById(promoId);

  /**
   * Effects
   */
  useEffect(() => {
    if (promoCode.data) {
      dispatch({
        type: 'serverChanged',
        target: 'self',
        payload: promoCode.data,
      });
    }
  }, [promoCode.data]);

  /**
   * Handlers
   */
  async function handleSave() {
    for (const attribute in promo) {
      if (attribute === 'name' || attribute === 'amount') {
        if (!promo[attribute] || promo[attribute].length === 0) {
          showPopup(EnumPopupIndex.MANDATORY);
          return;
        }
      }
      if (attribute === 'type' && promo[attribute] === -1) {
        showPopup(EnumPopupIndex.MANDATORY);
        return;
      }
    }

    if (promoId) {
      updatePromoCode(
        {
          promoCodeId: promoId,
          data: {
            amount: Number(promo.amount),
            companies: promo.companies,
            name: promo.name,
            promoUsageType: promo.promoUsageType as PromoUsageType,
            restaurants: promo.restaurants,
            startDate: promo.startDate,
            type: promo.type as PromoType,
            endDate: promo.endDate,
          },
        },
        {
          onSuccess: async () => {
            await promoCode.refetch();
            Service.success.push(translate('modif_promoCode_success'));
            history.push('/dashboard/promos');
          },
        }
      );
    } else {
      // Create dto
      const data: PromoCodeReqCreateDTO = {
        amount: Number(promo.amount),
        companies: promo.companies,
        name: promo.name,
        promoUsageType: promo.promoUsageType as PromoUsageType,
        restaurants: promo.restaurants,
        startDate: promo.startDate,
        type: promo.type as PromoType,
      };

      // Add endDate
      if (promo.endDate) {
        data.endDate = promo.endDate;
      }

      createPromoCode(
        {
          data,
        },
        {
          onSuccess: () => {
            Service.success.push(translate('add_promoCode_success'));
            history.push('/dashboard/promos');
          },
        }
      );
    }
  }

  function handleDelete() {
    deletePromoCode(
      { promoCodeId: promoId },
      {
        onSuccess: () => {
          Service.success.push(translate('delete_promoCode_success'));
          history.push(`/dashboard/promos`);
        },
      }
    );
  }

  return (
    <BusyIndicator query={promoCode}>
      <PageContainer>
        <Header
          title={promoCode.data && promoId ? promo.name : 'Add new promo code'}
          hasBackButton={true}
          saveButton={
            <SaveButton
              handleClick={handleSave}
              type={
                promoId ? SAVE_BUTTON_TYPE.SAVE : SAVE_BUTTON_TYPE.SAVE_AND_ADD
              }
              handleDelete={() => setIsModalOpen(true)}
            />
          }
        />
        <MenuContainer>
          <Form>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className='details-input-group-container'>
                <InputPromoCode
                  value={promo.name}
                  onChange={(event: any) =>
                    dispatch({
                      type: 'stateChanged',
                      target: 'name',
                      payload: event.target.value,
                    })
                  }
                />
                <div style={{ marginRight: 10 }} />
                <div
                  style={{ display: 'flex', width: '618px', minWidth: '618px' }}
                >
                  <PromoTypes value={promo.type} dispatch={dispatch} />
                  <div style={{ marginRight: 10 }} />
                  <PromoUsageTypes
                    value={promo.promoUsageType}
                    dispatch={dispatch}
                  />
                </div>
              </div>
              <div className='details-input-group-container'>
                <PromoPrice
                  promo={promo}
                  dispatch={dispatch}
                  value={promo.amount}
                  onChange={(event: any) =>
                    dispatch({
                      type: 'stateChanged',
                      target: 'amount',
                      payload: event.target.value,
                    })
                  }
                />
                <div style={{ marginRight: 10 }} />
                <PromoDate promo={promo} dispatch={dispatch} />
              </div>
              <div className='details-input-group-container'>
                {companies?.data && (
                  <MultiSelectCompanies
                    promo={promo}
                    promoCompanies={
                      promoCode.data?.companies ? promoCode.data?.companies : []
                    }
                    title='Applies to companies'
                    dispatch={dispatch}
                    companies={companies.data}
                  />
                )}
                <div style={{ marginRight: 10 }} />
                {restaurants?.data && (
                  <MultiSelectRestaurants
                    promo={promo}
                    promoRestaurants={
                      promoCode.data?.restaurants
                        ? promoCode.data?.restaurants
                        : []
                    }
                    title='Applies to restaurants'
                    dispatch={dispatch}
                    restaurants={restaurants.data}
                  />
                )}
              </div>
            </div>
          </Form>
        </MenuContainer>
        <Modal style={customStyles} isOpen={isModalOpen}>
          <Popup
            title='Are you sure you want to delete this promo code?'
            icon={<DeleteIcon style={{ width: 66, height: 69 }} />}
            buttons={[
              <PopupButton
                backgroundColor='grey'
                handleClick={() => setIsModalOpen(false)}
                title='Cancel'
              />,
              <PopupButton
                backgroundColor='yellow'
                handleClick={handleDelete}
                title='Yes, delete'
              />,
            ]}
          />
        </Modal>
      </PageContainer>
    </BusyIndicator>
  );
};

export default PromoDetail;
