import React, { useEffect, useState } from 'react';
import { ReactComponent as SelectedIcon } from './assets/selected.svg';
import { ReactComponent as UnSelectedIcon } from './assets/unselected.svg';
import { CheckInputProps } from './props';
import './style.css';

const DoubleCheckbox = ({
  firstChoice,
  secondChoice,
  selectedChoice,
  handleCheck,
}: CheckInputProps) => {
  // Attributes
  const [selectedValue, setSelectedValue] = useState(selectedChoice);

  // Effects
  useEffect(() => {
    setSelectedValue(selectedChoice);
  }, [selectedChoice]);

  // Handlers
  function handleClick(value: any) {
    setSelectedValue(value);
    handleCheck(value);
  }

  return (
    <div className='check-input-container'>
      <div
        className='check-input-first-container'
        onClick={(event) => handleClick(firstChoice)}
      >
        {selectedValue === firstChoice ? (
          <SelectedIcon className='check-input-icon' />
        ) : (
          <UnSelectedIcon className='check-input-icon' />
        )}
        <div style={{ fontSize: '14px', width: 'max-content' }}>
          {firstChoice}
        </div>
      </div>
      <div style={{ marginTop: 10 }} />
      <div
        className='check-input-first-container'
        onClick={(event) => handleClick(secondChoice)}
      >
        {selectedValue === secondChoice ? (
          <SelectedIcon className='check-input-icon' />
        ) : (
          <UnSelectedIcon className='check-input-icon' />
        )}
        <div style={{ fontSize: '14px', width: 'max-content' }}>
          {secondChoice}
        </div>
      </div>
    </div>
  );
};

export default DoubleCheckbox;
