import React, { useEffect, useState } from 'react';
import {
  CompanyResDTO,
  NotificationCompany,
  NotificationResDTO,
} from '../../../../../../../../dtos/go-api';
import { useGetAllCompanies } from '../../../../../../../../services/go-api/company/company';
import BackgroundDetailsForm from '../../../../../../components/background-details-form';
import GroupInputDetails from '../../../../../../components/group-input-details';
import InputSelect from '../../../../../../components/input-select';
import SelectedItem from '../../../../../promo/modules/detail/components/selected-item';
import { NotificationReducerAction } from '../../reducer';
import './style.css';

interface CompanySelectOptions {
  value: string;
  label: string;
}

enum CompanySelect {
  AllCompanies = 'All companies',
  SelectedCompanies = 'Selected companies',
}

interface NotificationsCompaniesSectionProps {
  notification: NotificationResDTO;
  dispatch: React.Dispatch<NotificationReducerAction>;
}

export default function NotificationsCompaniesSection({
  notification,
  dispatch,
}: NotificationsCompaniesSectionProps) {
  // Attributes
  const companiesReq = useGetAllCompanies(
    { size: 1000 },
    { query: { refetchOnWindowFocus: false } }
  );
  const companies = companiesReq.data && companiesReq.data.data;
  const [companySelectOptions, setCompanySelectOptions] = useState<
    CompanySelectOptions[]
  >([]);
  const [selectedRadio, setSelectedRadio] = useState(
    notification?.companies?.length === 0
      ? CompanySelect.AllCompanies
      : CompanySelect.SelectedCompanies
  );

  // Effects
  useEffect(() => {
    const companySelectOptions = companies?.map((company: CompanyResDTO) => {
      const option = {
        value: company.id,
        label: company.name,
      };

      return option;
    });

    if (companySelectOptions !== undefined) {
      setCompanySelectOptions(companySelectOptions as CompanySelectOptions[]);
    }
  }, [companies]);

  useEffect(() => {
    setSelectedRadio(
      notification?.companies?.length === 0
        ? CompanySelect.AllCompanies
        : CompanySelect.SelectedCompanies
    );
  }, [notification.companies]);

  // Functions
  function handleCompanySelect(companyId: string) {
    // check if company exists
    const company = companies?.find(
      (company: CompanyResDTO) => company.id === companyId
    );

    // check if company is already selected
    if (notification?.companies?.find((c) => c.id === companyId)) {
      return;
    }

    if (company) {
      const newNotificationCompany: NotificationCompany = {
        id: company.id as string,
        name: company.name as string,
      };

      dispatch({
        type: 'stateChanged',
        target: 'companies',
        payload: [...(notification.companies ?? []), newNotificationCompany],
      });
    }
  }

  function handleCompanySelectRadio(companySelect: CompanySelect) {
    switch (companySelect) {
      case CompanySelect.AllCompanies:
        setSelectedRadio(CompanySelect.AllCompanies);
        dispatch({
          type: 'stateChanged',
          target: 'companies',
          payload: [],
        });
        break;
      case CompanySelect.SelectedCompanies:
        setSelectedRadio(CompanySelect.SelectedCompanies);
        dispatch({
          type: 'stateChanged',
          target: 'companies',
          payload: notification.companies ?? [],
        });
        break;
      default:
        break;
    }
  }

  function handleRemoveSelectedCompany(companyId: string) {
    if (selectedRadio === CompanySelect.AllCompanies) {
      const newCompanies: NotificationCompany[] = companies
        ?.filter((company: CompanyResDTO) => company.id !== companyId)
        ?.map((elt: CompanyResDTO) => ({
          id: elt.id,
          name: elt.name,
        })) as NotificationCompany[];

      setSelectedRadio(CompanySelect.SelectedCompanies);
      dispatch({
        type: 'stateChanged',
        target: 'companies',
        payload: newCompanies,
      });
      return;
    }

    // Remove company from notification.companies
    const newCompanies: NotificationCompany[] =
      notification.companies?.filter(
        (company: NotificationCompany) => company.id !== companyId
      ) ?? [];

    dispatch({
      type: 'stateChanged',
      target: 'companies',
      payload: newCompanies,
    });
  }

  // Render
  return (
    <BackgroundDetailsForm>
      <GroupInputDetails title='notifSelectCompanyTitle'>
        <div className='notif-companies-select-container'>
          {/* LEFT SECTION */}
          <div className='left-section'>
            {/* RADIO */}
            <div className='companies-select-radio-container'>
              <div>
                <input
                  type='radio'
                  checked={selectedRadio === CompanySelect.SelectedCompanies}
                  value={CompanySelect.SelectedCompanies}
                  name='selected-companies'
                  onClick={() =>
                    handleCompanySelectRadio(CompanySelect.SelectedCompanies)
                  }
                />
                <span
                  style={{
                    marginLeft: 8,
                    fontSize: 14,
                    fontWeight: 500,
                    lineHeight: '30px',
                    fontFamily: 'Poppins',
                  }}
                >
                  Selected companies
                </span>
              </div>
              <div>
                <input
                  type='radio'
                  checked={selectedRadio === CompanySelect.AllCompanies}
                  value={CompanySelect.AllCompanies}
                  name='selected-companies'
                  onClick={() =>
                    handleCompanySelectRadio(CompanySelect.AllCompanies)
                  }
                />
                <span
                  style={{
                    marginLeft: 8,
                    fontSize: 14,
                    fontWeight: 500,
                    lineHeight: '30px',
                    fontFamily: 'Poppins',
                  }}
                >
                  All companies
                </span>
              </div>
            </div>

            {/* SELECT */}
            <div
              style={{
                display:
                  selectedRadio === CompanySelect.AllCompanies ? 'none' : '',
              }}
            >
              <InputSelect
                options={companySelectOptions}
                initialValue={notification.companies}
                handleSelect={(e) => handleCompanySelect(e)}
              />
            </div>
          </div>

          {/* RIGHT SECTION */}
          <div className='right-section'>
            {selectedRadio === CompanySelect.SelectedCompanies &&
              notification.companies
                ?.sort((a, b) => a.name.localeCompare(b.name))
                .map((company: NotificationCompany) => (
                  <div>
                    <SelectedItem
                      item={company}
                      handleClose={() =>
                        handleRemoveSelectedCompany(company.id)
                      }
                      key={`selected-item-${company.id}`}
                    />
                  </div>
                ))}
            {selectedRadio === CompanySelect.AllCompanies &&
              // sort companies by name
              companies &&
              companies
                ?.sort((a, b) =>
                  (a.name as string).localeCompare(b?.name as string)
                )
                ?.map((company: CompanyResDTO) => (
                  <SelectedItem
                    item={company}
                    handleClose={() =>
                      handleRemoveSelectedCompany(company.id as string)
                    }
                    key={`selected-item-${company.id}`}
                  />
                ))}
          </div>
        </div>
      </GroupInputDetails>
    </BackgroundDetailsForm>
  );
}
