import { CompanyDTO } from "./companyDTO";
import { UserDTO } from "./userDTO";

export interface EmployeeDTO {
    id: string,
    company: CompanyDTO,
    companyId: string,
    user: UserDTO,
    userId: string,
    employeeType: EmployeeType,
}

export enum EmployeeType {
    UNKNOWN = -1,
    ADMIN,
    SUPERIOR,
    EMPLOYEE,
}