import React, { useEffect, useState } from "react";
import PageContainer from "../../../../components/page-container";
import Lottie from "react-lottie";
import animationData from "./assets/loading.json";
import NotificationsListHeader from "./components/header";
import NotificationsFilters from "./components/filters";
import NotificationsUpcomingTable from "./components/tables/upcoming-table";
import NotificationsSentTable from "./components/tables/sent-table";
import NotificationsRecurringTable from "./components/tables/recurring-table";
import "./style.css";
import { useGetNotifications } from "../../../../../../services/go-api/notifications/notifications";
import { NotificationDestinationType, NotificationType } from "../../../../../../dtos/go-api";
import useQueryString from "../../../../../../hooks/useQueryString";

const MAX_SIZE_PER_PAGE = 30;

const NotificationsList = () => {
	// Attributes
	const [currentPage, setCurrentPage] = useState<number>(0);
	const [search, setSearch] = useState<string>("");
	const [currentTotalPages, setCurrentTotalPages] = useState<number>(0);
	const [selectedType, setSelectedType] = useState<NotificationType>("SENT");
	const [selectedDestinationType, setSelectedDestinationType] = useState<NotificationDestinationType>("COMPANY");
	const notificationListType: string | null = useQueryString().get("type");

	const {
		data: notifications,
		isSuccess,
		isLoading,
		refetch: refetchNotification,
	} = useGetNotifications({
		size: MAX_SIZE_PER_PAGE,
		page: currentPage,
		notificationType: selectedType,
		q: search,
		destinationType: selectedDestinationType,
	});

	// Effects
	useEffect(() => {
		if (notifications?.nbPages !== undefined) {
			setCurrentTotalPages(notifications?.nbPages);
		}
	}, [notifications?.nbPages]);

	useEffect(() => {
		if (notificationListType) {
			switch (notificationListType) {
				case NotificationType.RECURRING:
					setSelectedType(NotificationType.RECURRING);
					break;
				case NotificationType.SENT:
					setSelectedType(NotificationType.SENT);
					break;
				case NotificationType.UPCOMING:
					setSelectedType(NotificationType.UPCOMING);
					break;
				default:
					setSelectedType(NotificationType.SENT);
					break;
			}
		}
	}, [notificationListType]);

	// Render
	return (
		<PageContainer>
			<NotificationsListHeader />
			<NotificationsFilters page={currentPage} totalPages={currentTotalPages} setPage={setCurrentPage} selectedType={selectedType} selectedDestinationType={selectedDestinationType} setSelectedDestinationType={setSelectedDestinationType} setSelectedType={setSelectedType} search={search} setSearch={setSearch} />
			<div className="notifications-table-container">
				{isLoading ? (
					<tr className="notifications-table-placeholder-container">
						<Lottie
							options={{
								loop: true,
								autoplay: true,
								animationData: animationData,
								rendererSettings: {
									preserveAspectRatio: "xMidYMid slice",
								},
							}}
							height={300}
							width={300}
						/>
					</tr>
				) : (
					<>{selectedType === "SENT" ? <NotificationsSentTable destinationType={selectedDestinationType} data={isSuccess ? notifications?.data : []} /> : selectedType === "UPCOMING" ? <NotificationsUpcomingTable destinationType={selectedDestinationType} data={isSuccess ? notifications?.data : []} refetchNotification={refetchNotification} /> : <NotificationsRecurringTable data={isSuccess ? notifications?.data : []} refetchNotification={refetchNotification} />}</>
				)}
			</div>
		</PageContainer>
	);
};

export default NotificationsList;
