import React from 'react';
import InputText from '../../../../../../../../components/input-text';
import InputWithLabel from '../../../../../../../../components/input-with-label';
import GroupInputDetails from '../../../../../../components/group-input-details';
import InputGroup from '../../../../../../components/input-group';
import { actions } from '../../reducer';
import { AddressProps, ADDRESS_FIELDS } from './props';

const Address = ({ company, dispatch }: AddressProps) => {

    // Attributes

    return (
        <InputGroup size='medium'>
            <GroupInputDetails title='Address'>
                <InputGroup size='medium'>
                    <InputWithLabel label='STREET'>
                        <InputText
                            size="large"
                            placeholder='insert street'
                            onChange={(event: any) => dispatch({ type: actions.addressChanged, target: ADDRESS_FIELDS.STREETNAME, payload: event.target.value })}
                            value={company.address.streetName}
                            required={true}
                        />
                    </InputWithLabel>
                </InputGroup>
                <InputGroup size='medium'>
                    <InputWithLabel label='NUMBER'>
                        <InputText
                            size="medium"
                            placeholder='insert number'
                            onChange={(event: any) => dispatch({ type: actions.addressChanged, target: ADDRESS_FIELDS.NUMBER, payload: event.target.value })}
                            value={company.address.number}
                            required={true}
                        />
                    </InputWithLabel>
                    <InputWithLabel label='ZIPCODE'>
                        <InputText
                            size="medium"
                            placeholder='insert zipCode'
                            onChange={(event: any) => dispatch({ type: actions.addressChanged, target: ADDRESS_FIELDS.ZIPCODE, payload: event.target.value })}
                            value={company.address.zipCode}
                            required={true}
                        />
                    </InputWithLabel>
                </InputGroup>
                <InputGroup>
                    <InputWithLabel label='CITY'>
                        <InputText
                            placeholder='insert city'
                            onChange={(event: any) => dispatch({ type: actions.addressChanged, target: ADDRESS_FIELDS.CITYNAME, payload: event.target.value })}
                            value={company.address.cityName}
                            required={true}
                        />
                    </InputWithLabel>
                    <InputWithLabel label='COUNTRY'>
                        <InputText
                            placeholder='insert country'
                            onChange={(event: any) => dispatch({ type: actions.addressChanged, target: ADDRESS_FIELDS.COUNTRY, payload: event.target.value })}
                            value={company.address.country}
                            required={true}
                        />
                    </InputWithLabel>
                </InputGroup>
            </GroupInputDetails>
        </InputGroup>
    )
}

export default Address