import { formatISO9075 } from 'date-fns';
import React, { useEffect, useState } from 'react';
import InputWithLabel from '../../../../../../../../components/input-with-label';
import BackgroundDetailsForm from '../../../../../../components/background-details-form';
import CheckboxSquare from '../../../../../../components/checkbox-square';
import GroupInputDetails from '../../../../../../components/group-input-details';
import InputDate from '../../../../../../components/input-date';
import InputTime from '../../../../../../components/input-time';
import { PromoDateProps } from './props';
import './style.css';

const PromoDate = ({ promo, dispatch }: PromoDateProps) => {
  // Attributes
  const [hasStartDate, setHasStartDate] = useState(false);
  const [hasEndDate, setHasEndDate] = useState(false);
  // Effects
  useEffect(() => {
    setHasEndDate(promo.endDate ? true : false);
    setHasStartDate(promo.startDate ? true : false);
  }, [promo]);

  // Handlers
  function renderDate(dateType: 'startDate' | 'endDate') {
    // Get startDate or endDate
    const formatDate = new Date(promo[dateType] ?? '');

    // Extract time
    const date = formatISO9075(formatDate, {
      representation: 'date',
    });

    return date;
  }

  function renderTime(dateType: 'startDate' | 'endDate') {
    // Get startDate or endDate
    const date = new Date(promo[dateType] ?? '');

    // Extract time
    const time = formatISO9075(date, {
      representation: 'time',
    });

    return time;
  }

  function renderTimePayload(value: string) {
    // Parse hours & minutes from input time
    const hours = Number(value.split(':')[0]);
    const minutes = Number(value.split(':')[1]);
    const startDate = new Date(promo.startDate);

    // Transform to UTC iso string
    const utcDate = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate(),
      hours,
      minutes
    ).toISOString();

    return utcDate;
  }

  function renderDatePayload(value: string, field: 'startDate' | 'endDate') {
    // Format input values to Date
    const newDateValues = new Date(value);

    // Format current date to Date
    const currentDate = promo[field]
      ? new Date(promo[field] ?? '')
      : new Date();

    // Compose new date
    const newDate = new Date(
      newDateValues.getFullYear(),
      newDateValues.getMonth(),
      newDateValues.getDate(),
      currentDate.getHours(),
      currentDate.getMinutes()
    ).toISOString();

    return newDate;
  }

  function handleStartDateCheck() {
    if (hasStartDate) {
      dispatch({ type: 'stateChanged', target: 'startDate', payload: '' });
      dispatch({ type: 'stateChanged', target: 'endDate', payload: '' });
    }

    setHasStartDate((previous) => !previous);
  }

  function handleEndDateCheck() {
    if (hasEndDate) {
      dispatch({ type: 'stateChanged', target: 'endDate', payload: '' });
    }

    if (!hasEndDate) {
      setHasStartDate(true);
    }
    setHasEndDate((previous) => !previous);
  }

  return (
    <BackgroundDetailsForm>
      <GroupInputDetails title='Define start and end date'>
        <div className='promo-input-date-start-end-container'>
          <div>
            <InputWithLabel label='START DATE'>
              <InputDate
                disabled={!hasStartDate}
                value={promo.startDate ? renderDate('startDate') : ''}
                onChange={(event: any) =>
                  dispatch({
                    type: 'stateChanged',
                    target: 'startDate',
                    payload: renderDatePayload(event.target.value, 'startDate'),
                  })
                }
              />
            </InputWithLabel>
            <div style={{ marginBottom: 12 }} />
            <CheckboxSquare
              value={hasStartDate}
              title='set start date'
              handleOnCheck={handleStartDateCheck}
            />
            <div style={{ marginBottom: 12 }} />
            <InputWithLabel label='START TIME'>
              <InputTime
                value={renderTime('startDate')}
                onChange={(event: any) =>
                  dispatch({
                    type: 'stateChanged',
                    target: 'startDate',
                    payload: renderTimePayload(event.target.value),
                  })
                }
              />
            </InputWithLabel>
          </div>
          <div>
            <InputWithLabel label='END DATE'>
              <InputDate
                disabled={!hasEndDate}
                value={promo.endDate ? renderDate('endDate') : ''}
                onChange={(event: any) =>
                  dispatch({
                    type: 'stateChanged',
                    target: 'endDate',
                    payload: renderDatePayload(event.target.value, 'endDate'),
                  })
                }
              />
            </InputWithLabel>
            <div style={{ marginBottom: 12 }} />
            <CheckboxSquare
              value={hasEndDate}
              title='set end date'
              handleOnCheck={handleEndDateCheck}
            />
          </div>
        </div>
      </GroupInputDetails>
    </BackgroundDetailsForm>
  );
};

export default PromoDate;
