import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import CompanyDetail from './modules/detail';
import CompanyList from './modules/list';
import CompanyRestaurants from './modules/restaurants';
import CompanyUsers from './modules/users';

const Companies = () => {
    return (
        <Switch>
            <Route path='/dashboard/companies/list' component={CompanyList} />
            <Route path='/dashboard/companies/:companyId/employees' component={CompanyUsers} />
            <Route path='/dashboard/companies/:companyId/restaurants' component={CompanyRestaurants} />
            <Route path='/dashboard/companies/:companyId/details' component={CompanyDetail} />
            <Route path='/dashboard/companies/details' component={CompanyDetail} />
            <Route path='/dashboard/companies/' render={() => <Redirect to='/dashboard/companies/list' />} />
        </Switch>
    )
}

export default Companies;