import React from "react";
import InputText from "../../../../../../../../../components/input-text";
import BackgroundDetailsForm from "../../../../../../../components/background-details-form";
import GroupInputDetails from "../../../../../../../components/group-input-details";
import { InputTemplateNameProps } from "./props";

const InputTemplateName = ({ value, onChange }: InputTemplateNameProps) => {
  return (
    <BackgroundDetailsForm style={{ height: "none" }}>
      <GroupInputDetails title="templateName">
        <InputText
          required={true}
          placeholder="new"
          value={value}
          onChange={onChange}
          size="medium"
          style={{ marginTop: 20 }}
        />
        <div style={{ marginTop: 20 }} />
      </GroupInputDetails>
    </BackgroundDetailsForm>
  );
};

export default InputTemplateName;
