import Axios from 'axios';
import { EmployeeDTO } from '../dtos/employeeDTO';
import Service from './Service';
import Generator from 'generate-password';

async function get(companyId: string, employeeId?: string): Promise<any> {
    if (employeeId) {
        return Service.execute(Axios.get(`${Service.API_URL}/v1/api/companies/${companyId}/employees/${employeeId}`, Service.config()));
    }
    return Service.execute(Axios.get(`${Service.API_URL}/v1/api/companies/${companyId}/employees`, Service.config()));
}

async function post(companyId: string, employeeDTO: EmployeeDTO) {
    return Service.execute(Axios.post(`${Service.API_URL}/v1/api/companies/${companyId}/employees`, employeeDTO, Service.config()));
}

async function deleteEmployee(companyId: string, employeeId: string) {
    return Service.execute(Axios.delete(`${Service.API_URL}/v1/api/companies/${companyId}/employees/${employeeId}`, Service.config()));
}

async function put(companyId: string, employeeDTO: EmployeeDTO) {
    return Service.execute(Axios.put(`${Service.API_URL}/v1/api/companies/${companyId}/employees`, employeeDTO, Service.config()));
}

let exported = { get, post, put, deleteEmployee };

// if(process.env.REACT_APP_MOCKED === "true")
// {
//     exported = UserCompanyMockService;
// }

export default exported;
