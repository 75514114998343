import React from 'react';
import BackgroundDetailsForm from '../../../../../../components/background-details-form';
import GroupInputDetails from '../../../../../../components/group-input-details';
import { PromoCodeInputProps } from '../props';
import InputPrice from '../../../../../../components/input-price';
import { PromoType } from '../../../../../../../../dtos/promoCodeDTO';

const PromoPrice = ({ value, onChange, dispatch, promo }: PromoCodeInputProps) => {
    return (
        <BackgroundDetailsForm style={{ height: 'none' }}>
            <GroupInputDetails title='Value'>
                <div style={{ marginTop: 20 }} />
                <InputPrice type={!promo ? 'EUR' : promo?.type === PromoType.AMOUNT ? 'EUR' : 'PERCENTAGE'} value={value} onChange={onChange} removeZero={() => dispatch({ type: 'stateChanged', target: 'amount', payload: '' })} />
            </GroupInputDetails>
        </BackgroundDetailsForm>
    )
}

export default PromoPrice;