import { CompanyDTO } from './companyDTO';
import { RestaurantDTO } from './restaurantDTO';

export interface PromoCodeDTO {
  id: string;
  name: string;
  type: PromoType;
  startDate: string;
  endDate: string | undefined;
  companyRestaurantId: string;
  amount: number;
  usage: number;
  companies: CompanyDTO[] | [];
  restaurants: RestaurantDTO[];
  promoUsageType: PromoUsageType;
}

export enum PromoType {
  UNKNOWN = "UNKNOWN",
  AMOUNT = "AMOUNT",
  PERCENTAGE = "PERCENTAGE",
}

export enum PromoUsageType {
  UNKNOWN = -1,
  ONETIMEUSE,
  MULTIPLEUSE,
}
