import React, { useState } from "react";
import Modal from "react-modal";
import CompanyRestaurantService from "../../../../../../../../services/CompanyRestaurantService";
import Toggle from "../../../../../../components/toggle";
import IndexModal from "./components/index-modal";
import { customStyles } from "../../../../../../components/popup/customStyle";
import { CompanyRestaurantCardProps } from "./props";
import "./style.css";
import { useParams } from "react-router-dom";
import CompanyRestaurantsIndex from "./components/index";

const CompanyRestaurantCard = ({
	restaurant,
	refetch,
}: CompanyRestaurantCardProps) => {
	// Attributes
	const [isModalOpen, setIsModalOpen] = useState(false);
	const { companyId }: any = useParams();

	// Handler
	async function handleAssign(isSelected: boolean) {
		await CompanyRestaurantService.put(companyId, {
			...restaurant,
			isAssigned: isSelected,
		});
		//to update the ui I have to refetch the query
		refetch();
	}

	async function handleIndexChange(indexPrice: number) {
		const copied = { ...restaurant, indexPrice };
		//updates the companyRestaurant with the new indexPrice
		await CompanyRestaurantService.put(companyId, copied);
		setIsModalOpen(false);
		//to update the ui I have to refetch the query
		refetch();
	}

	return (
		<div className="company-restaurant-card-container">
			<img
				src={restaurant.restaurant.pictureUrl}
				alt="restaurant-img"
				className="company-restaurant-card-picture"
			/>
			<div className="company-restaurant-card-footer">
				<div className="company-restaurant-card-name">
					{restaurant.restaurant.name}
				</div>
				<div className="company-restaurant-card-footer-actions">
					<Toggle
						isNotSelectedText="Not Assigned"
						isSelectedText="Assigned"
						handleClick={handleAssign}
						defaultValue={restaurant.isAssigned}
					/>
					<div>
						<CompanyRestaurantsIndex
							index={
								restaurant.indexPrice >= 0
									? restaurant.indexPrice
									: ""
							}
							handleAction={() =>
								setIsModalOpen((previous) => !previous)
							}
						/>
						{/* <ActionButton value={restaurant.indexPrice !== 100 ? restaurant.indexPrice : ''} name='index' handleAction={() => setIsModalOpen(previous => !previous)} /> */}
					</div>
				</div>
			</div>
			<Modal isOpen={isModalOpen} style={customStyles}>
				<IndexModal
					closeModal={() => setIsModalOpen(false)}
					value={restaurant.indexPrice}
					handleIndexChange={handleIndexChange}
				/>
			</Modal>
		</div>
	);
};

export default CompanyRestaurantCard;
