import React, { useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AddButton from '../../../../../../../../components/add-button';
import BusyIndicator from '../../../../../../../../components/busy-indicator';
import Header from '../../../../../../../../components/header';
import SegmentTab from '../../../../../../../../components/segment-tab';
import { ProductResDTO } from '../../../../../../../../dtos/go-api';
import useSearch from '../../../../../../../../hooks/use-search';
import { useGetRestaurantProducts } from '../../../../../../../../services/go-api/product/product';
import { useGetRestaurantById } from '../../../../../../../../services/go-api/restaurant/restaurant';
import CardGroup from '../../../../../../components/card-group';
import EmptyStates from '../../../../../../components/empty-states';
import MenuAction from '../../../../../../components/menu-action';
import MenuListContainer from '../../../../../../components/menu-list-container';
import PageContainer from '../../../../../../components/page-container';
import Search from '../../../../../../components/search';
import DishCard from './components/dish-card';

const DishesList = () => {
	const { restaurantId }: any = useParams();

	// Queries
	const { data: restaurantQuery } = useGetRestaurantById(restaurantId);
	const restaurant = restaurantQuery;
	const {
		data: productByRestaurant,
		refetch: refetchProducts,
		isLoading: isProductsLoading,
	} = useGetRestaurantProducts(restaurantId);
	const products: ProductResDTO[] = useMemo(
		() => productByRestaurant?.data ?? [],
		[productByRestaurant]
	);

	// Attributes
	const history = useHistory();
	const { search, setSearch } = useSearch<any>(products ?? [], ['name']);
	const [isActive, setIsActive] = useState<boolean>(true);

	// Functions
	const filteredProducts = useMemo(() => {
		if (search) {
			return products?.filter((product) => product?.name?.includes(search));
		}

		if (isActive) {
			return products
				?.filter((product) => product?.isAvailable)
				.sort(function (p1, p2): number {
					if ((p1?.order as number) > (p2?.order as number)) {
						return 1;
					}
					return -1;
				});
		}

		return products?.filter((product) => !product?.isAvailable);
	}, [products, isActive, search]);

	function handleFilter() {
		if (filteredProducts) {
			return filteredProducts.map((product) => (
				<DishCard
					key={`${product.id}`}
					dish={product}
					refetch={refetchProducts}
				/>
			));
		}
	}

	// Render
	return (
		<BusyIndicator query={isProductsLoading}>
			<PageContainer>
				<Header
					title={`${restaurant?.name} - Dishes`}
					hasBackButton={true}
					navigationHeader={[
						{ path: '/dashboard/restaurants', name: 'Restautrants' },
						{ name: `${restaurant?.name}` },
					]}
					actionButtons={{
						menu: 'restaurants',
						routes: ['dishes', 'details'],
						id: restaurantId,
					}}
				/>
				{filteredProducts && filteredProducts.length > 0 ? (
					<MenuListContainer>
						<MenuAction>
							<Search
								value={search}
								placeholder='Search for a dish'
								onChange={setSearch}
							/>
							<SegmentTab
								segments={['Active', 'Offline']}
								handleChange={(segment: string) => {
									setIsActive(segment === 'Active');
								}}
							/>
							<AddButton
								title='Add a new dish'
								handleClick={() =>
									history.push(
										`/dashboard/restaurants/${restaurantId}/dishes/details`
									)
								}
							/>
						</MenuAction>
						<CardGroup>{filteredProducts && handleFilter()}</CardGroup>
					</MenuListContainer>
				) : (
					<MenuListContainer>
						<MenuAction>
							<Search
								value={search}
								placeholder='Search for a dish'
								onChange={setSearch}
							/>
							<SegmentTab
								segments={['Active', 'Offline']}
								handleChange={(segment: string) => {
									setIsActive(segment === 'Active');
								}}
							/>
							<AddButton
								title='Add a new dish'
								handleClick={() =>
									history.push(
										`/dashboard/restaurants/${restaurantId}/dishes/details`
									)
								}
							/>
						</MenuAction>
						<EmptyStates menu='dishes' restaurantId={restaurantId} />
					</MenuListContainer>
				)}
			</PageContainer>
		</BusyIndicator>
	);
};

export default DishesList;
