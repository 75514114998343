import React from 'react';
import Translation from '../../../../../../../../../../components/translation';
import './style.css';

const PromoTableHeader = () => {
    return (
        <thead className='promo-table-header-container'>
            <tr className='promo-table-header-row'>
                <th className='promo-table-header-tuple'>
                    <Translation>Name</Translation>
                </th>
                <th className='promo-table-header-tuple'>
                    <Translation>Status</Translation>
                </th>
                <th className='promo-table-header-tuple'>
                    <Translation>Dates</Translation>
                </th>
                <th className='promo-table-header-tuple'>
                    <Translation>Usage</Translation>
                </th>
                <th className='promo-table-header-tuple'>
                    <Translation>Value</Translation>
                </th>
                <th className='promo-table-header-tuple'>
                    <Translation>Actions</Translation>
                </th>
            </tr>
        </thead>
    )
}

export default PromoTableHeader;