import { NotificationDeliveryType, NotificationDestinationType, NotificationFrequency, NotificationResDTO, NotificationType } from "../../../../../../dtos/go-api";
import { NotificationStatus } from "./../../../../../../dtos/go-api/notificationStatus";

// create initial notification state
export const initialNotification: NotificationResDTO = {
	id: "",
	title: "",
	description: "",
	type: NotificationType.UPCOMING,
	publicationDate: "",
	publicationTime: "",
	frequency: NotificationFrequency.UNIQUE_USE,
	frequencyDays: [],
	status: NotificationStatus.ENABLED,
	companies: [],
	destinationType: NotificationDestinationType.COMPANY,
	deliveryType: NotificationDeliveryType.PUSH_AND_MAIL,
};

export interface NotificationReducerAction {
	type: "serverChanged" | "stateChanged";
	target: string;
	payload: any;
}

// create reducer
export const reducer = (state: NotificationResDTO, action: NotificationReducerAction) => {
	switch (action.type) {
		case "serverChanged":
			return {
				...state,
				...action.payload,
			};

		case "stateChanged":
			return {
				...state,
				[action.target]: action.payload,
			};
	}
};
