import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import Dashboard from './modules/dashboard';
import Login from './modules/login';
import { TranslationContextProvider } from './components/translation-context';
import AlertContainer from './components/alert';
import { ToastContainer } from 'react-toastify';
import { QueryClient, QueryClientProvider } from 'react-query';

function App() {
	const queryClient = new QueryClient();

	return (
		<QueryClientProvider client={queryClient}>
			<TranslationContextProvider>
				<BrowserRouter>
					<Switch>
						<Route path='/dashboard' component={Dashboard} />
						<Route path='/login' component={Login} />
						<Route path='/' component={() => <Redirect to='/login' />} />
					</Switch>
				</BrowserRouter>
				<AlertContainer />
				<ToastContainer
					position='bottom-center'
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
			</TranslationContextProvider>
		</QueryClientProvider>
	);
}

export default App;
