import React from 'react';
import { ReactComponent as OffIcon } from './assets/logout.svg';
import './style.css';

const Logout = ({ handleClick }: any) => {
    return (
        <div className='logout-container' onClick={handleClick}>
            <OffIcon />
        </div>
    )
}

export default Logout;