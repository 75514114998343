import React from 'react';
import BackgroundDetailsForm from '../../../../../../components/background-details-form';
import GroupInputDetails from '../../../../../../components/group-input-details';
import { PromoCodeInputProps } from '../props';
import DoubleCheckbox from '../double-checkbox';
import { PromoType } from '../../../../../../../../dtos/go-api';

const PromoTypes = ({ value, dispatch }: PromoCodeInputProps) => {
  // Handlers
  function handleCheck(label: string) {
    const value = mapWithValue(label);
    dispatch({ type: 'stateChanged', target: 'type', payload: value });
  }

  function mapWithValue(data: string) {
    switch (data) {
      case 'Percentage':
        return PromoType.PERCENTAGE;
      case 'Fixed Amount':
        return PromoType.AMOUNT;
      case 'Unkown':
        return PromoType.UNKNOWN;
    }
  }

  function mapValueWithChoice(choice: PromoType) {
    switch (choice) {
      case PromoType.PERCENTAGE:
        return 'Percentage';
      case PromoType.AMOUNT:
        return 'Fixed Amount';
      case PromoType.UNKNOWN:
        return 'Unkown';
    }
  }

  return (
    <BackgroundDetailsForm>
      <GroupInputDetails title='Types'>
        <DoubleCheckbox
          firstChoice='Percentage'
          secondChoice='Fixed Amount'
          selectedChoice={mapValueWithChoice(value)}
          handleCheck={handleCheck}
        />
      </GroupInputDetails>
    </BackgroundDetailsForm>
  );
};

export default PromoTypes;
