import React from 'react';
import InputText from '../../../../../../../../components/input-text';
import InputWithLabel from '../../../../../../../../components/input-with-label';
import BackgroundDetailsForm from '../../../../../../components/background-details-form';
import InputSelect from '../../../../../../components/input-select';
import { RestaurantInformationProps } from './props';
import TitleInputGroup from '../../../../../../components/title-input-group';
import './style.css';
import { useTranslation } from '../../../../../../../../components/translation-context/TranslationContext';
import { RestaurantType } from '../../../../../../../../dtos/go-api/restaurantType';

const RestaurantInformation = ({
	restaurant,
	dispatch,
	setRestaurantType,
	restaurantType,
}: RestaurantInformationProps) => {
	//Attributes
	const options = [
		{ value: 'B2C', label: 'B2C' },
		{ value: 'B2B', label: 'B2B' },
		{ value: 'BOTH', label: 'BOTH' },
	];
	const { translate } = useTranslation();

	//Functions
	function handleOrderChange(order: number) {
		dispatch({
			type: 'restaurantChanged',
			target: 'order',
			payload: Number(order),
		});
	}

	function handleFormChange(payload: any, target: string) {
		dispatch({
			type: 'restaurantChanged',
			target: target,
			payload: payload,
		});
	}

	//Render
	return (
		<BackgroundDetailsForm style={{ width: '40%', marginRight: 40 }}>
			<TitleInputGroup title='Restaurant information' />
			<div className='restaurant-information-goup-input'>
				<InputWithLabel label='RESTAURANT NAME'>
					<InputText
						required={true}
						value={restaurant.name}
						placeholder='ex: Nightborn'
						size='medium'
						onChange={(event: any) =>
							handleFormChange(event.target.value, 'name')
						}
					/>
				</InputWithLabel>
				<div style={{ marginTop: 20 }} />
				<InputWithLabel label='RESTAURANT ORDER'>
					<InputText
						value={restaurant.order}
						onChange={(event: any) => handleOrderChange(event.target.value)}
						placeholder={translate('restaurantOrderPlaceHolder')}
					/>
				</InputWithLabel>
				<InputWithLabel label='BackgroundColor' style={{ marginTop: 20 }}>
					<InputText
						value={restaurant.colorReference}
						onChange={(event: any) =>
							handleFormChange(event.target.value, 'colorReference')
						}
						placeholder={translate('BackgroundColorPlaceHolder')}
					/>
				</InputWithLabel>
				<div style={{ marginTop: 20 }} />
				<InputWithLabel
					label='TYPE OF RESTAURANT'
					style={{ width: 'fit-content' }}
				>
					<InputSelect
						options={options}
						initialValue={restaurant.restaurantType}
						size='medium'
						handleSelect={(value) => {
							handleFormChange(value, 'restaurantType');
							setRestaurantType(value);
						}}
					/>
				</InputWithLabel>
				{(restaurantType === RestaurantType.B2B ||
					restaurantType === RestaurantType.BOTH) && (
					<>
						<div style={{ marginTop: 20 }} />
						<InputWithLabel label='PAPERFORM URL'>
							<InputText
								required={true}
								value={restaurant?.paperformUrl}
								placeholder='ex: http://paperform.url'
								size='medium'
								onChange={(event: any) =>
									handleFormChange(event.target.value, 'paperformUrl')
								}
							/>
						</InputWithLabel>
					</>
				)}
			</div>
		</BackgroundDetailsForm>
	);
};

export default RestaurantInformation;
