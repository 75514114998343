import React from "react";

const Checked = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M4.76795 0.999998C5.53775 -0.333335 7.46225 -0.333334 8.23205 1L12.1292 7.75C12.899 9.08334 11.9367 10.75 10.3971 10.75H2.60288C1.06328 10.75 0.101034 9.08333 0.870835 7.75L4.76795 0.999998Z" fill="#061A42"/>
		</svg>
	);
};
export default Checked;
