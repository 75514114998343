import React from 'react';
import Translation from '../../../../../../../../../../components/translation';
import { ReactComponent as DeleteIcon } from './assets/delete.svg';
import { UploadedPictureProps } from './props';
import './style.css';


const UploadedPicture = ({ pictureUrl, handleDelete }: UploadedPictureProps) => {
    return (
        <div className='picture-image-uploaded-container'>
            <div className='picture-image-container'>
                <img className='picture-image-img' src={pictureUrl} alt='restaurant-picture' />
                <DeleteIcon className='picture-image-delete' onClick={handleDelete} />
            </div>
            <div className='picture-image-uploaded-text-container'>
                <div>
                    <span className='picture-image-uploaded-tex'><Translation>File</Translation></span><br />
                    <span className='picture-image-uploaded-tex'><Translation>successfully</Translation></span><br />
                    <span className='picture-image-uploaded-text green' ><Translation>uploaded</Translation></span>
                </div>
            </div>
        </div>
    )
}

export default UploadedPicture;