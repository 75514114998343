import React from 'react';
import './style.css';
import { CompanyOrdersCardProps } from './props';

const CompanyOrdersCard = ({ name, orders }: CompanyOrdersCardProps) => {

    return (
        <div className='company-orders-card-container'>
            <div className='company-orders-card-header'>
                <div className='company-orders-card-header-name'>{name}</div>
            </div>
            <div className='company-orders-card-actions-container'>
                <div className='company-orders-card-number'>{orders}</div>
            </div>
        </div>
    )
}

export default CompanyOrdersCard;