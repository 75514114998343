import { useEffect, useState } from 'react';

const useSearch = <Out>(elements: Out[], fields: string[]) => {
    // ATTRIBUTES
    const [search, setSearch] = useState("");
    const [filtered, setFiltered] = useState(elements);

    // METHODS
    const applyFilter = (element: any, field: string) => {
        try {
            if (field.includes('.')) {
                field.split('.').forEach((searchValue) => element = element[searchValue]);

                if (element.toLowerCase().includes(search.toLowerCase())) {
                    return true;
                }
                else {
                    return false;
                }
            }

            return element[field].toLowerCase().includes(search.toLowerCase());
        }
        catch (exception) {
            return false;
        }
    }

    // EFFECTS
    useEffect(() => {
        if (elements && fields) {
            setFiltered((previous) => {
                const newFiltered = elements.length > 0 ? elements.filter(elt => {
                    if (fields.filter((searchableValue) => applyFilter(elt, searchableValue)).length > 0) {
                        return true;
                    }
                    return false;
                }) : [];

                return newFiltered;
            });
        }
    }, [elements, search]);

    return { search: search, setSearch: setSearch, filtered: filtered };
}

export default useSearch;