import React from 'react';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';
import DishDetail from './modules/detail';
import DishesList from './modules/list';

const RestaurantsDishes = () => {
    // Attributes 
    const { restaurantId }: any = useParams()

    return (
        <Switch>
            <Route path='/dashboard/restaurants/:restaurantId/dishes/list' component={DishesList} />
            <Route path='/dashboard/restaurants/:restaurantId/dishes/:productId/details' component={DishDetail} />
            <Route path='/dashboard/restaurants/:restaurantId/dishes/details' component={DishDetail} />
            <Route path='/dashboard/restaurants/:restaurantId/dishes/' render={() => <Redirect to={`/dashboard/restaurants/${restaurantId}/dishes/list`} />} />
        </Switch>
    )
}

export default RestaurantsDishes;