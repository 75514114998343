import React from "react";
import { ReactComponent as PrinterIcon } from "../../assets/print-big.svg";
import * as _ from "lodash";
import { OrderDTO } from "../../../../../../../../dtos/go-api";
import { pdf } from "@react-pdf/renderer";
import printJS from "print-js";
import { SingleOrderPrintButtonProps } from "./props";
import PrintTicket from "../print-ticket";

const SingleOrderPrintButton = ({
  order,
  setIsModalOpen,
  setSelectedOrder,
}: SingleOrderPrintButtonProps) => {
  //Attributes
  const orders: OrderDTO[] = [];
  orders.push(order);

  async function handlePrintJS() {
    const blob = await pdf(<PrintTicket orders={orders} />).toBlob();
    const blobURL = URL.createObjectURL(blob);
    printJS({
      printable: blobURL,
      onPrintDialogClose: () => {
        setIsModalOpen(true);
        setSelectedOrder(order);
      },
    });
  }

  return (
    <div
      style={{
        cursor: "pointer",
        width: "30px",
        height: "30px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={handlePrintJS}
    >
      <div
        style={{
          cursor: "pointer",
          width: "30px",
          height: "30px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "100%",
          backgroundColor: "white",
        }}
      >
        <PrinterIcon width='.875rem' height='.875rem' />
      </div>
    </div>
  );
};

export default SingleOrderPrintButton;
