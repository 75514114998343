import React, { useState } from "react";
import { ReactComponent as PrinterIcon } from "../../../assets/print-big.svg";
import * as _ from "lodash";
import OrderLogService from "../../../../../../../../../services/OrderLogService";
import Modal from "react-modal";
import Popup from "../../../../../../../components/popup";
import PopupButton from "../../../../../../../../../components/popup-button";
import { customStyles } from "../../../../../../orders/modules/list/components/printable-order";
import {
  getOrders,
  useGetUnprintedOrdersCount,
} from "../../../../../../../../../services/go-api/order/order";
import { OrderDTO, OrderLogType } from "../../../../../../../../../dtos/go-api";
import { formatISO } from "date-fns";
import animationData from "../../../assets/loading.json";
import Lottie from "react-lottie";
import { pdf } from "@react-pdf/renderer";
import printJS from "print-js";
import PrintTicket from "../../print-ticket";

const MultiPrintButton = () => {
  //Attributes
  const [isPrintLoading, setIsPrintLoading] = useState(false);
  const [isOrdersUpdating, setIsOrdersUpdating] = useState(false);
  const [orders, setOrders] = useState<OrderDTO[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const today = new Date();
  const minDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - 1,
    14,
    0,
    0
  );

  async function handlePrintJS(unprintedOrders: OrderDTO[]) {
    const blob = await pdf(<PrintTicket orders={unprintedOrders} />).toBlob();
    const blobURL = URL.createObjectURL(blob);
    printJS({
      printable: blobURL,
      onPrintDialogClose: () => {
        setIsModalOpen(true);
      },
    });
  }

  const {
    data: unprintedOrdersCount,
    isLoading: isUnprintedOrdersCountLoading,
  } = useGetUnprintedOrdersCount(
    {
      minDate: formatISO(minDate),
    },
    { query: { refetchInterval: 3000 } }
  );

  async function getUnprintedOrders() {
    try {
      const orders = await getOrders({
        minDate: formatISO(minDate),
        size: 1000,
      });
      const unprintedOrders = orders?.data?.filter(
        (order) => order?.status !== OrderLogType.PRINTED
      );
      setOrders(unprintedOrders ?? []);

      return unprintedOrders;
    } catch (err) {
      console.error(err);
    }
  }

  //Functions
  async function handleOrdersLogUpdate() {
    try {
      setIsOrdersUpdating(true);
      const updatePromises = orders?.map(
        async (order) =>
          await OrderLogService.createOrderLog({
            orderId: order?.id ?? "",
            orderLogType: 0,
          })
      );

      await Promise.all(updatePromises);
    } catch (err) {
      console.error(err);
    } finally {
      setIsModalOpen(false);
      setIsOrdersUpdating(false);
      setIsPrintLoading(false);
    }
  }

  async function handleClick() {
    setIsPrintLoading(true);
    const unprintedOrders = await getUnprintedOrders();
    await handlePrintJS(unprintedOrders ?? []);
  }

  return (
    <>
      <div
        className={`multi-print-button-container ${
          unprintedOrdersCount?.count === 0 ? "disabled" : ""
        }`}
        onClick={unprintedOrdersCount?.count !== 0 ? handleClick : undefined}
        style={{ minWidth: 180, justifyContent: "center" }}
      >
        {isPrintLoading || isUnprintedOrdersCountLoading ? (
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: animationData,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            height={20}
            width={50}
          />
        ) : (
          <>
            <PrinterIcon />
            <div className='multi-print-button-title'>{`Print all unprinted from the last 24h (${unprintedOrdersCount?.count})`}</div>
          </>
        )}
      </div>

      <Modal ariaHideApp={false} style={customStyles} isOpen={isModalOpen}>
        <Popup
          title='didOrderPrint'
          icon={<PrinterIcon style={{ width: 66, height: 69 }} />}
          buttons={[
            <PopupButton
              backgroundColor='grey'
              handleClick={(event: any) => {
                setIsModalOpen(false);
                setIsPrintLoading(false);
                event.stopPropagation();
              }}
              title='NO'
            />,
            <PopupButton
              backgroundColor='yellow'
              handleClick={(e: any) => {
                handleOrdersLogUpdate();
                e.stopPropagation();
              }}
              title='YES'
              isLoading={isOrdersUpdating}
            />,
          ]}
        />
      </Modal>
    </>
  );
};

export default MultiPrintButton;
