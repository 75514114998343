import React from "react";
import { TimingType } from "..";
import { useTranslation } from "../../../../../../../../../components/translation-context/TranslationContext";
import {
  NotificationDestinationType,
  NotificationFrequency,
  NotificationResDTO,
  NotificationType,
} from "../../../../../../../../../dtos/go-api";
import { NotificationReducerAction } from "../../../reducer";

interface PublishTypeRadioProps {
  notification: NotificationResDTO;
  dispatch: React.Dispatch<NotificationReducerAction>;
  selectedTimingType: TimingType;
  setSelectedTimingType: React.Dispatch<React.SetStateAction<TimingType>>;
  destinationType: NotificationDestinationType;
  isNewNotif: boolean
}

export default function PublishTypeRadioSection({
  notification,
  dispatch,
  selectedTimingType,
  setSelectedTimingType,
  destinationType,
  isNewNotif
}: PublishTypeRadioProps) {
  // Attributes
  const { translate } = useTranslation()

  // Functions
  function handleNotifPublishNowRadio(type: NotificationType) {
    setSelectedTimingType(TimingType.PUBLISH_NOW)
    dispatch({
      type: "stateChanged",
      target: "type",
      payload: type,
    });
  }

  function handleNotifPublishTimeRadio(type: NotificationType) {
    setSelectedTimingType(TimingType.CUSTOM_DATETIME)
    dispatch({
      type: "stateChanged",
      target: "type",
      payload: type,
    });
    dispatch({
      type: "stateChanged",
      target: "frequency",
      payload: NotificationFrequency.UNIQUE_USE,
    });
  }

  function handleNotifPublishFrequencyRadio(type: NotificationType) {
    setSelectedTimingType(TimingType.FREQUENCY)
    dispatch({
      type: "stateChanged",
      target: "type",
      payload: type,
    });
    dispatch({
      type: "stateChanged",
      target: "frequency",
      payload: notification.frequency !== NotificationFrequency.UNIQUE_USE ? notification.frequency : NotificationFrequency.EVERY_WEEK,
    });
  }

  // Render
  return (
    <div>
      <div className="notif-timing-radio-container">
        {/* PUBLISH NOW */}
        <div
          style={{ marginRight: 40, cursor: "pointer" }}
          onClick={() => handleNotifPublishNowRadio(NotificationType.SENT)}
        >
          <input
            type="radio"
            checked={selectedTimingType === TimingType.PUBLISH_NOW}
            value={NotificationType.SENT}
            name="notification-publish-timing"
          />
          <span
            style={{
              marginLeft: 8,
              fontSize: 14,
              fontWeight: 500,
              lineHeight: "30px",
              fontFamily: "Poppins",
            }}
          >
            {translate('Publish now')}
          </span>
        </div>
        {/* CUSTOM DATE-TIME */}
        <div
          style={{ marginRight: 40, cursor: "pointer" }}
          onClick={() => handleNotifPublishTimeRadio(NotificationType.UPCOMING)}
        >
          <input
            type="radio"
            checked={selectedTimingType === TimingType.CUSTOM_DATETIME}
            value={NotificationType.UPCOMING}
            name="notification-publish-timing"
          />
          <span
            style={{
              marginLeft: 8,
              fontSize: 14,
              fontWeight: 500,
              lineHeight: "30px",
              fontFamily: "Poppins",
            }}
          >
            Custom date-time
          </span>
        </div>
        {/* RECCURING */}
        {destinationType !== NotificationDestinationType.USER &&
          <div
            style={{ cursor: "pointer" }}
            onClick={() =>
              handleNotifPublishFrequencyRadio(NotificationType.RECURRING)
            }
          >
            <input
              type="radio"
              checked={selectedTimingType === TimingType.FREQUENCY}
              value={NotificationType.RECURRING}
              name="notification-frequency"
            />
            <span
              style={{
                marginLeft: 8,
                fontSize: 14,
                fontWeight: 500,
                lineHeight: "30px",
                fontFamily: "Poppins",
              }}
            >
              Frequency
            </span>
          </div>
        }
      </div>
      <div
        style={{
          display:
            notification.type === NotificationType.SENT
              ? "flex"
              : "none",
        }}
      >
        <span className="publish-now-warning-text">
          By publishing now, your notification will be sent immediately to
          foodiz users
        </span>
      </div>
    </div>
  );
}
