import React, { useMemo } from "react";
import BackgroundDetailsForm from "../../../../../../../../components/background-details-form";
import InputSelect from "../../../../../../../../components/input-select";
import TitleInputGroup from "../../../../../../../../components/title-input-group";
import "./style.css";
import { ScreenProps } from "./props";
import { ScreenDTO } from "../../../../../../../../../../types/dtos/csharp-api";

const ScreenDishes = ({ product, dispatch, screens }: ScreenProps) => {
  // Attributes
  const options = useMemo(() => {
    if (!screens?.length) return [];
    return screens.map((screen: ScreenDTO) => {
      return {
        label: screen.name,
        value: screen.id,
      };
    });
  }, [screens]);

  // Render
  return (
    <BackgroundDetailsForm>
      <TitleInputGroup title="Select screen" />
      <div style={{ marginTop: "1.5rem" }}>
        <InputSelect
          initialValue={product?.screenId}
          options={options}
          handleSelect={(value) =>
            dispatch({
              type: "productChanged",
              target: "screenId",
              payload: value,
            })
          }
        />
      </div>
    </BackgroundDetailsForm>
  );
};

export default ScreenDishes;
