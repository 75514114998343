import React, { useReducer } from 'react';
import InputText from '../../../../../../../../components/input-text';
import InputWithLabel from '../../../../../../../../components/input-with-label';
import TextArea from '../../../../../../../../components/text-area';
import { NotificationDTO } from '../../../../../../../../dtos/go-api';
import BackgroundDetailsForm from '../../../../../../components/background-details-form';
import GroupInputDetails from '../../../../../../components/group-input-details';
import { NotificationReducerAction } from '../../reducer';

interface NotificationTitleSectionProps {
	notification: NotificationDTO;
	dispatch: React.Dispatch<NotificationReducerAction>;
}

export default function NotificationTitleSection({
	notification,
	dispatch,
}: NotificationTitleSectionProps) {
	// Render
	return (
		<BackgroundDetailsForm style={{ height: 'none' }}>
			<GroupInputDetails title='title'>
				<InputText
					required={true}
					placeholder='notificationTitlePlaceholder'
					value={notification.title}
					onChange={(event: any) =>
						dispatch({
							type: 'stateChanged',
							target: 'title',
							payload: event.target.value,
						})
					}
					size='medium'
					style={{ marginTop: 8, marginBottom: 44 }}
				/>
				<InputWithLabel label='notificationDescription'>
					<TextArea
						required={true}
						placeholder='notificationDescriptionPlaceholder'
						size='medium'
						value={notification.description}
						style={{ resize: 'none' }}
						rows={4}
						onChange={(event: any) =>
							dispatch({
								type: 'stateChanged',
								target: 'description',
								payload: event.target.value,
							})
						}
					/>
				</InputWithLabel>
			</GroupInputDetails>
		</BackgroundDetailsForm>
	);
}
