import React from 'react';
import './style.css'
import { BackgroundDetailsFormProps } from './props'

const BackgroundDetailsForm = ({ children, style }: BackgroundDetailsFormProps) => {
    return (
        <div className='details-form-background' style={{ ...style }}>
            {children}
        </div>
    )
}

export default BackgroundDetailsForm;