import React from 'react';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';
import UserDetail from './modules/detail';
import UserList from './modules/list';
import UserSummary from './modules/summary';

const CompanyUsers = () => {

    // Attributes
    const { companyId }: any = useParams()

    return (
        <Switch>
            <Route path='/dashboard/companies/:companyId/employees/list' component={UserList} />
            <Route path='/dashboard/companies/:companyId/employees/:employeeId/summary' component={UserSummary} />
            <Route path='/dashboard/companies/:companyId/employees/:employeeId/details' component={UserDetail} />
            <Route path='/dashboard/companies/:companyId/employees/details' component={UserDetail} />
            <Route path='/dashboard/companies/:companyId/employees/' render={() => <Redirect to={`/dashboard/companies/${companyId}/employees/list`} />} />
        </Switch>
    )
}

export default CompanyUsers;