import { format } from "date-fns";
import React from "react";
import { useMemo } from "react";
import Table from "../../../../../../../../../components/table";
import { useTranslation } from "../../../../../../../../../components/translation-context/TranslationContext";
import { NotificationCompany, NotificationDestinationType } from "../../../../../../../../../dtos/go-api";
import NotificationStatusBadge from "../../../../../components/status-badge";
import CompaniesIndicator from "../../companies-indicator";
import { NotificationsSentTableProps } from "./props";

const NotificationsSentTable = ({
  destinationType,
  data
}: NotificationsSentTableProps) => {
  // Attributes
  const { translate } = useTranslation()

  const columns = useMemo(
    () => {
      const col = [
        {
          Header: "Title",
          accessor: "title", // accessor is the "key" in the data
          Cell: ({ value }: { value: string }) => (
            <span style={{ color: "#000000" }}>{value}</span>
          ),
        },
        {
          Header: "Status",
          accessor: "status",
          style: { color: "#7F85A2" },
          Cell: ({ value }: { value: string }) => (
            <NotificationStatusBadge sent={true} text={"Sent"} />
          ),
        },
        {
          Header: "Published",
          accessor: "publicationDate",
          Cell: ({ value }: { value: string }) => {
            let localDateTime = new Date(value)
            return (
              <span style={{ color: "#7F85A2" }}>
                {value &&
                  `${format(localDateTime, "dd MMM yyyy")} ${translate('at')} ${format(localDateTime, "HH:mm")}`
                }
              </span>
            )
          }
        },
        {
          Header: "Frenquency",
          accessor: "frequency",
          Cell: ({ value }: { value: string }) => (
            <span style={{ color: "#7F85A2" }}>{value}</span>
          ),
        },
      ]

      if (destinationType === NotificationDestinationType.COMPANY) {
        return ([
          ...col,
          {
            Header: "Company",
            accessor: "companies",
            Cell: ({ value }: { value: NotificationCompany[] }) => (
              <CompaniesIndicator companies={value} />
            ),
          },
        ])
      }

      return (col)
    },
    [destinationType, translate]
  );

  // Render
  return <Table columns={columns} data={data ?? []} />;
};

export default NotificationsSentTable;
