import React, { } from "react";
import { useTranslation } from "../../../../../../../../components/translation-context/TranslationContext";
import "./style.css";
import "react-datepicker/dist/react-datepicker.css";

const AppVersioningListHeader = () => {
	// Attributes
	const { translate } = useTranslation();

	return (
		<div className="versioning-header-container">
			<div className="versioning-header-title">
				<div>{translate("version control")}</div>
			</div>

		</div>
	);
};

export default AppVersioningListHeader;
