import { Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import React from "react";
import FoodizLogo from "./FoodizLogo";
import { PrintTicketFooterProps } from "./props";

const styles = StyleSheet.create({
  footerFlexContainer: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    bottom: 0,
    width: "100%",
  },
  footerCompanyInfosContainer: {
    padding: "0 16 0 18",
  },

  footerCompanyInfosFlexContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottom: "1 solid rgba(0, 0, 0, 0.10)",
  },

  footerSubContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 8,
    padding: "0 17 13 17",
    width: "100%",
  },

  footerFlexItem: {
    display: "flex",
    flexDirection: "row",
    fontSize: 8,
    alignItems: "center",
    fontFamily: "Poppins",
  },

  footerCompanyName: {
    fontSize: 15,
    fontFamily: "Poppins",
    fontWeight: 600,
  },

  footerLogoText: {
    fontSize: 6,
    fontFamily: "Poppins",
    fontWeight: 500,
  },

  blackDot: {
    height: 3,
    width: 3,
    backgroundColor: "black",
    borderRadius: 8,
    marginLeft: 6,
    marginRight: 6,
  },

  logoView: {
    marginTop: 6,
  },

  barcode: {
    height: 40,
    position: "relative",
    right: 20,
  },

  pagination: {
    fontFamily: "Poppins",
    fontSize: 9,
  },
});

const PrintTicketFooter = ({ order, barcode }: PrintTicketFooterProps) => {
  return (
    <View fixed style={styles.footerFlexContainer}>
      <View style={styles.footerCompanyInfosContainer}>
        <View style={styles.footerCompanyInfosFlexContainer}>
          <Text style={styles.footerCompanyName}>{`${order.companyName
            ?.charAt(0)
            .toUpperCase()}${order.companyName?.slice(1).toLowerCase()}`}</Text>
          <View style={styles.footerFlexItem}>
            <Text>{order.orderTicketId}</Text>
            <View style={styles.blackDot} />
            <Text>{order.zoneName}</Text>
            <View style={styles.blackDot} />
            <Text>{order.kitchenPriority}</Text>
          </View>
        </View>
      </View>

      <View style={styles.footerSubContainer}>
        <View>
          <Text style={styles.footerLogoText}>Homemade with love by</Text>
          <View style={styles.logoView}>
            <FoodizLogo />
          </View>
        </View>
        <Image src={barcode} style={styles.barcode} />
        <View>
          <Text
            style={styles.pagination}
            render={({ subPageNumber, subPageTotalPages }) =>
              `${subPageNumber}/${subPageTotalPages}`
            }
          />
        </View>
      </View>
    </View>
  );
};

export default PrintTicketFooter;
