import React from "react";
import { InputTimeProps } from "./props";
import "./style.css";

const InputTime = ({
  value,
  onChange,
  placeholder,
  style,
  ...rest
}: InputTimeProps) => {
  return (
    <input
      type='time'
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      style={{ ...style }}
      className={`input-time`}
      {...rest}
    />
  );
};

export default InputTime;
