import React from 'react';
import InputText from '../../../../../../../../../../components/input-text';
import InputWithLabel from '../../../../../../../../../../components/input-with-label';
import BackgroundDetailsForm from '../../../../../../../../components/background-details-form';
import TitleInputGroup from '../../../../../../../../components/title-input-group';
import { ProductPriorityProps } from './props';

const PromoDescription = ({ product, dispatch }: ProductPriorityProps) => {
    return (
        <BackgroundDetailsForm>
            <TitleInputGroup title='Dish priority' />
            <div style={{ marginTop: 14 }} />
            <InputWithLabel label='CHOOSE A PRIORITY'>
                <InputText value={product.promoDescription} onChange={(event: any) => dispatch({ target: 'promoDescription', type: 'productChanged', payload: event.target.value })} placeholder="ex: It's December already, lets try the Coco Butternut Soup!" />
            </InputWithLabel>
        </BackgroundDetailsForm>
    )
}

export default PromoDescription;