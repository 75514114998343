import { format } from "date-fns";
import React from "react";
import { useMemo } from "react";
import Table from "../../../../../../../../../components/table";
import CompaniesIndicator from "../../companies-indicator";
import { NotificationsRecurringTableProps } from "./props";
import EditAction from "../../edit-action";
import { NotificationCompany } from "../../../../../../../../../dtos/go-api";
import { useTranslation } from "../../../../../../../../../components/translation-context/TranslationContext";

const NotificationsRecurringTable = ({
  data,
  refetchNotification,
}: NotificationsRecurringTableProps) => {
  // Attributes
  const { translate } = useTranslation()

  const columns = useMemo(
    () => [
      {
        Header: "Title",
        accessor: "title", // accessor is the "key" in the data
        Cell: ({ value }: { value: string }) => (
          <span style={{ color: "#000000" }}>{value}</span>
        ),
      },
      {
        Header: "Next Publication",
        accessor: "nextPublicationDate",
        Cell: ({ value }: { value: string }) => {
          let localDateTime = new Date(value)
          return (
            <span style={{ color: "#7F85A2" }}>
              {value &&
                `${format(localDateTime, "dd MMM yyyy")} ${translate('at')} ${format(localDateTime, "HH:mm")}`
              }
            </span>
          )
        },
      },
      {
        Header: "Frequency",
        accessor: "frequency",
        Cell: ({ value }: { value: string }) => (
          <span style={{ color: "#7F85A2" }}>{value}</span>
        ),
      },
      {
        Header: "Company",
        accessor: "companies",
        Cell: ({ value }: { value: NotificationCompany[] }) => (
          <CompaniesIndicator companies={value} />
        ),
      },
      {
        Header: "Actions",
        accessor: "col1",
        Cell: ({ cell }: any) => (
          <EditAction
            notificationId={cell?.row?.original.id}
            notificationStatus={cell?.row?.original.status}
            handleDisabled
            refetchNotification={refetchNotification}
          />
        ),
      },
    ],
    [refetchNotification, translate]
  );

  // Render
  return <Table columns={columns} data={data ?? []} />;
};

export default NotificationsRecurringTable;
